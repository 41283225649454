import "./bookOK.css";

function BookOK({ dataBook, imgSrc, author }) {
  return (
    <div className="book__global">
      <div className="book">
        
        <div className="book__divimg">
          {imgSrc !== "" ? (
            <img
              className="book__img"
              src={imgSrc}
              alt={`Couverture du livre ${dataBook.title}`}
            />
          ) : (
            <div className="book__divimg--noimg"><span className="book__divimg--txt">Image non disponible</span></div>
          )}
        </div>
        <div className="book__ok">
        <div className="book__ok--title"><p>Titre :</p><p><strong>{dataBook.title ? dataBook.title : "Titre non disponible"}</strong></p></div>
        <div className="book__ok--author"><p>Auteur :</p><p><strong>{author ? author : "Auteur non disponible"}</strong></p></div>
        </div>
      </div>
      {dataBook.book_fourth !== '' ? <div className="book__ok--fourth">{dataBook.book_fourth}</div> : ''}
    </div>
  );
}

export default BookOK;
