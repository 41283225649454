import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import "./adminHome.css";

function AdminHome() {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const first = async () => {
    setIsLoading(true);
    if (!localStorage.lecturecommuneCheckAdmin) {
      navigate("/");
    }
    const token = localStorage.lecturecommuneToken;
    const testConnect = await fetch(
      `https://www.lecturecommune.fr/api/user/checkadmin`,
      {
        method: "post",
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    if (testConnect.status === 200) {
      setIsLoading(false);
    } else {
      navigate("/error");
    }
  };
  useEffect(() => {
    first();
  }, []);
  return (
    <>
      <main>
        {!isLoading ? (
          <>
            <h2 className="bookcreated__title">Administration</h2>
            <div className="adminhome">
              <button
                className="form__book"
                onClick={() => {
                  navigate("/adminview/users");
                }}
              >
                Inscriptions
              </button>
              <button
                className="form__book"
                onClick={() => {
                    navigate("/adminview/readings");
                  }}
              >
                Lectures
              </button>
              <button
                className="form__book"
                onClick={() => {
                    navigate("/adminview/addcover");
                  }}
              >
                Ajout couv
              </button>
              <button
                className="form__book"
                onClick={() => {
                    navigate("/adminview/editsession");
                  }}
              >
                Edit Session
              </button>
              <button
                className="form__book"
                onClick={() => {
                    navigate("/adminview/add");
                  }}
              >
                Ajout catalogue
              </button>
              <button
                className="form__book"
                onClick={() => {
                    navigate("/adminview/biblio");
                  }}
              >
                Bibliothèques
              </button>
              <button
                className="form__book"
                onClick={() => {
                    navigate("/adminview/biblioupdate");
                  }}
              >
                Biblio Update
              </button>
            </div>
          </>
        ) : (
          ""
        )}
      </main>
    </>
  );
}

export default AdminHome;
