import { useNavigate } from "react-router-dom";
import { HiOutlineCursorClick } from "react-icons/hi";
import { BsChevronUp } from "react-icons/bs";
import { AiFillStar } from 'react-icons/ai';
import { BiEdit } from 'react-icons/bi';
import "./faqDetail.css";

const FAQDetail = () => {
  const navigate = useNavigate();
  const open = (part) => {
    if (document.getElementById(part).classList.value === "faq__headbar") {
      document.getElementById(part).classList.add("faq__headbar--open");
      document
        .getElementById(`${part}__chevron`)
        .classList.add("faq__headbar--chevron--open");
      document.getElementById(`${part}__detail`).classList.add("faq__open");
    } else {
      document.getElementById(part).classList.remove("faq__headbar--open");
      document
        .getElementById(`${part}__chevron`)
        .classList.remove("faq__headbar--chevron--open");
      document.getElementById(`${part}__detail`).classList.remove("faq__open");
    }
  };
  return (
    <main>
      <div className="legal">
        <div className="legal__div">
          <h2 className="legal__div--title">FAQ</h2>
          <p className="legal__div--txt legal__div--center">
            Cette FAQ sera régulièrement complétée.
          </p>
          <br />
          <div className="faq__box">
            <div
              id="inscription"
              className="faq__headbar"
              onClick={() => {
                open("inscription");
              }}
            >
              <h3 className="faq__headbar--title">S'inscrire</h3>
              <BsChevronUp
                id="inscription__chevron"
                className="faq__headbar--chevron"
              />
            </div>
            <div id="inscription__detail" className="faq">
              <p>
                Le formulaire d'inscription se trouve sur{" "}
                <span
                  className="faq__link"
                  onClick={() => {
                    navigate("/signup");
                  }}
                >
                  <strong>cette page </strong>
                  <HiOutlineCursorClick className="faq__link--icon" />
                </span>{" "}
                et se complète avec :
              </p>
              <div className="legal__div--txt faq__divrow">
                <div className="faq__divcol">
                  <p>- Nom d'utilisateur</p>
                  <p>- Adresse email</p>
                  <p>- Mot de passe</p>
                  <p>- Acceptation des CGU</p>
                </div>
                <img
                  className="faq__img"
                  src="https://www.lecturecommune.fr/assets/faq/signup.png"
                  alt="Inscription"
                />
              </div>
            </div>
          </div>
          <div className="faq__box">
            <div
              id="profile"
              className="faq__headbar"
              onClick={() => {
                open("profile");
              }}
            >
              <h3 className="faq__headbar--title">
                Mettre à jour votre profil
              </h3>
              <BsChevronUp
                id="profile__chevron"
                className="faq__headbar--chevron"
              />
            </div>
            <div id="profile__detail" className="faq">
              <div>
                Dans le menu <span className="faq__nolink">
                  <strong>Mon profil</strong>
                </span>{" > "}<span className="faq__nolink">
                  <strong>Mes informations</strong>
                </span>, vous pouvez mettre à jour votre profil {"("}photo de profil, pseudo Instagram, site Web et bio{")"} en cliquant sur les icônes <span className="faq__nolink">
                  <strong><BiEdit className="verticalcenter" /></strong>
                </span>.
              </div>
              <div className="legal__div--txt faq__divrow">
                <img
                  className="faq__img"
                  src="https://www.lecturecommune.fr/assets/faq/profile.png"
                  alt="Page de présentation d'une lecture"
                />
              </div>
            </div>
          </div>
          <div className="faq__box">
            <div
              id="show-readings"
              className="faq__headbar"
              onClick={() => {
                open("show-readings");
              }}
            >
              <h3 className="faq__headbar--title">Consulter les lectures</h3>
              <BsChevronUp
                id="show-readings__chevron"
                className="faq__headbar--chevron"
              />
            </div>
            <div id="show-readings__detail" className="faq">
              <div>
                Une selection de lectures communes est proposée sur{" "}
                <span
                  className="faq__link"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  <strong>la page d'accueil </strong>
                  <HiOutlineCursorClick className="faq__link--icon" />
                </span>{" "}
                . Vous y trouverez 3 sections :{" "}
                <ul>
                  <li>
                    Lectures à venir : <strong>une sélection</strong> de
                    lectures communes qui débutent prochainement.
                  </li>
                  <li>
                    Lectures en cours : <strong>une sélection</strong> de
                    lectures qui ont déjà débuté mais auxquelles vous pouvez
                    encore participer.
                  </li>
                  <li>
                    Dernières lectures ajoutées : <strong>une sélection</strong>{" "}
                    des dernières lectures communes créées par les lecteurs.
                  </li>
                </ul>
              </div>
              <div className="legal__div--txt faq__divrow">
                <img
                  className="faq__img"
                  src="https://www.lecturecommune.fr/assets/faq/home.png"
                  alt="Page d'accueil"
                />
              </div>
              <div>
                <br />
                Dans la catégorie{" "}
                <span className="faq__nolink">
                  <strong>Lectures en cours</strong>
                </span>{" "}
                , vous pourrez consulter <strong>l'état d'avancement</strong> de
                la lecture. Il se matérialise par une barre de progression :
              </div>
              <div className="legal__div--txt faq__divrow">
                <img
                  className="faq__img--small"
                  src="https://www.lecturecommune.fr/assets/faq/card.png"
                  alt="Toutes les lectures"
                />
              </div>
              <div>
                <br />
                Dans le menu{" "}
                <span
                  className="faq__link"
                  onClick={() => {
                    navigate("/reading/all");
                  }}
                >
                  <strong>Toutes les Lectures </strong>
                  <HiOutlineCursorClick className="faq__link--icon" />
                </span>{" "}
                , vous pourrez consulter <strong>toutes</strong> les lectures
                auxquelles vous pouvez participer :
                <ul>
                  <li>En liste</li>
                  <li>
                    Sur un calendrier : la semaine actuelle s'affiche, et vous
                    pouvez naviguer entre les semaines grâce aux boutons{" "}
                    <span className="faq__nolink">
                      <strong>{"<"}</strong>
                    </span>{" "}
                    et{" "}
                    <span className="faq__nolink">
                      <strong>{">"}</strong>
                    </span>{" "}
                    .
                  </li>
                </ul>
              </div>
              <div className="legal__div--txt faq__divrow">
                <img
                  className="faq__img2"
                  src="https://www.lecturecommune.fr/assets/faq/allreadings.png"
                  alt="Toutes les lectures"
                />
                <img
                  className="faq__img2"
                  src="https://www.lecturecommune.fr/assets/faq/scheduler.png"
                  alt="Calendrier"
                />
              </div>
            </div>
          </div>
          <div className="faq__box">
            <div
              id="reading"
              className="faq__headbar"
              onClick={() => {
                open("reading");
              }}
            >
              <h3 className="faq__headbar--title">
                Consulter le détail d'une lecture
              </h3>
              <BsChevronUp
                id="reading__chevron"
                className="faq__headbar--chevron"
              />
            </div>
            <div id="reading__detail" className="faq">
              <div>
                En cliquant sur une lecture, vous pourrez consulter les détails
                du livre, ainsi que les conditions proposées {"("}dates, nom du
                lecteur qui a créé la lecture, description de la LC et nombre de
                participants déjà inscrits{")"}.{" "}
              </div>
              <div className="legal__div--txt faq__divrow">
                <img
                  className="faq__img"
                  src="https://www.lecturecommune.fr/assets/faq/reading.png"
                  alt="Page de présentation d'une lecture"
                />
              </div>
              <div>
                <br />
                Si vous souhaitez intégrer cette lecture commune, vous utilisez
                le bouton{" "}
                <span className="faq__nolink">
                  <strong>Participer</strong>
                </span>
                .
              </div>
            </div>
          </div>
          <div className="faq__box">
            <div
              id="myreadings"
              className="faq__headbar"
              onClick={() => {
                open("myreadings");
              }}
            >
              <h3 className="faq__headbar--title">
                Accès rapide à vos lectures communes
              </h3>
              <BsChevronUp
                id="myreadings__chevron"
                className="faq__headbar--chevron"
              />
            </div>
            <div id="myreadings__detail" className="faq">
              <div>
                Le menu principal vous permet d'accéder rapidement à vos
                lectures. Vous pouvez consulter les lectures en cours, les
                lectures terminées, et les lectures que vous avez créées.{" "}
              </div>
              <div className="legal__div--txt faq__divrow">
                <img
                  className="faq__img"
                  src="https://www.lecturecommune.fr/assets/faq/myreadings.png"
                  alt="Menu Mes Lectures"
                />
              </div>
            </div>
          </div>
          <div className="faq__box">
            <div
              id="createreading"
              className="faq__headbar"
              onClick={() => {
                open("createreading");
              }}
            >
              <h3 className="faq__headbar--title">Créer une lecture commune</h3>
              <BsChevronUp
                id="createreading__chevron"
                className="faq__headbar--chevron"
              />
            </div>
            <div id="createreading__detail" className="faq">
              <div>
                Dans le menu{" "}
                <span className="faq__nolink">
                  <strong>Lectures</strong>
                </span>
                , vous avec la possibilité de créer une lecture commune en
                choisissant :{" "}
                <span className="faq__nolink">
                  <strong>Créer une lecture</strong>.
                </span>
                <br />
                <br />
                <p>
                  La première page vous permet de rechercher le livre en
                  renseignant l'ISBN / EAN. Vous consulterez alors les détails
                  trouvés sur ce livre et pourrez continuer en cliquant sur{' '}
                  <span className="faq__nolink">
                    <strong>Confirmer</strong>.
                  </span>
                </p>
              </div>
              <div className="legal__div--txt faq__divrow">
                <img
                  className="faq__img2"
                  src="https://www.lecturecommune.fr/assets/faq/create1.png"
                  alt="Recherche par ISBN EAN"
                />
                <img
                  className="faq__img2"
                  src="https://www.lecturecommune.fr/assets/faq/create2.png"
                  alt="Vérification du livre"
                />
              </div>
              <div>
                La page suivante vous pemettra de renseigner une description, et
                les dates de début et de fin de la lecture commune.
              </div>
              <div className="legal__div--txt faq__divrow">
                <img
                  className="faq__img"
                  src="https://www.lecturecommune.fr/assets/faq/create3.png"
                  alt="Menu Mes Lectures"
                />
              </div>
              <div>
                Après avoir cliqué sur{" "}
                <span className="faq__nolink">
                  <strong>Créer</strong>
                </span>
                , votre lecture est validée et les autres lecteurs peuvent y
                participer.
              </div>
            </div>
          </div>
          <div className="faq__box">
            <div
              id="readingable"
              className="faq__headbar"
              onClick={() => {
                open("readingable");
              }}
            >
              <h3 className="faq__headbar--title">Fonctionnalités</h3>
              <BsChevronUp
                id="readingable__chevron"
                className="faq__headbar--chevron"
              />
            </div>
            <div id="readingable__detail" className="faq">
              <div>
                Dans chaque lecture commune à laquelle vous participez, vous avez accès à 4 onglets qui vous permettent chacun d'obtenir de nouvelles fonctionnalités.< br/><br />
                <div className="legal__div--txt faq__divrow">
                <img
                  className="faq__img"
                  src="https://www.lecturecommune.fr/assets/faq/tabs.png"
                  alt="Onglets d'une LC"
                />
              </div><br />
                L'onglet{' '}
                <span className="faq__nolink">
                  <strong>Résumé</strong>
                </span>{" "}reprend l'ensemble des caractéristiques de la lecture commune.
                <br />
                <br />
                <p>L'onglet{" "}
                <span className="faq__nolink">
                  <strong>Lecteurs</strong>
                </span>{" "}
                  restitue une vision détaillée de tous les participants à la LC. Leur progression personnelle est affichée dans la barre de progression.
                </p>
              </div>
                <div className="legal__div--txt faq__divrow">
                <img
                  className="faq__img"
                  src="https://www.lecturecommune.fr/assets/faq/readers.png"
                  alt="Onglet Lecteurs"
                />
              </div>
              <div>
                L'onglet <span className="faq__nolink">
                  <strong>Discussion</strong>
                </span> permet d'échanger avec les autres participants à la LC.
              </div>
              <div className="legal__div--txt faq__divrow">
                <img
                  className="faq__img"
                  src="https://www.lecturecommune.fr/assets/faq/chat.png"
                  alt="Onglet Discussion"
                />
              </div>
              <div>
               Une description détaillée de l'onglet <span className="faq__nolink">
                  <strong>Discussion</strong>
                </span> est à lire ci-après.
              </div>
              <div>
               L'onglet <span className="faq__nolink">
                  <strong>Progression</strong>
                </span> vous permet de mettre à jour le nombre de pages que vous avez lues. L'organisateur peut ainsi suivre l'évolution globale des participants.
              </div>
            </div>
          </div>
          <div className="faq__box">
            <div
              id="chatspoil"
              className="faq__headbar"
              onClick={() => {
                open("chatspoil");
              }}
            >
              <h3 className="faq__headbar--title">
                Discussion & Messages <AiFillStar />
              </h3>
              <BsChevronUp
                id="chatspoil__chevron"
                className="faq__headbar--chevron"
              />
            </div>
            <div id="chatspoil__detail" className="faq">
              <div>
                Lors de l'envoi d'un message dans la discussion, vous avez la possibilité d'activer la fonction <span className="faq__nolink">
                  <strong>Spoiler</strong></span> afin de ne pas gâcher la lecture de chacun. Pour l'activer, il suffit de cocher la case correspondante :
              </div>
              <div className="legal__div--txt faq__divrow">
                <img
                  className="faq__img"
                  src="https://www.lecturecommune.fr/assets/faq/spoilcheckbox.png"
                  alt="Menu Mes Lectures"
                />
              </div>
              <div>
                Une fois le message envoyé, sont statut <span className="faq__nolink">
                  <strong>Spoiler</strong></span> est confirmé par l'affichage de cette mention sur le message : 
              </div>
              <div className="legal__div--txt faq__divrow">
                <img
                  className="faq__imgsmall"
                  src="https://www.lecturecommune.fr/assets/faq/spoilmsgself.png"
                  alt="Menu Mes Lectures"
                />
              </div>
              <div>
                Les messages envoyés par d'autres lecteurs avec cette option apparaissent floutés. Pour les lire, cliquez simplement sur chaque message.
              </div>
              <div className="legal__div--txt faq__divrow">
                <img
                  className="faq__imgsmall"
                  src="https://www.lecturecommune.fr/assets/faq/spoilmsg.png"
                  alt="Menu Mes Lectures"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default FAQDetail;
