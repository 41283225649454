import { useEffect, useState } from "react";
import "../readingsCreated/readingsCreated.css";
import Loader from "../loader/loader";
import ReadingShow from "../readingShow/readingShow";

function GetLastSessions() {
  const [data, setData] = useState();
  const dataLast = async () => {
    const testConnect = await fetch(
      "https://www.lecturecommune.fr/api/reading/all",
      {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
          },
      }
    );
    if (testConnect.status === 200) {
      const result = await testConnect.json();
      await result.sort(function(a, b) {
        return (new Date(b.createdAt) - new Date(a.createdAt))
      });
      let newArrayLast = [];
      for (let index = 0; index < 15; index++) {
        newArrayLast.push(result[index]);
      }
      setData(newArrayLast);
    }
  };
  useEffect( () => { dataLast() }, []);
  return (
    
          data ? (
            <main>
            <div className="readinginprocess"><h2 className="readinginprocess__title">Dernières lectures ajoutées</h2>
              <div className="readinginprocess__list scheduler__table">
            {
            data.map((data2) => (
              <ReadingShow key={data2.id} reading={data2} limitDate={true} />
            ))
            }
            </div>
          </div>
            </main>
          ) : (
            <Loader />
          )
  );
}

export default GetLastSessions;
