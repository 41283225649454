import Footer from '../../components/footer/footer';
import Header from '../../components/header/header';
import ReadingsCreated from '../../components/readingsCreated/readingsCreated';
import TopFooter from '../../components/topFooter/topFooter';

function ReadingCreated() {
  return (
    <>
      <Header />
      <ReadingsCreated />
      <TopFooter />
      <Footer />
    </>
  );
}

export default ReadingCreated;