import { useState, useEffect } from "react";
import "./readingChat.css";

const ReadingChat = ({ messages, sessionId, followers }) => {
  // console.log(followers);
  const [msgs, setMsgs] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const userToken = localStorage.lecturecommuneUserId;
  const token = localStorage.lecturecommuneToken;
  const changeSpoil = () => {
    let test = "test";
  };
  const fetchMsgs = async () => {
    const dlMsgs = await fetch(
      `https://www.lecturecommune.fr/api/reading/open/${sessionId}`,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    if (dlMsgs.status === 200) {
      const readMsgs = await fetch(
        `https://www.lecturecommune.fr/api/reading/updateread/${sessionId}`,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      if (readMsgs.status === 200) {
        const result = await dlMsgs.json();
        setMsgs(result.session_messages);
        setTimeout(() => {
          const chatbox = document.getElementById("chatbox");
          if (chatbox) {
            chatbox.scrollTo({
              top: 99999999999,
              behavior: "smooth",
            });
          }
        }, 100);
      }
    }
  };
  useEffect(() => {
    fetchMsgs();
  }, []);
  const postMsg = async () => {

if (isLoading === false) {
  setIsLoading(true);
  document
      .getElementById("chatsend")
      .classList.add("chatwrite__sendbtn--none");
    const messageToSend = document.getElementById("msg").value;
    const dateToSend = new Date(Date.now());
    const checked = document.getElementById("spoil").checked;
    if (messageToSend !== "") {
      const toSend = {
        message: messageToSend,
        date: dateToSend,
        spoil: checked,
      };
      const postMessage = await fetch(
        `https://www.lecturecommune.fr/api/reading/postmsg/${sessionId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify(toSend),
        }
      );
      if (postMessage.status === 200) {
        fetchMsgs();
        document.getElementById("msg").value = "";
      } else {
        const errr = await postMessage.json();
        console.log(errr);
      }
      document
        .getElementById("chatsend")
        .classList.remove("chatwrite__sendbtn--none");
    } else {
      document
        .getElementById("chatsend")
        .classList.remove("chatwrite__sendbtn--none");
      alert("Message vide");
    }
    setIsLoading(false);
} else {
  console.log('already ...');
}
    


  };
  const checkCharacter = (event) => {
    if (event.key === '@') {
      console.log('Press');
    }
  }
  const AddCheckMention = () => {
  document.addEventListener('keydown', checkCharacter);
  };
  const deleteCheckMention = () => {
    document.removeEventListener('keydown', checkCharacter);
    };
  return (
    <div className="chat__global">
      <div id="chatbox" className="chat">
        {msgs ? (
          msgs.map((msg) => (
            <div
              className={`${
                msg.id === Number(userToken)
                  ? "chat__msgself"
                  : "chat__msgother"
              }`}
              key={`${msg.id}${Math.random()}`}
            >
              <div
                id={`${msg.id}${msg.date}`}
                className={`${
                  msg.id === Number(userToken)
                    ? "chat__msgself--msg"
                    : "chat__msgother--msg"
                }${
                  msg.id !== Number(userToken) &&
                  msg.spoil &&
                  msg.spoil === true
                    ? " chat__hidden"
                    : ""
                }`}
                onClick={() => {
                  if (
                    msg.id !== Number(userToken) &&
                    msg.spoil &&
                    msg.spoil === true
                  ) {
                    const targetSpoil = document.getElementById(
                      `${msg.id}${msg.date}`
                    );
                    targetSpoil.classList.remove("chat__hidden");
                    targetSpoil.classList.add("chat__display");
                  }
                }}
              >
                <p className={msg.id === Number(userToken) && msg.spoil && msg.spoil === true ? "chat__msgself--spoil" : ""}>{msg.message}</p>
                <p className="chat__date">
                  {msg.date
                    ? (new Date(msg.date).getDate() < 10
                        ? `0${new Date(msg.date).getDate()}/`
                        : `${new Date(msg.date).getDate()}/`) +
                      (new Date(msg.date).getMonth() + 1 < 10
                        ? `0${new Date(msg.date).getMonth() + 1}/`
                        : `${new Date(msg.date).getMonth() + 1}/`) +
                      `${String(new Date(msg.date).getFullYear()).substring(
                        2,
                        4
                      )} ` +
                      (new Date(msg.date).getHours() < 10
                        ? `0${new Date(msg.date).getHours()}h`
                        : `${new Date(msg.date).getHours()}h`) +
                      (new Date(msg.date).getMinutes() < 10
                        ? `0${new Date(msg.date).getMinutes()}`
                        : `${new Date(msg.date).getMinutes()}`)
                    : ""}
                </p>
                {msg.id === Number(userToken) && msg.spoil && msg.spoil === true ? <img className="chat__msgself--spoilimg" src="https://www.lecturecommune.fr/assets/images/spoileronly.png" alt="Spoil détecté" /> : ""}
              </div>
              <p
                className={`${
                  msg.id === Number(userToken)
                    ? "chat__msgself--author"
                    : "chat__msgother--author"
                }`}
              >{`${msg.username}`}</p>
            </div>
          ))
        ) : (
          <p>Chargement</p>
        )}
      </div>
      <div className="chatwrite">
        <div className="chatwrite__spoiler">
          <div>
            <img
              className="chatwrite__spoiler--img"
              src="https://www.lecturecommune.fr/assets/images/spoil.png"
              alt="Spoiler Alert"
            />
          </div>
          <input
            id="spoil"
            type="checkbox"
            className="chatwrite__spoiler--checkbox"
            onChange={changeSpoil}
            defaultChecked={false}
          />
        </div>
        <div className="chatwrite__left">
          <label className="chatwrite__label">Message :</label>
          <textarea
            id="msg"
            name="msg"
            className="chatwrite__txtarea"
            placeholder="Votre message..."
            wrap="soft"
            // onFocus={AddCheckMention}
            // onBlur={deleteCheckMention}
          />
        </div>
        <div
          id="chatsend"
          className="chatwrite__sendbtn"
          onClick={postMsg}
        >{`>`}</div>
      </div>
    </div>
  );
};

export default ReadingChat;
