import AdminReadings from '../../components/adminreadings/adminreadings';
import Footer from '../../components/footer/footer';
import Header from '../../components/header/header';

function AdminViewReadings() {
  return (
    <>
      <Header />
      <AdminReadings />
      <Footer />
    </>
  );
}

export default AdminViewReadings;