import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./mainpage.css";
import BoxReading from "../boxreading/boxreading";
import LoaderOnly from "../loaderonly/loaderonly";
import AdsenseBoxReading from "../adsenseBoxReading/adsenseBoxReading";
import AdsenseNav from "../adsenseNav/adsenseNav";
import appleImg from "../../assets/images/appstore.png";
import googleImg from "../../assets/images/googleplay.png";

function MainPage() {
  // const adNbr1 = "3476034636";
  // const adNbr2 = "4478372532";
  // const adNbr3 = "3165290864";
  // const adNbr4 = "9539127528";
  // const adNbr5 = "2771110015";
  // const adNbr6 = "8226045857";
  // const adNbr7 = "1828158462";
  // const adNbr8 = "4949979971";
  // const adNbr9 = "8685210858";
  const randomNbr = Math.round(Math.random());
  const [data1, setData1] = useState();
  const [data2, setData2] = useState();
  const [data3, setData3] = useState();
  const [data4, setData4] = useState();
  const [random1, setRandom1] = useState();
  const [random2, setRandom2] = useState();
  const [random3, setRandom3] = useState();
  const [random4, setRandom4] = useState();
  const [self1, setSelf1] = useState();
  const [self2, setSelf2] = useState();
  const [self3, setSelf3] = useState();
  const [self4, setSelf4] = useState();
  const navigate = useNavigate();
  const dataFetch = async () => {
    const testConnect = await fetch(
      "https://www.lecturecommune.fr/api/reading/last",
      {
        method: "GET",
      }
    );
    if (testConnect.status === 200) {
      const resultData = await testConnect.json();

      let newArray1 = [];
      let addNbr = 0;
      resultData.map((reading) => {
        if (addNbr < 1 + randomNbr) {
          newArray1.push(reading);
        }
        addNbr += 1;
      });
      setData1(newArray1);
      // console.log("randomNbr : ", randomNbr);
      let newArray2 = [];
      let addNbr2 = 0;
      resultData.map((reading) => {
        // console.log("test : si ", addNbr2, " < ", (3 + randomNbr), " et ", addNbr2, " >= ", (1 + addNbr));
        if (addNbr2 < 3 + randomNbr && addNbr2 >= newArray1.length) {
          newArray2.push(reading);
        }
        addNbr2 += 1;
      });
      setData2(newArray2);
      let newArray3 = [];
      let addNbr3 = 0;
      resultData.map((reading) => {
        if (
          addNbr3 < 7 + randomNbr &&
          addNbr3 >= newArray1.length + newArray2.length
        ) {
          newArray3.push(reading);
        }
        addNbr3 += 1;
      });
      setData3(newArray3);
      let newArray4 = [];
      let addNbr4 = 0;
      resultData.map((reading) => {
        if (
          addNbr4 < 14 &&
          addNbr4 >= newArray1.length + newArray2.length + newArray3.length
        ) {
          newArray4.push(reading);
        }
        addNbr4 += 1;
      });
      setData4(newArray4);
      // console.log(newArray1);
      // console.log(newArray2);
      // console.log(newArray3);
      // console.log(newArray4);
    }
    randomFetch();
    selfFetch();
  };
  const randomFetch = async () => {
    const randomConnect = await fetch(
      "https://www.lecturecommune.fr/api/reading/top",
      {
        method: "GET",
      }
    );
    if (randomConnect.status === 200) {
      const resultrandom = await randomConnect.json();
      let newArray1 = [];
      let addNbr = 0;
      resultrandom.map((reading) => {
        if (addNbr < 1 + randomNbr) {
          newArray1.push(reading);
        }
        addNbr += 1;
      });
      setRandom1(newArray1);
      // console.log("randomNbr : ", randomNbr);
      let newArray2 = [];
      let addNbr2 = 0;
      resultrandom.map((reading) => {
        // console.log("test : si ", addNbr2, " < ", (3 + randomNbr), " et ", addNbr2, " >= ", (1 + addNbr));
        if (addNbr2 < 3 + randomNbr && addNbr2 >= newArray1.length) {
          newArray2.push(reading);
        }
        addNbr2 += 1;
      });
      setRandom2(newArray2);
      let newArray3 = [];
      let addNbr3 = 0;
      resultrandom.map((reading) => {
        if (
          addNbr3 < 7 + randomNbr &&
          addNbr3 >= newArray1.length + newArray2.length
        ) {
          newArray3.push(reading);
        }
        addNbr3 += 1;
      });
      setRandom3(newArray3);
      let newArray4 = [];
      let addNbr4 = 0;
      resultrandom.map((reading) => {
        if (
          addNbr4 < 14 &&
          addNbr4 >= newArray1.length + newArray2.length + newArray3.length
        ) {
          newArray4.push(reading);
        }
        addNbr4 += 1;
      });
      setRandom4(newArray4);
      // console.log(newArray1);
      // console.log(newArray2);
      // console.log(newArray3);
      // console.log(newArray4);
    }
  };
  const selfFetch = async () => {
    const selfConnect = await fetch(
      "https://www.lecturecommune.fr/api/reading/random",
      {
        method: "GET",
      }
    );
    if (selfConnect.status === 200) {
      const resultself = await selfConnect.json();
      let newArray = [];
      let Nbr = 0;
      resultself.map((reading) => {
        if (
          new Date(reading.session_begin_date) < new Date(Date.now()) &&
          Nbr < 14
        ) {
          newArray.push(reading);
          Nbr += 1;
        }
      });

      let newArray1 = [];
      let addNbr = 0;
      newArray.map((reading) => {
        if (addNbr < 1 + (randomNbr === 0 ? 1 : 0)) {
          newArray1.push(reading);
        }
        addNbr += 1;
      });
      setSelf1(newArray1);
      // console.log("randomNbr : ", randomNbr);
      let newArray2 = [];
      let addNbr2 = 0;
      newArray.map((reading) => {
        // console.log("test : si ", addNbr2, " < ", (3 + randomNbr), " et ", addNbr2, " >= ", (1 + addNbr));
        if (
          addNbr2 < 3 + (randomNbr === 0 ? 1 : 0) &&
          addNbr2 >= newArray1.length
        ) {
          newArray2.push(reading);
        }
        addNbr2 += 1;
      });
      setSelf2(newArray2);
      let newArray3 = [];
      let addNbr3 = 0;
      newArray.map((reading) => {
        if (
          addNbr3 < 7 + (randomNbr === 0 ? 1 : 0) &&
          addNbr3 >= newArray1.length + newArray2.length
        ) {
          newArray3.push(reading);
        }
        addNbr3 += 1;
      });
      setSelf3(newArray3);
      let newArray4 = [];
      let addNbr4 = 0;
      newArray.map((reading) => {
        if (
          addNbr4 < 14 &&
          addNbr4 >= newArray1.length + newArray2.length + newArray3.length
        ) {
          newArray4.push(reading);
        }
        addNbr4 += 1;
      });
      setSelf4(newArray4);
      // console.log(newArray1);
      // console.log(newArray2);
      // console.log(newArray3);
      // console.log(newArray4);

      //   setSelf(newArray);
    }
  };
  useEffect(() => {
    dataFetch();
  }, []);
  return (
    <main className="main__display">
      <div className="main">
        <div className="main__intro--store">
          <a
            href="https://apps.apple.com/fr/app/lecture-commune/id6463268154"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="storelink" src={appleImg} alt="App Store" />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.lecturecommune.app"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="storelink" src={googleImg} alt="Google Play" />
          </a>
        </div>
        <div
          className={`main__intro${
            localStorage.lecturecommuneToken &&
            localStorage.lecturecommuneToken !== ""
              ? " main__intro--one"
              : ""
          }`}
        >
          <div className="main__btn" onClick={() => navigate("/faq")}>
            Mode d'emploi
          </div>
          {(!localStorage.lecturecommuneToken ||
            localStorage.lecturecommuneToken === "") && (
            <div className="main__btn" onClick={() => navigate("/signup")}>
              S'inscrire
            </div>
          )}
        </div>
        <div className="main__title">
          <h2 className="main__title--txt">Lectures à venir</h2>
        </div>
        <div className="content">
          {random1 && random2 && random3 && random4 ? (
            <>
              {random1.map((data3) => (
                <BoxReading
                  key={data3.id}
                  dataReading={data3}
                  limitDate={true}
                />
              ))}
              {/* <AdsenseBoxReading slotNbr={adNbr4} /> */}
              {random2.map((data3) => (
                <BoxReading
                  key={data3.id}
                  dataReading={data3}
                  limitDate={true}
                />
              ))}
              {/* <AdsenseBoxReading slotNbr={adNbr1} /> */}
              {random3.map((data3) => (
                <BoxReading
                  key={data3.id}
                  dataReading={data3}
                  limitDate={true}
                />
              ))}
              {/* <AdsenseBoxReading slotNbr={adNbr6} /> */}
              {random4.map((data3) => (
                <BoxReading
                  key={data3.id}
                  dataReading={data3}
                  limitDate={true}
                />
              ))}
            </>
          ) : (
            <LoaderOnly />
          )}
        </div>
        <div className="main__sublink">
          <div
            className="main__sublink--link"
            onClick={() => navigate("/reading/all")}
          >
            Voir toutes les lectures
          </div>
        </div>
        <AdsenseNav slotNbr={"4038678391"} />
        <div className="main__title">
          <h2 className="main__title--txt">Lectures en cours</h2>
        </div>
        <div className="content">
          {self1 && self2 && self3 && self4 ? (
            <>
              {self1.map((data3) => (
                <BoxReading
                  key={data3.id}
                  dataReading={data3}
                  progress={true}
                />
              ))}
              {/* <AdsenseBoxReading slotNbr={adNbr1} /> */}
              {self2.map((data3) => (
                <BoxReading
                  key={data3.id}
                  dataReading={data3}
                  progress={true}
                />
              ))}
              {/* <AdsenseBoxReading slotNbr={adNbr2} /> */}
              {self3.map((data3) => (
                <BoxReading
                  key={data3.id}
                  dataReading={data3}
                  progress={true}
                />
              ))}
              {/* <AdsenseBoxReading slotNbr={adNbr3} /> */}
              {self4.map((data3) => (
                <BoxReading
                  key={data3.id}
                  dataReading={data3}
                  progress={true}
                />
              ))}
            </>
          ) : (
            // self.map((selfrandom) => (
            //   <BoxReading
            //     key={selfrandom.id}
            //     dataReading={selfrandom}
            //     progress={true}
            //   />
            // ))
            <LoaderOnly />
          )}
        </div>
        <div className="main__sublink">
          <div
            className="main__sublink--link"
            onClick={() => navigate("/reading/all")}
          >
            Voir toutes les lectures
          </div>
        </div>
        <AdsenseNav slotNbr={"9551941096"} />
        <div className="main__title">
          <h2 className="main__title--txt">Dernières lectures créées</h2>
        </div>
        <div className="content">
          {data1 && data2 && data3 && data4 ? (
            <>
              {data1.map((data3) => (
                <BoxReading
                  key={data3.id}
                  dataReading={data3}
                  limitDate={true}
                />
              ))}
              {/* <AdsenseBoxReading slotNbr={adNbr7} /> */}
              {data2.map((data3) => (
                <BoxReading
                  key={data3.id}
                  dataReading={data3}
                  limitDate={true}
                />
              ))}
              {/* <AdsenseBoxReading slotNbr={adNbr3} /> */}
              {data3.map((data3) => (
                <BoxReading
                  key={data3.id}
                  dataReading={data3}
                  limitDate={true}
                />
              ))}
              {/* <AdsenseBoxReading slotNbr={adNbr9} /> */}
              {data4.map((data3) => (
                <BoxReading
                  key={data3.id}
                  dataReading={data3}
                  limitDate={true}
                />
              ))}
            </>
          ) : (
            // data.map((data2) => (
            //   <BoxReading key={data2.id} dataReading={data2} limitDate={true} />
            // ))
            <LoaderOnly />
          )}
        </div>
        <div className="main__sublink">
          <div
            className="main__sublink--link"
            onClick={() => navigate("/reading/all")}
          >
            Voir toutes les lectures
          </div>
        </div>
      </div>
    </main>
  );
}

export default MainPage;
