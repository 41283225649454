import { Link } from "react-router-dom";
import "./signup.css";
import { useState } from "react";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
const validator = require("email-validator");

const Signup = () => {
  const [email, setEmail] = useState("");
  const [accept, setAccept] = useState(false);
  const [emailValid, setEmailValid] = useState(false);
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [signupIsOk, setSignupIsOk] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const displayPassword = () => {
    if (showPass) {
      document.getElementById("password").setAttribute("type", "password");
      document.getElementById('form__eye--true').classList.add('form__eye--display');
      document.getElementById('form__eye--true').classList.remove('form__eye--nodisplay');
      document.getElementById('form__eye--false').classList.add('form__eye--nodisplay');
      document.getElementById('form__eye--false').classList.remove('form__eye--display');
      setShowPass(false);
    } else {
      document.getElementById("password").setAttribute("type", "text");
      document.getElementById('form__eye--false').classList.add('form__eye--display');
      document.getElementById('form__eye--false').classList.remove('form__eye--nodisplay');
      document.getElementById('form__eye--true').classList.add('form__eye--nodisplay');
      document.getElementById('form__eye--true').classList.remove('form__eye--display');
      setShowPass(true);
    }
  };
  const acceptCgu = () => {
    !accept ? setAccept(true) : setAccept(false);
  }
  const handleClick = () => {
    setEmail(document.getElementById("email").value);
    checkEmail();
  };
  const handleClick2 = () => {
    setPassword(document.getElementById("password").value);
  };
  const handleClick3 = () => {
    setUsername(document.getElementById("username").value);
  };
  const checkEmail = () => {
    const testValid = validator.validate(
      document.getElementById("email").value
    );
    setEmailValid(testValid);
    document
      .querySelector(".form__input--email")
      .classList.add(`form__input--email--${emailValid}`);
    document
      .querySelector(".form__input--email")
      .classList.remove(`form__input--email--${!emailValid}`);
  };
  const sendData = async (e) => {
    e.preventDefault();
    if (isLoading === false) {
      setIsLoading(true);
      if (
        emailValid &&
        email &&
        username &&
        username.length > 4 &&
        username.length < 31 &&
        password &&
        accept &&
        password.length > 7
      ) {
        let dataToSend = {
          email: email,
          username: username,
          password: password,
        };
        let response = await fetch(
          "https://www.lecturecommune.fr/api/user/signup",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(dataToSend),
          }
        );
        if (response && response.status === 200) {
          setSignupIsOk(true);
        } else if (response && response.status === 400) {
          setSignupIsOk(false);
          let resp = await response.json();
          if (resp === "Username already used") {
            alert("Nom d'utilisateur non disponible.");
          }
          if (resp === "Email already used") {
            alert("Adresse mail déjà liée à un compte.");
          }
        } else {
          setSignupIsOk(false);
          alert("Erreur. Veuillez réessayer.");
        }
      } else {
          setSignupIsOk(false);
        alert(
          "Données incomplètes ou manquantes. Veuillez vérifier et réessayer."
        );
      }
      setIsLoading(false);
    } else {
      console.log('already running');
    }
  };
  return (
    <main className="main__signup">
      <h2 className="connexion__title">{!signupIsOk ? 'Inscription' : 'Félicitations !'}</h2>
      {!signupIsOk ?
      <form>
        <div className="form__div">
          <label>Nom d'utilisateur :</label>
          <br />
          <input
            id="username"
            className="form__input"
            type="text"
            onChange={handleClick3}
          />
          <div className="form__input--explain"><i>5 caractères minimum</i></div>
        </div>
        <div className="form__div">
          <label>Adresse email :</label>
          <br />
          <input
            id="email"
            name="email"
            className="form__input"
            type="email"
            onChange={handleClick}
          />
          <div className="form__input--email form__input--email--true">
            {/* Adresse email incorrecte */}
          </div>
        </div>
        <div className="form__div">
          <label>Mot de passe :</label>
          <br />
          <div className="form__eye--line">
            <input
              id="password"
              className="form__input form__input--pass"
              type="password"
              onChange={handleClick2}
            />
            <div className="form__input--password" onClick={displayPassword}>
              <AiOutlineEyeInvisible id="form__eye--false" className="form__eye form__eye--nodisplay" />
              <AiOutlineEye id="form__eye--true" className="form__eye form__eye--display" />
            </div>
          </div>
          <div className="form__input--explain"><i>8 caractères minimum</i></div>
        </div>
        <div className="form__divline">
        <input
          className="form__checkcgu"
          type="checkbox"
          onClick={acceptCgu}
        />
        <p>Je reconnais avoir lu et compris <Link to='/cgu'>les CGU</Link> et je les accepte.</p></div>
        <input
          className="form__submit nodecoration"
          type="button"
          value="S'inscrire"
          onClick={sendData}
        />
      </form>
: <div className="signup"><p className="signup__center">Votre compte a bien été créé.</p><br />
<p className="signup__center">Un mail d'activation vous a été envoyé. Veuillez suivre le lien pour activer votre compte.</p><br /><br />
<Link to='/connect' className="form__submit nodecoration">Se connecter</Link></div>}
    </main>
  );
};

export default Signup;
