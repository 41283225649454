import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import CGUText from '../../components/cgu/cgu';
import TopFooter from '../../components/topFooter/topFooter';

function CGU() {
  return (
    <>
      <Header />
      <CGUText />
      <TopFooter />
      <Footer />
    </>
  );
}

export default CGU;