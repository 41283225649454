import "./loaderonly.css";

const LoaderOnly = () => {
  return (
          <div className="loaderonly__animation">
            <div className="loaderonly__animation--circle"></div>
          </div>
  );
};

export default LoaderOnly;