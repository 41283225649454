import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./confirm.css";
import configUrl from "../../assets/config";

const ConfirmPass = ({ pass }) => {
  const [isOK, setIsOk] = useState();
  const [isValid, setIsValid] = useState();
  async function sendData() {
    let response = await fetch(
      `${configUrl.api}/user/confirm/${pass.activationPass}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response && response.ok) {
      setIsValid(true);
      setIsOk(true);
    } else if (response && !response.ok) {
      setIsValid(false);
      setIsOk(true);
    } else {
      setIsValid(false);
      setIsOk(false);
    }
  }
  useEffect( () => { sendData() }, []);
  return (
    <main>
      {isOK ? (
        isValid ? (
          <div className="confirm">
            <h2 className="confirm__title">{isOK ? "Email confirmé !" : ""}</h2>
            <div className="confirm__txt">
              <p className="confirm__txt--txt">
                Votre compte a bien été validé
              </p>
              <p className="confirm__txt--txt">
                Vous pouvez maintenant vous connecter
              </p>
              <Link to="/connect" className="nodecoration">
                <button className="form__book">Se connecter</button>
              </Link>
            </div>
          </div>
        ) : (
          <div className="confirm">
            <div className="confirm__txt">
              <h2>Erreur</h2>
              <p className="confirm__txt--txt">Erreur lors de la validation.</p>
              <p className="confirm__txt--txt">
                Veuillez réessayer en suivant le lien reçu par mail.
              </p>
              <Link to="/" className="nodecoration">
                <button className="form__book">Accueil</button>
              </Link>
            </div>
          </div>
        )
      ) : (
        <div className="confirm">
          <div className="confirm__animation">
            <div className="confirm__animation--circle"></div>
          </div>
        </div>
      )}
    </main>
  );
};

export default ConfirmPass;
