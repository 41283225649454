import { Link, useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { BiArrowBack } from "react-icons/bi";
import "./adminbibliooneupdate.css";
import Header from "../header/header";
import Footer from "../footer/footer";

function AdminBiblioOneUpdate() {
  const demand = useParams();
  const [dataUsers, setDataUsers] = useState();
  const [dataServerOK, setDataServerOK] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isbnOK, setIsbnOK] = useState();
  const [titleOK, setTitleOK] = useState();
  const [authorOK, setAuthorOK] = useState();
  const [resumeOK, setResumeOK] = useState();
  const [nbrOK, setNbrOK] = useState();
  const [priceOK, setPriceOK] = useState();
  const navigate = useNavigate();

  const isbnChange = (event) => {
    setIsbnOK(event.target.value);
  };
  const titleChange = (event) => {
    setTitleOK(event.target.value);
  };
  const authorChange = (event) => {
    setAuthorOK(event.target.value);
  };
  const resumeChange = (event) => {
    setResumeOK(event.target.value);
  };
  const nbrChange = (event) => {
    setNbrOK(event.target.value);
  };
  const priceChange = (event) => {
    setPriceOK(event.target.value);
  };

  const sendUpdate = async () => {
    let toSend = {
      isbn: isbnOK,
      title: titleOK,
      author: authorOK,
      cover: `https://www.lecturecommune.fr/assets/covers/${isbnOK}.jpeg`,
      fourth: resumeOK,
      nbrpages: nbrOK,
      price: priceOK,
    };
    const token = localStorage.lecturecommuneToken;
    const updateData = await fetch(
      `https://www.lecturecommune.fr/api/mybooks/updateadmin`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(toSend),
      }
    );
    if (updateData.status === 200) {
      return true;
    } else {
      return false;
    }
  };

  const first = async () => {
    setIsLoading(true);
    if (!localStorage.lecturecommuneCheckAdmin) {
      navigate("/");
    }
    const token = localStorage.lecturecommuneToken;
    const testConnect = await fetch(
      `https://www.lecturecommune.fr/api/user/checkadmin`,
      {
        method: "post",
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    if (testConnect.status === 200) {
      const fetchUsers = await fetch(
        `https://www.lecturecommune.fr/api/mybooks/getoneadminmustupdate/${demand.isbn}`,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      if (fetchUsers.status === 200) {
        let result2 = await fetchUsers.json();
        setDataUsers(result2);
        //
        let searchBook = { isbn: demand.isbn };
        const fetchCatalog = await fetch(
          `https://www.lecturecommune.fr/api/reading/getbook`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
            body: JSON.stringify(searchBook),
          }
        );
        if (fetchCatalog.status === 200) {
          const result3 = await fetchCatalog.json();
          console.log(result3);
          setIsbnOK(demand.isbn);
          setTitleOK(result3.name);
          setAuthorOK(result3.book_author);
          setResumeOK(result3.fourth);
          setPriceOK(result2[0].price);
          setNbrOK(result3.nbrpages);
          setDataServerOK(true);
        } else {
          setDataServerOK(false);
        }
        //
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } else {
      navigate("/error");
    }
  };

  useEffect(() => {
    first();
  }, []);

  return (
    <>
      <Header />
      <main>
        {!isLoading && (
          <>
            <div className="readingdetails__firstline">
              <h2 className="bookcreated__title">Livre à modifier</h2>
              <div
                className="readingdetails__firstline--back"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <BiArrowBack className="cursorlink" />
              </div>
            </div>
            <h3 className="bookcreated__title adminusers__title">
              {dataUsers && `${dataUsers.length} livres`}
            </h3>
            <div className="bookcreated__title adminusers__title">
              <p>Livres concernés :</p>
            </div>
            <div className="adminusers bookcreated__title adminusers__title">
              {dataUsers && (
                <div className="adminusers__line">
                  <p className="adminusers__line--1">
                    <strong>Id</strong>
                  </p>
                  <p className="adminusers__line--2">
                    <strong>User Id</strong>
                  </p>
                  <p className="adminusers__line--2">
                    <strong>ISBN</strong>
                  </p>
                  <p className="adminusers__line--2">
                    <strong>Titre</strong>
                  </p>
                  <p className="adminusers__line--5">
                    <strong>Cover</strong>
                  </p>
                  <p className="adminusers__line--5">
                    <strong>Fourth</strong>
                  </p>
                  <p className="adminusers__line--5">
                    <strong>Price</strong>
                  </p>
                  <p className="adminusers__line--5">
                    <strong>Pages nbr</strong>
                  </p>
                </div>
              )}
              {dataUsers &&
                dataUsers.map((user) => (
                  <div className="adminusers__line" key={user.id}>
                    <p
                      className="adminusers__line--1"
                      style={{
                        height: 110,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >{`${user.id}`}</p>
                    <p
                      className="adminusers__line--2"
                      style={{
                        height: 110,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >{`${user.user}`}</p>
                    <p
                      className="adminusers__line--2"
                      style={{
                        height: 110,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >{`${user.isbn}`}</p>
                    <p
                      className="adminusers__line--2"
                      style={{
                        height: 110,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >{`${user.title}`}</p>
                    {user.cover && user.cover !== "" ? (
                      <div
                        className="adminusers__line--2"
                        style={{
                          height: 110,
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          style={{ width: 65, height: 100 }}
                          src={user.cover}
                        />
                      </div>
                    ) : (
                      <p
                        className="adminusers__line--5"
                        style={{ height: 110 }}
                      >
                        X
                      </p>
                    )}
                    <p
                      className="adminusers__line--5"
                      style={{
                        height: 110,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      {user.fourth ? user.fourth : "X"}
                    </p>
                    <p
                      className="adminusers__line--5"
                      style={{
                        height: 110,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {user.price ? `${user.price} €` : "X"}
                    </p>
                    <p
                      className="adminusers__line--5"
                      style={{
                        height: 110,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      {user.nbrpages ? user.nbrpages : "X"}
                    </p>
                  </div>
                ))}
            </div>
            <div className="bookcreated__title adminusers__title">
              <p>Livre trouvé dans le catalogue :</p>
            </div>
            <div className="bookcreated__title adminusers__title">
              {dataServerOK ? (
                <div className="adminhome">
                  <div className="adminLine">
                    <label htmlFor="title">Titre</label>
                    <input
                      disabled={true}
                      type="text"
                      id="title"
                      name="title"
                      value={titleOK}
                    />
                  </div>
                  <div className="adminLine">
                    <label htmlFor="author">Auteur</label>
                    <input
                      disabled={true}
                      type="text"
                      id="author"
                      name="author"
                      value={authorOK}
                    />
                  </div>
                  <div className="adminLine">
                    <label htmlFor="resume">Résumé</label>
                    <textarea
                      disabled={true}
                      name="resume"
                      id="resume"
                      cols="50"
                      rows="8"
                      value={resumeOK}
                    ></textarea>
                  </div>
                  <div className="adminLine">
                    <label htmlFor="nbrpages">Nombre de pages</label>
                    <input
                      disabled={true}
                      type="text"
                      id="nbrpages"
                      name="nbrpages"
                      value={nbrOK}
                    />
                  </div>
                  <div className="adminLine">
                    <label htmlFor="price">Prix</label>
                    <input
                      type="text"
                      id="price"
                      name="price"
                      value={priceOK}
                      onChange={priceChange}
                    />
                  </div>
                  <div className="adminLine">
                    <p>Couverture</p>
                    <img
                      src={`https://www.lecturecommune.fr/assets/covers/${isbnOK}.jpeg`}
                      alt="Couverture"
                    />
                    <img
                      src={`https://www.lecturecommune.fr/assets/covers/${isbnOK}.webp`}
                      alt="Couverture"
                    />
                    <img
                      src={`https://www.lecturecommune.fr/assets/covers/${isbnOK}.avif`}
                      alt="Couverture"
                    />
                  </div>
                  <button
                    className="form__book"
                    onClick={async () => {
                      const updateFunction = await sendUpdate();
                      updateFunction ? alert("OK") : alert("ERREUR");
                    }}
                  >
                    Valider modifications
                  </button>
                </div>
              ) : (
                <p>Non trouvé</p>
              )}
            </div>
          </>
        )}
      </main>
      <Footer />
    </>
  );
}

export default AdminBiblioOneUpdate;
