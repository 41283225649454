import "./error404.css";
import { Link } from "react-router-dom";

function Error404() {
  return (
    <main>
      <div className="error">
        <h2>Oups !</h2>
        <p>Cette page n'existe pas.</p>
        <Link to='/' className="nodecoration"><button className="error--btn">Retourner à l'accueil</button></Link>
      </div>
    </main>
  );
}

export default Error404;