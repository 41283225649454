import AccountDisplay from "../../components/accountDisplay/accountDisplay";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";
import TopFooter from '../../components/topFooter/topFooter';

function Account() {
    return (
        <>
          <Header />
          <AccountDisplay />
          <TopFooter />
          <Footer />
        </>
      );
}

export default Account;
