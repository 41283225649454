import "./loader.css";

const Loader = () => {
  return (
    <main className="loader__global">
      <div className="loader">
          <div className="loader__animation">
            <div className="loader__animation--circle"></div>
          </div>
      </div>
    </main>
  );
};

export default Loader;