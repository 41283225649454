import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
// import "./adminAddCover.css";
import TopFooter from "../topFooter/topFooter";
import Footer from "../footer/footer";
import Header from "../header/header";

function AdminAddCover() {
  const [isLoading, setIsLoading] = useState(true);
  const [globalData, setGlobalData] = useState();
  const [isbnOK, setIsbnOK] = useState("");
  const [isbnPresent, setIsbnPresent] = useState(false);
  const [titleOK, setTitleOK] = useState("");
  const [titlePresent, setTitlePresent] = useState(false);
  const [authorOK, setAuthorOK] = useState("");
  const [resumeOK, setResumeOK] = useState("");
  const [nbrOK, setNbrOK] = useState("");
  const navigate = useNavigate();
  const isbnChange = (event) => {
    setIsbnOK(event.target.value);
  };
  const titleChange = (event) => {
    setTitleOK(event.target.value);
  };
  const authorChange = (event) => {
    setAuthorOK(event.target.value);
  };
  const resumeChange = (event) => {
    setResumeOK(event.target.value);
  };
  const nbrChange = (event) => {
    setNbrOK(event.target.value);
  };
  const isbnBlur = () => {
    setIsbnPresent(false);
    globalData.map((book) => {
      book.isbn === isbnOK && setIsbnPresent(true);
    });
  };
  const titleBlur = () => {
    setTitlePresent(false);
    globalData.map((book) => {
      book.name == titleOK && setTitlePresent(true);
    });
  };
  const first = async () => {
    setIsLoading(true);
    if (!localStorage.lecturecommuneCheckAdmin) {
      navigate("/");
    }
    const token = localStorage.lecturecommuneToken;
    const testConnect = await fetch(
      `https://www.lecturecommune.fr/api/user/checkadmin`,
      {
        method: "post",
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    if (testConnect.status === 200) {
      const getAllBooks = await fetch(
        `https://www.lecturecommune.fr/api/reading/getallbooks`,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      if (getAllBooks.status === 200) {
        const result = await getAllBooks.json();
        setGlobalData(result);
        setIsLoading(false);
      } else {
        navigate("/error");
      }
    } else {
      navigate("/error");
    }
  };
  const createBook = async () => {
    let newBook = {
      isbn: isbnOK,
      title: titleOK,
      author: authorOK,
      cover: `https://www.lecturecommune.fr/assets/covers/${isbnOK}.jpeg`,
      fourth: resumeOK,
      nbr: nbrOK,
    };
    let token = localStorage.lecturecommuneToken;
    let create = await fetch(
      `https://www.lecturecommune.fr/api/reading/addbook`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(newBook),
      }
    );
    if (create.status === 200) {
      alert("Ajouté !");
      setIsbnOK("");
      setTitleOK("");
      setResumeOK("");
      setNbrOK("");
    } else {
      alert("Erreur");
    }
  };

  // const handleSubmitFile = async (e) => {
  //   e.preventDefault();
  //   let token = localStorage.lecturecommuneToken;
  //   if (document.getElementById("file").files[0]) {
  //     const file_box = document.getElementById("file").files[0];
  //     if (file_box.size < 4000000) {
  //       let formData = new FormData();
  //       formData.append("file", document.getElementById("file").files[0]);
  //       const response = await fetch(
  //         `https://www.lecturecommune.fr/api/user/addcover/${isbnOK}`,
  //         {
  //           method: "POST",
  //           headers: {
  //             Authorization: "Bearer " + token,
  //           },
  //           body: formData,
  //         }
  //       );
  //       if (response.status === 200) {
  //         alert("Ajouté !");
  //       } else {
  //         alert("Erreur");
  //       }
  //     } else {
  //       alert("La taille de votre fichier doit être inférieure à 4Mo");
  //     }
  //   } else {
  //     alert("nooooon");
  //   }
  // };

  const handleSubmitFileJpeg = async (e) => {
    e.preventDefault();
    let token = localStorage.lecturecommuneToken;
    if (document.getElementById("file").files[0]) {
      const file_box = document.getElementById("file").files[0];
      if (file_box.size < 4000000) {
        let formData = new FormData();
        formData.append("file", document.getElementById("file").files[0]);
        const response = await fetch(
          `https://www.lecturecommune.fr/api/user/addcoverjpeg/${isbnOK}`,
          {
            method: "POST",
            headers: {
              Authorization: "Bearer " + token,
            },
            body: formData,
          }
        );
        if (response.status === 200) {
          alert("Ajouté !");
        } else {
          alert("Erreur");
        }
      } else {
        alert("La taille de votre fichier doit être inférieure à 4Mo");
      }
    } else {
      alert("nooooon");
    }
  };

  useEffect(() => {
    first();
  }, []);
  return (
    <>
      <Header />
      <main>
        {!isLoading ? (
          <>
            <h2 className="bookcreated__title">Ajout au catalogue</h2>
            <div className="adminhome">
              <div className="adminLine">
                <label htmlFor="isbnToSave">ISBN</label>
                <input
                  type="text"
                  id="isbnToSave"
                  name="isbnToSave"
                  value={isbnOK}
                  onChange={isbnChange}
                  onBlur={isbnBlur}
                />
                {isbnPresent && <p>Déjà présent</p>}
              </div>
              <form>
                <input id="file" type="file" name="file"></input>
                <button onClick={handleSubmitFileJpeg}>Ajout Cover</button>
              </form>
            </div>
          </>
        ) : (
          ""
        )}
      </main>
      <TopFooter />
      <Footer />
    </>
  );
}

export default AdminAddCover;
