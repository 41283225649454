import Connexion from '../../components/connexion/connexion';
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import TopFooter from '../../components/topFooter/topFooter';

function Connect() {
  return (
    <>
      <Header />
      <Connexion />
      <TopFooter />
      <Footer />
    </>
  );
}

export default Connect;