import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./scheduler.css";

const Scheduler = (readings) => {
  const [viewScheduler, setViewScheduler] = useState("all");
  const [week, setWeek] = useState(0);
  const [fullWeek, setFullWeek] = useState();
  const [weekDetail, setWeekDetail] = useState([]);
  const [readingsFetched, setReadingsFetched] = useState(readings.readings);
  const [finalData, setFinalData] = useState();
  const navigate = useNavigate();
  const daysLetter = [
    { id: "L" },
    { id: "M" },
    { id: "M" },
    { id: "J" },
    { id: "V" },
    { id: "S" },
    { id: "D" },
  ];
  const getAll = (weekReference) => {
    let dataToSendAll = [];
    readingsFetched.map((oneReading) => {
      if (
        (new Date(oneReading.session_begin_date) <
          new Date(weekReference[0].date) &&
          new Date(oneReading.session_end_inscription_date) >
            new Date(weekReference[0].date) &&
          new Date(oneReading.session_end_inscription_date) <
            new Date(weekReference[6].date)) ||
        (new Date(oneReading.session_begin_date) <
          new Date(weekReference[0].date) &&
          new Date(oneReading.session_end_inscription_date) >
            new Date(weekReference[6].date)) ||
        (new Date(oneReading.session_begin_date) <
          new Date(weekReference[6].date) &&
          new Date(oneReading.session_begin_date) >
            new Date(weekReference[0].date) &&
          new Date(oneReading.session_end_inscription_date) <
            new Date(weekReference[6].date) &&
          new Date(oneReading.session_end_inscription_date) >
            new Date(weekReference[0].date)) ||
        (new Date(oneReading.session_begin_date) <
          new Date(weekReference[6].date) &&
          new Date(oneReading.session_begin_date) >
            new Date(weekReference[0].date) &&
          new Date(oneReading.session_end_inscription_date) >
            new Date(weekReference[6].date))
      ) {
        let follow = false;
        if (oneReading.session_followers !== []) {
          oneReading.session_followers.map((testFollow) => {
            if (
              localStorage.lecturecommuneUserId &&
              Number(testFollow.id) ===
                Number(localStorage.lecturecommuneUserId)
            ) {
              follow = true;
            }
          });
        }
        dataToSendAll.push({
          id: oneReading.id,
          begin: oneReading.session_begin_date,
          end: oneReading.session_end_inscription_date,
          cover: oneReading.book_cover,
          follower: follow,
        });
      }
    });
    setFinalData(dataToSendAll);
  };
  const getAllMy = async (weekReference) => {
    let dataToSendMy = [];
    readingsFetched.map((oneReading) => {
      if (
        (new Date(oneReading.session_begin_date) <
          new Date(weekReference[0].date) &&
          new Date(oneReading.session_end_inscription_date) >
            new Date(weekReference[0].date) &&
          new Date(oneReading.session_end_inscription_date) <
            new Date(weekReference[6].date)) ||
        (new Date(oneReading.session_begin_date) <
          new Date(weekReference[0].date) &&
          new Date(oneReading.session_end_inscription_date) >
            new Date(weekReference[6].date)) ||
        (new Date(oneReading.session_begin_date) <
          new Date(weekReference[6].date) &&
          new Date(oneReading.session_begin_date) >
            new Date(weekReference[0].date) &&
          new Date(oneReading.session_end_inscription_date) <
            new Date(weekReference[6].date) &&
          new Date(oneReading.session_end_inscription_date) >
            new Date(weekReference[0].date)) ||
        (new Date(oneReading.session_begin_date) <
          new Date(weekReference[6].date) &&
          new Date(oneReading.session_begin_date) >
            new Date(weekReference[0].date) &&
          new Date(oneReading.session_end_inscription_date) >
            new Date(weekReference[6].date))
      ) {
        let follow = false;
        if (oneReading.session_followers !== []) {
          oneReading.session_followers.map((testFollow) => {
            if (
              localStorage.lecturecommuneUserId &&
              Number(testFollow.id) ===
                Number(localStorage.lecturecommuneUserId)
            ) {
              follow = true;
            }
          });
        }
        dataToSendMy.push({
          id: oneReading.id,
          begin: oneReading.session_begin_date,
          end: oneReading.session_end_inscription_date,
          cover: oneReading.book_cover,
          follower: follow,
        });
      }
    });
    let dataMyWithoutFalse = [];
    for (let index = 0; index < dataToSendMy.length; index++) {
      if (dataToSendMy[index].follower === true) {
        dataMyWithoutFalse.push(dataToSendMy[index]);
      }
    }
    await setFinalData(dataMyWithoutFalse);
  };
  const goAll = () => {
    viewScheduler !== "all" && setViewScheduler("all");
    makeWeek(week, "all");
  };
  const goMy = () => {
    viewScheduler !== "my" && setViewScheduler("my");
    makeWeek(week, "my");
  };
  const nextWeek = () => {
    makeWeek(week + 1, viewScheduler);
    setWeek(week + 1, viewScheduler);
  };
  const previousWeek = () => {
    makeWeek(week - 1, viewScheduler);
    setWeek(week - 1, viewScheduler);
  };
  const makeWeek = (weekDisplayed, filter) => {
    const nowDate = new Date(Date.now());
    let firstMondayOperation = "";
    let firstMonday = "";
    let firstMondayHours = "";
    let firstMondayMinutes = "";
    if (nowDate.getDay() !== 1) {
      firstMondayOperation =
        nowDate.getDate() +
        (nowDate.getDay() === 0 ? nowDate.getDay() - 6 : 1 - nowDate.getDay()) +
        (weekDisplayed * 7);
      firstMonday = nowDate.setDate(firstMondayOperation);
    } else {
      firstMondayOperation =
        nowDate.getDate() +
        (weekDisplayed * 7);
        firstMonday = nowDate.setDate(firstMondayOperation);
    }
    firstMondayHours = new Date(firstMonday).setHours(1);
    firstMondayMinutes = new Date(firstMondayHours).setMinutes(0);
    let weekToSave = [];
    weekToSave.push({ id: 1, date: new Date(firstMondayMinutes) });
    for (let index = 0; index < 6; index++) {
      let DateDayMonday = new Date(firstMonday).getDate();
      let newDay = new Date(firstMonday).setDate(DateDayMonday + (index + 1));
      let newDateHours = new Date(newDay).setHours(index < 5 ? 12 : 23);
      let newDateMinutes = new Date(newDateHours).setMinutes(0);
      weekToSave.push({ id: index + 2, date: new Date(newDateMinutes) });
    }
    setWeekDetail(weekToSave);
    makeFullWeek(weekDisplayed);
    filter === "all" ? getAll(weekToSave) : getAllMy(weekToSave);
  };
  const makeFullWeek = (weekDisplayed) => {
    const nowDate = new Date(Date.now());
    let firstMondayOperation = "";
    let firstMonday = "";
    let firstMondayHours = "";
    let firstMondayMinutes = "";
    if (nowDate.getDay() !== 1) {
      firstMondayOperation =
        nowDate.getDate() +
        (nowDate.getDay() === 0 ? nowDate.getDay() - 6 : 1 - nowDate.getDay()) +
        (weekDisplayed ? weekDisplayed * 7 : 0);
      firstMonday = nowDate.setDate(firstMondayOperation);
    } else {
      firstMondayOperation =
        nowDate.getDate() +
        (weekDisplayed * 7);
        firstMonday = nowDate.setDate(firstMondayOperation);
    }
    firstMondayHours = new Date(firstMonday).setHours(1);
    let firstMondayHours2 = new Date(firstMonday).setHours(23);
    firstMondayMinutes = new Date(firstMondayHours).setMinutes(0);
    let firstMondayMinutes2 = new Date(firstMondayHours2).setMinutes(0);
    let weekToSave = [];
    weekToSave.push({ id: 1, date: new Date(firstMondayMinutes) });
    weekToSave.push({ id: 2, date: new Date(firstMondayMinutes2) });
    for (let index = 0; index < 12; index++) {
      let DateDayMonday = new Date(firstMonday).getDate();
      let newDay = new Date(firstMonday).setDate(
        DateDayMonday + (Math.floor(index / 2) + 1)
      );
      let newDateHours = new Date(newDay).setHours(
        Math.floor(index % 2) === 0 ? 1 : 23
      );
      let newDateMinutes = new Date(newDateHours).setMinutes(0);
      weekToSave.push({ id: index + 3, date: new Date(newDateMinutes) });
    }
    setFullWeek(weekToSave);
  };
  const getReadingsFetched = async () => {
    const testConnect = await fetch(
      "https://www.lecturecommune.fr/api/reading/all",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (testConnect.status === 200) {
      const result = await testConnect.json();
      await setReadingsFetched(result);
    }
    goAll();
  };
  useEffect(() => {
    getReadingsFetched();
  }, []);
  return (
    // <p>SCHEDULER</p>
    <>
      {localStorage.lecturecommuneUserId ? (
        <div className="readinginprocess__line--inside">
          <div
            className={`scheduler__link readinginprocess__line--element readinginprocess__line--left ${
              viewScheduler === "all" ? "readinginprocess__line--selected" : ""
            }`}
            onClick={() => {
              viewScheduler !== "all" && goAll();
            }}
          >
            Toutes les lectures
          </div>
          <div
            className={`scheduler__link readinginprocess__line--element readinginprocess__line--right ${
              viewScheduler === "my" ? "readinginprocess__line--selected" : ""
            }`}
            onClick={() => {
              viewScheduler !== "my" && goMy();
            }}
          >
            Mes lectures
          </div>
        </div>
      ) : (
        ""
      )}
      <table className="scheduler" cellSpacing={"0px"}>
        <thead>
          <tr>
            {/* <th className="scheduler__th" colSpan="8">
              <div className="scheduler__cell" onClick={previousWeek}>{"<"}</div><div className="scheduler__cell">Semaine</div><div className="scheduler__cell" onClick={nextWeek}>{">"}</div>
            </th> */}
            <th className="scheduler__cell scheduler__th" onClick={previousWeek}>
              <p className="scheduler__link scheduler__th--left">{"<"}</p>
            </th>
            <th className="scheduler__cell scheduler__th" colSpan="6">
              
            </th>
            <th className="scheduler__cell scheduler__th" onClick={nextWeek}>
              <p className="scheduler__link scheduler__th--right">{">"}</p>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="scheduler__firstcell scheduler__th">LC</td>
            {weekDetail !== []
              ? weekDetail.map((oneElement) => (
                  <td key={`${oneElement.date}date${oneElement.id}`} className="scheduler__cell">
                    <div>
                      <p>{daysLetter[oneElement.id - 1].id}</p>
                      <p>
                        {(new Date(oneElement.date).getDate() < 10
                          ? `0${new Date(oneElement.date).getDate()}`
                          : new Date(oneElement.date).getDate()) +
                          (new Date(oneElement.date).getMonth() + 1 < 10
                            ? `/0${new Date(oneElement.date).getMonth() + 1}`
                            : `/${new Date(oneElement.date).getMonth() + 1}`)}
                      </p>
                    </div>
                  </td>
                ))
              : ""}
          </tr>
          <tr className="scheduler__separator">
            <td colSpan="8"></td>
          </tr>
          {finalData && viewScheduler && fullWeek ? (
            finalData.map((displayReading) => (
              <tr key={`${displayReading.begin}${displayReading.end}${Math.random()}`}>
                <td className="scheduler__cellscontainer" onClick={() => {navigate(`/reading/view/${displayReading.id}`)}}>
                  <img
                    className="scheduler__cell--img"
                    src={displayReading.cover}
                    alt="Couverture du livre"
                  />
                </td>
                <td>
                  {fullWeek &&
                  (new Date(displayReading.end) < new Date(fullWeek[0].date) ||
                    new Date(displayReading.begin) >
                      new Date(fullWeek[1].date)) ? (
                    <div className="scheduler__cellcontainer">
                      <div className="scheduler__cellinside scheduler__cellinside--false"></div>
                    </div>
                  ) : (
                    <div className="scheduler__cellcontainer">
                      <div className="scheduler__cellinside scheduler__cellinside--true">
                        <p className="scheduler__cellinside--txt">
                          {(new Date(fullWeek[0].date).getDate() < 10
                            ? `0${new Date(fullWeek[0].date).getDate()}`
                            : new Date(fullWeek[0].date).getDate()) +
                            (new Date(fullWeek[0].date).getMonth() + 1 < 10
                              ? `/0${new Date(fullWeek[0].date).getMonth() + 1}`
                              : `/${
                                  new Date(fullWeek[0].date).getMonth() + 1
                                }`)}
                        </p>
                      </div>
                    </div>
                  )}
                </td>
                <td>
                  {fullWeek &&
                  (new Date(displayReading.end) < new Date(fullWeek[2].date) ||
                    new Date(displayReading.begin) >
                      new Date(fullWeek[3].date)) ? (
                    <div className="scheduler__cellcontainer">
                      <div className="scheduler__cellinside scheduler__cellinside--false"></div>
                    </div>
                  ) : (
                    <div className="scheduler__cellcontainer">
                      <div className="scheduler__cellinside scheduler__cellinside--true">
                        <p className="scheduler__cellinside--txt">
                          {(new Date(fullWeek[2].date).getDate() < 10
                            ? `0${new Date(fullWeek[2].date).getDate()}`
                            : new Date(fullWeek[2].date).getDate()) +
                            (new Date(fullWeek[2].date).getMonth() + 1 < 10
                              ? `/0${new Date(fullWeek[2].date).getMonth() + 1}`
                              : `/${
                                  new Date(fullWeek[2].date).getMonth() + 1
                                }`)}
                        </p>
                      </div>
                    </div>
                  )}
                </td>
                <td>
                  {fullWeek &&
                  (new Date(displayReading.end) < new Date(fullWeek[4].date) ||
                    new Date(displayReading.begin) >
                      new Date(fullWeek[5].date)) ? (
                    <div className="scheduler__cellcontainer">
                      <div className="scheduler__cellinside scheduler__cellinside--false"></div>
                    </div>
                  ) : (
                    <div className="scheduler__cellcontainer">
                      <div className="scheduler__cellinside scheduler__cellinside--true">
                        <p className="scheduler__cellinside--txt">
                          {(new Date(fullWeek[4].date).getDate() < 10
                            ? `0${new Date(fullWeek[4].date).getDate()}`
                            : new Date(fullWeek[4].date).getDate()) +
                            (new Date(fullWeek[4].date).getMonth() + 1 < 10
                              ? `/0${new Date(fullWeek[4].date).getMonth() + 1}`
                              : `/${
                                  new Date(fullWeek[4].date).getMonth() + 1
                                }`)}
                        </p>
                      </div>
                    </div>
                  )}
                </td>
                <td>
                  {fullWeek &&
                  (new Date(displayReading.end) < new Date(fullWeek[6].date) ||
                    new Date(displayReading.begin) >
                      new Date(fullWeek[7].date)) ? (
                    <div className="scheduler__cellcontainer">
                      <div className="scheduler__cellinside scheduler__cellinside--false"></div>
                    </div>
                  ) : (
                    <div className="scheduler__cellcontainer">
                      <div className="scheduler__cellinside scheduler__cellinside--true">
                        <p className="scheduler__cellinside--txt">
                          {(new Date(fullWeek[6].date).getDate() < 10
                            ? `0${new Date(fullWeek[6].date).getDate()}`
                            : new Date(fullWeek[6].date).getDate()) +
                            (new Date(fullWeek[6].date).getMonth() + 1 < 10
                              ? `/0${new Date(fullWeek[6].date).getMonth() + 1}`
                              : `/${
                                  new Date(fullWeek[6].date).getMonth() + 1
                                }`)}
                        </p>
                      </div>
                    </div>
                  )}
                </td>
                <td>
                  {fullWeek &&
                  (new Date(displayReading.end) < new Date(fullWeek[8].date) ||
                    new Date(displayReading.begin) >
                      new Date(fullWeek[9].date)) ? (
                    <div className="scheduler__cellcontainer">
                      <div className="scheduler__cellinside scheduler__cellinside--false"></div>
                    </div>
                  ) : (
                    <div className="scheduler__cellcontainer">
                      <div className="scheduler__cellinside scheduler__cellinside--true">
                        <p className="scheduler__cellinside--txt">
                          {(new Date(fullWeek[8].date).getDate() < 10
                            ? `0${new Date(fullWeek[8].date).getDate()}`
                            : new Date(fullWeek[8].date).getDate()) +
                            (new Date(fullWeek[8].date).getMonth() + 1 < 10
                              ? `/0${new Date(fullWeek[8].date).getMonth() + 1}`
                              : `/${
                                  new Date(fullWeek[8].date).getMonth() + 1
                                }`)}
                        </p>
                      </div>
                    </div>
                  )}
                </td>
                <td>
                  {fullWeek &&
                  (new Date(displayReading.end) < new Date(fullWeek[10].date) ||
                    new Date(displayReading.begin) >
                      new Date(fullWeek[11].date)) ? (
                    <div className="scheduler__cellcontainer">
                      <div className="scheduler__cellinside scheduler__cellinside--false"></div>
                    </div>
                  ) : (
                    <div className="scheduler__cellcontainer">
                      <div className="scheduler__cellinside scheduler__cellinside--true">
                        <p className="scheduler__cellinside--txt">
                          {(new Date(fullWeek[10].date).getDate() < 10
                            ? `0${new Date(fullWeek[10].date).getDate()}`
                            : new Date(fullWeek[10].date).getDate()) +
                            (new Date(fullWeek[10].date).getMonth() + 1 < 10
                              ? `/0${
                                  new Date(fullWeek[10].date).getMonth() + 1
                                }`
                              : `/${
                                  new Date(fullWeek[10].date).getMonth() + 1
                                }`)}
                        </p>
                      </div>
                    </div>
                  )}
                </td>
                <td>
                  {fullWeek &&
                  (new Date(displayReading.end) < new Date(fullWeek[12].date) ||
                    new Date(displayReading.begin) >
                      new Date(fullWeek[13].date)) ? (
                    <div className="scheduler__cellcontainer">
                      <div className="scheduler__cellinside scheduler__cellinside--false"></div>
                    </div>
                  ) : (
                    <div className="scheduler__cellcontainer">
                      <div className="scheduler__cellinside scheduler__cellinside--true">
                        <p className="scheduler__cellinside--txt">
                          {(new Date(fullWeek[12].date).getDate() < 10
                            ? `0${new Date(fullWeek[12].date).getDate()}`
                            : new Date(fullWeek[12].date).getDate()) +
                            (new Date(fullWeek[12].date).getMonth() + 1 < 10
                              ? `/0${
                                  new Date(fullWeek[12].date).getMonth() + 1
                                }`
                              : `/${
                                  new Date(fullWeek[12].date).getMonth() + 1
                                }`)}
                        </p>
                      </div>
                    </div>
                  )}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="8">Chargement...</td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
};

export default Scheduler;
