import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { BiArrowBack } from "react-icons/bi";
import "./adminbiblio.css";
import Header from "../header/header";
import Footer from "../footer/footer";

function AdminBiblio() {
  const [dataUsers, setDataUsers] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const first = async () => {
    setIsLoading(true);
    if (!localStorage.lecturecommuneCheckAdmin) {
      navigate("/");
    }
    const token = localStorage.lecturecommuneToken;
    const testConnect = await fetch(
      `https://www.lecturecommune.fr/api/user/checkadmin`,
      {
        method: "post",
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    if (testConnect.status === 200) {
      const fetchUsers = await fetch(
        `https://www.lecturecommune.fr/api/mybooks/getalladmin`,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      if (fetchUsers.status === 200) {
        let result2 = await fetchUsers.json();
        setDataUsers(result2);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } else {
      navigate("/error");
    }
  };
  useEffect(() => {
    first();
  }, []);
  return (
    <>
      <Header />
      <main>
        {!isLoading && (
          <>
            <div className="readingdetails__firstline">
              <h2 className="bookcreated__title">Bibliothèques</h2>
              <div
                className="readingdetails__firstline--back"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <BiArrowBack className="cursorlink" />
              </div>
            </div>
            <h3 className="bookcreated__title adminusers__title">
              {dataUsers && `${dataUsers.length} livres`}
            </h3>
            <div className="adminusers">
              {dataUsers && (
                <div className="adminusers__line">
                  <p className="adminusers__line--1">
                    <strong>Id</strong>
                  </p>
                  <p className="adminusers__line--2">
                    <strong>Titre</strong>
                  </p>
                  <p className="adminusers__line--5">
                    <strong>PAL</strong>
                  </p>
                  <p className="adminusers__line--5">
                    <strong>Wish</strong>
                  </p>
                  <p className="adminusers__line--5">
                    <strong>Lu</strong>
                  </p>
                  <p className="adminusers__line--5">
                    <strong>Lu</strong>
                  </p>
                </div>
              )}
              {dataUsers &&
                dataUsers.map((user) => (
                  <div className="adminusers__line" key={user.id}>
                    <p className="adminusers__line--1">{`${user.user}`}</p>
                    <p className="adminusers__line--2">{`${user.title}`}</p>
                    <p className="adminusers__line--5">
                      {user.pal === 1 ? "Oui" : "Non"}
                    </p>
                    <p className="adminusers__line--5">
                      {user.wish === 1 ? "Oui" : "Non"}
                    </p>
                    <p className="adminusers__line--5">
                      {user.readed === 1 ? "Oui" : "Non"}
                    </p>
                    <p className="adminusers__line--5">
                      {user.readed === 1 ? "Oui" : "Non"}
                    </p>
                  </div>
                ))}
            </div>
          </>
        )}
      </main>
      <Footer />
    </>
  );
}

export default AdminBiblio;
