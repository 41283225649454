import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { BiArrowBack } from 'react-icons/bi';
import ReadingShow from "../readingShow/readingShow";
import "./adminreadings.css";

function AdminReadings() {
    const [readings, setReadings] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const first = async () => {
    setIsLoading(true);
    if (!localStorage.lecturecommuneCheckAdmin) {
      navigate("/");
    }
    const token = localStorage.lecturecommuneToken;
    const testConnect = await fetch(
      `https://www.lecturecommune.fr/api/user/checkadmin`,
      {
        method: "post",
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    if (testConnect.status === 200) {
      const fetchUsers = await fetch(
        `https://www.lecturecommune.fr/api/reading/admin/all`,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      if (fetchUsers.status === 200) {
        let result2 = await fetchUsers.json();
        await result2.sort(function(a, b) {
          return (new Date(b.updatedAt) - new Date(a.updatedAt))
        });
        setReadings(result2);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } else {
      navigate("/error");
    }
  };
  useEffect(() => {
    first();
  }, []);
  return (
    <>
      <main>
        {!isLoading && (
          <>
            <div className="readingdetails__firstline"><h2 className="bookcreated__title">Inscriptions</h2><div className="readingdetails__firstline--back" onClick={() => {navigate(-1)}}><BiArrowBack className="cursorlink" /></div></div>
            <div className="readinginprocess__list adminreadings">
            {
              (readings) ? (
            readings.map((data2) => (
              <ReadingShow key={data2.id} reading={data2} limitDate={true} link={`/reading/open/${data2.id}`} />
            ))) : ""
            }
            </div>
          </>
        )}
      </main>
    </>
  );
}

export default AdminReadings;
