import { useEffect } from "react";
import "./adsenseNav.css";

const AdsenseNav = ({ slotNbr }) => {
  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {}
  }, []);
  return (
    <div className="footeradsense2">
      <div className="footeradsense2__container">
        <div className="footeradsense2__ad">
          <ins
            className="adsbygoogle footeradsense2__responsive"
            style={{ display: "inline-block", height: "90px !important", alignSelf: "center", textAlign: "center" }}
            data-ad-client="ca-pub-6076266123381071"
            data-ad-slot={slotNbr && slotNbr !== "" ? slotNbr : "2797060551"}
            // data-ad-format="horizontal"
            data-full-width-responsive="true"
          ></ins>
        </div>
      </div>
    </div>
  );
};

export default AdsenseNav;
