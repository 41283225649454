import { useEffect, useState } from "react";
import "./readingsInProcess.css";
import Loader from "../loader/loader";
import ReadingShow from "../readingShow/readingShow";

function ReadingInProcess() {
  const [data, setData] = useState();
  const token = localStorage.lecturecommuneToken;
  const dataLast = async () => {
    const testConnect = await fetch(
      "https://www.lecturecommune.fr/api/reading/inprocess",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    );
    if (testConnect.status === 200) {
      const result = await testConnect.json();
      setData(result);
    }
  };
  useEffect( () => { dataLast() }, []);
  return data ? (
    <main>
      <div className="readinginprocess"><h2 className="readinginprocess__title">Lectures en cours</h2>
        <div className="readinginprocess__list">
          {data.map((data2) => (
            <ReadingShow key={data2.id} reading={data2} link={`/reading/open/${data2.id}`} unread={'true'} />
          ))}
        </div>
      </div>
    </main>
  ) : (
    <Loader />
  );
}

export default ReadingInProcess;
