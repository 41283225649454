import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { BiArrowBack } from 'react-icons/bi';
import "./adminusers.css";

function AdminUsers() {
  const [notActivated, setNotActivated] = useState();
  const [dataUsers, setDataUsers] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const first = async () => {
    setIsLoading(true);
    if (!localStorage.lecturecommuneCheckAdmin) {
      navigate("/");
    }
    const token = localStorage.lecturecommuneToken;
    const testConnect = await fetch(
      `https://www.lecturecommune.fr/api/user/checkadmin`,
      {
        method: "post",
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    if (testConnect.status === 200) {
      const fetchUsers = await fetch(
        `https://www.lecturecommune.fr/api/user/admin/users`,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      if (fetchUsers.status === 200) {
        let result2 = await fetchUsers.json();
        setDataUsers(result2);
        let notActive = 0;
        result2.map((user) => {
          if (Number(user.active) === 0) {
            notActive += 1;
          }
        });
        setNotActivated(notActive);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } else {
      navigate("/error");
    }
  };
  useEffect(() => {
    first();
  }, []);
  return (
    <>
      <main>
        {!isLoading && (
          <>
            <div className="readingdetails__firstline"><h2 className="bookcreated__title">Inscriptions</h2><div className="readingdetails__firstline--back" onClick={() => {navigate(-1)}}><BiArrowBack className="cursorlink" /></div></div>
            <h3 className="bookcreated__title adminusers__title">
              {dataUsers && `${dataUsers.length} inscrits${notActivated ? ` dont ${notActivated} non validés` : ``}`}
            </h3>
            <div className="adminusers">
              {dataUsers && (
                <div className="adminusers__line">
                  <p className="adminusers__line--1">
                    <strong>Id</strong>
                  </p>
                  <p className="adminusers__line--2">
                    <strong>Insc.</strong>
                  </p>
                  <p className="adminusers__line--3">
                    <strong>Pseudo</strong>
                  </p>
                  <p className="adminusers__line--4">
                    <strong>Insta</strong>
                  </p>
                  <p className="adminusers__line--5">
                    <strong>Actif</strong>
                  </p>
                </div>
              )}
              {dataUsers &&
                dataUsers.map((user) => (
                  <div className="adminusers__line" key={user.id}>
                    <p className="adminusers__line--1">{user.id}</p>
                    <p className="adminusers__line--2">{`${
                      new Date(user.createdAt).getDate() < 10
                        ? `0${new Date(user.createdAt).getDate()}`
                        : new Date(user.createdAt).getDate()
                    }/${
                      new Date(user.createdAt).getMonth() < 10
                        ? `0${new Date(user.createdAt).getMonth() + 1}`
                        : new Date(user.createdAt).getMonth() + 1
                    }`}</p>
                    <p className="adminusers__line--3">
                      {user.username ? user.username : "-"}
                    </p>
                    <p className="adminusers__line--4">
                      {user.insta ? user.insta : "-"}
                    </p>
                    <p className="adminusers__line--5">
                      {Number(user.active) === 1 ? "Oui" : "Non"}
                    </p>
                  </div>
                ))}
            </div>
          </>
        )}
      </main>
    </>
  );
}

export default AdminUsers;
