import { useState } from "react";
import { Link } from "react-router-dom";
import configUrl from "../../assets/config";

const ReinitPassConfirm = ({ pass }) => {
  const [password, setPassword] = useState("");
  const [isSent, setIsSent] = useState(false);
  const [isSentOk, setIsSentOk] = useState(false);
  const handleClick = () => {
    setPassword(document.getElementById("email").value);
  };
  const sendData = async (e) => {
    e.preventDefault();
    if (password && password.length > 7) {
      let dataToSend = {
        password: password,
        passconfirm: pass.pass
      };
      let response = await fetch(`${configUrl.api}user/reinitpassconfirm`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataToSend),
      });
      if (response && response.status === 200) {
        setIsSent(true);
        setIsSentOk(true);
      } else if (response && response.status !== 200) {
        alert("Erreur : veuillez réessayer.")
        setIsSent(true);
        setIsSentOk(false);
      }
    } else {
      alert("Veuillez choisir votre nouveau mot de passe (minimum 8 caractères)");
    }
  };
  return (
    <main className="main__connect">
        <h2 className="connexion__title">Mot de pass oublié</h2><br />
      <p className="reinit__p">Veuillez choisir un nouveau mot de passe et valider.</p>
      <form onSubmit={sendData}>
        <div className="form__div">
          <label>Mot de passe :</label>
          <br />
          <input
            id="email"
            name="email"
            className="form__input form__input--mail"
            type="password"
            onChange={handleClick}
          />
          <div className="form__input--email form__input--email--true">
            {/* Adresse email incorrecte */}
          </div>
        </div>
        
        <input
          className="form__submit nodecoration"
          type="submit"
          value="Réinitialiser"
          onClick={sendData}
        />
      </form><br />
      {(isSent && isSentOk) && <p className="reinit__p">Votre mot de passe a bien été modifié. <Link to="/connect">Vous pouvez vous connecter</Link></p>}
      
      
    </main>
  );
};

export default ReinitPassConfirm;
