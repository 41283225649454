import { useNavigate } from "react-router-dom";
import { BiZoomIn } from "react-icons/bi";
import ProgressBar from "../progressbar/progressbar";
import "./readingFollowers.css";

const ReadingFollowers = ({ followers, cible }) => {
  const navigate = useNavigate();
  const pagesReaded = followers.stage;
  const nbrPagesBook = cible;
  const progressPercent =
    (((pagesReaded / nbrPagesBook) * 100) / 5).toFixed(0) * 5;
  return (
    <div className="rdetails__followers">
      <div className="rdetails__followers--line">
        <div className="rdetails__followers--part1" onClick={() => {navigate(`/user/${followers.id}`)}}><BiZoomIn className="rdetails__followers--part1-2"/><p className="rdetails__followers--part1-1">{followers.name}</p></div>
      <ProgressBar stage={progressPercent} txt='' className="rdetails__followers--part2" />
      </div>
    </div>
  );
};

export default ReadingFollowers;
