import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { TbAlertTriangle } from 'react-icons/tb';
import { MdOutlineMarkEmailUnread } from 'react-icons/md';
import { BsChevronRight } from 'react-icons/bs';
import "./readingShow.css";
const jsonata = require("jsonata");

function ReadingShow({ reading, link, limitDate, unread }) {
  const [delay, setDelay] = useState();
  const [unreadMsg, setUnreadMsg] = useState();
  const userId = localStorage.lecturecommuneUserId;
  const checkDelay = async () => {
    if (limitDate && reading.session_begin_date) {
      let beginDateMs = await jsonata(`$toMillis("${reading.session_begin_date}")`);
      let beginDateS = await beginDateMs.evaluate();
      beginDateS = beginDateS / 1000;
      let endDateMs = await jsonata(`$toMillis("${reading.session_end_inscription_date}")`);
      let endDateS = await endDateMs.evaluate();
      endDateS = endDateS / 1000;
      let now = await jsonata("$toMillis($now())");
      now = await now.evaluate();
      now = now / 1000;
      let delayMin = Math.floor((beginDateS - now) / 60);
      let delay2Min = Math.floor((endDateS - now) / 60);
      if(delayMin > 0) {
      delayMin < 60 && setDelay(`Début de la lecture dans ${delayMin} minute${(Math.floor(delayMin > 1)) ? 's' : ''}`);
      (delayMin >= 60 && delayMin < 1440) && setDelay(`Début de la lecture dans ${Math.floor(delayMin / 60)} heure${(Math.floor(delayMin / 60)) > 1 ? 's' : ''}`);
      (delayMin >= 1440 && delayMin < 4320) && setDelay(`Début de la lecture dans ${Math.floor(delayMin / 1440)} jour${(Math.floor(delayMin / 1440)) > 1 ? 's' : ''}`);
    } else {
      delay2Min < 60 && setDelay(`Fin de la lecture dans ${delay2Min} minute${(Math.floor(delay2Min > 1)) ? 's' : ''}`);
      (delay2Min >= 60 && delay2Min < 1440) && setDelay(`Fin de la lecture dans ${Math.floor(delay2Min / 60)} heure${(Math.floor(delay2Min / 60)) > 1 ? 's' : ''}`);
      (delay2Min >= 1440) && setDelay(`Fin de la lecture dans ${Math.floor(delay2Min / 1440)} jour${(Math.floor(delay2Min / 1440)) > 1 ? 's' : ''}`);
    }}
    if (reading.session_followers) {reading.session_followers.map((follower) => {
      if(follower.id === Number(userId) && follower.unread !== 0) {
        setUnreadMsg(follower.unread);
      }
    })}
    };
    useEffect( () => { checkDelay() }, []);
  return (
    <div className="readingshow">
      <div className="readingshow__img">
        {reading.book_cover ? (
          <img
            className="readingshow__img--img"
            src={reading.book_cover}
            alt="Couverture du Livre"
          ></img>
        ) : (
          <div className="readingshow__img--noimg"></div>
        )}
      </div>
      <div className="readingshow__detail">
        <div className="readingshow__detail--essential">
        <p>
          <strong>{reading.book_name}</strong>
        </p>
        <p>{reading.book_author}</p>
        </div>
        <div className="readingshow__detail--begindate"><p>Début de la lecture :</p><p>{`${
                      new Date(
                        reading.session_begin_date
                      ).getDate() < 10
                        ? `0${new Date(
                          reading.session_begin_date
                          ).getDate()}`
                        : new Date(
                          reading.session_begin_date
                          ).getDate()
                    } / ${
                      new Date(
                        reading.session_begin_date
                      ).getMonth() +
                        1 <
                      10
                        ? `0${
                            new Date(
                              reading.session_begin_date
                            ).getMonth() + 1
                          }`
                        : new Date(
                          reading.session_begin_date
                          ).getMonth() + 1
                    } / ${new Date(
                      reading.session_begin_date
                    ).getFullYear()}`}</p></div>
      </div>
      <Link
        className="readingshow__link nodecoration"
        to={link ? link : `/reading/view/${reading.id}`}
      >
        <div className="readingshow__divlink">
          {/* <i className="fa-solid fa-chevron-right "></i> */}
          <BsChevronRight className="readingshow__divlink--arrow" />
        </div>
      </Link>
      {limitDate && delay ? <div className="content__card--msg"><span className="content__card--span"><TbAlertTriangle /><strong>{delay}</strong></span></div> : ''}
      {unreadMsg && unread === 'true' ? <Link to={link ? link : `/reading/view/${reading.id}`} className="readingshow__unread"><MdOutlineMarkEmailUnread /></Link> : ''}
    </div>
  );
}

export default ReadingShow;
