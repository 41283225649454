import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ReactGA from "react-ga4";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Home from "./pages/home/home";
import Connect from "./pages/connect/connect";
import Confirm from "./pages/confirm/confirm";
import Page404 from "./pages/page404/page404";
import SignUp from "./pages/signup/signup";
import User from "./pages/user/user";
import CreateReading from "./pages/reading-create/reading-create";
import ViewReading from "./pages/viewreading/viewreading";
import OpenReading from "./pages/openreading/openreading";
import ReadingInProcess from "./pages/readinginprocess/readinginprocess";
import ReadingCreated from "./pages/readingcreated/readingcreated";
import Account from "./pages/account/account";
import AllSessions from "./pages/allsessions/allsessions";
import CGU from "./pages/cgu/cgu";
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";
import Contact from "./pages/contact/contact";
import ReadingCompleted from "./pages/readingcompleted/readingcompleted";
import ReadingCreatedBy from "./pages/readingscreatedby/readingscreatedby";
import ReadingFollowedBy from "./pages/readingsfollowedby/readingsfollowedby";
import PersonnalSettings from "./pages/personnalsettings/personnalsettings";
import AdminView from "./pages/adminview/adminview";
import AdminViewUsers from "./pages/adminviewusers/adminviewusers";
import AdminViewReadings from "./pages/adminviewreadings/adminviewreadings";
import FAQ from "./pages/faq/faq";
import LastSessions from "./pages/lastsessions/lastsessions";
import ReinitAsk from "./pages/reinitask/reinitask";
import ReinitPassword from "./pages/reinitPaswword/reinitPassword";
import AdminAdd from "./components/adminAdd/adminAdd";
import AdminBiblio from "./components/adminBiblio/adminbiblio";
import GetApp from "./components/getApp/getApp";
import AdminBiblioUpdate from "./components/adminBiblioUpdate/adminbiblioupdate";
import AdminBiblioOneUpdate from "./components/admibbibliooneupdate/adminbibliooneupdate";
import AdminAddCover from "./components/adminAddCover/adminAddCover";
import AdminEditSession from "./components/adminEditSession/adminEditSession";
import AdminEditOneSession from "./components/adminEditOneSession/adminEditOneSession";
// import Testt from "./pages/test/test";

const root = ReactDOM.createRoot(document.getElementById("root"));
const setChoiceAcceptCookies = () => {
  acceptCookies();
};
const setChoiceDeclineCookies = () => {
  document.location.href = "/";
};
const acceptCookies = () => {
  ReactGA.initialize("G-XQ67S7YFLE");
  ReactGA.send({ hitType: "pageview", page: window.location.pathname });
};
if (getCookieConsentValue() == "true") {
  acceptCookies();
}
root.render(
  <>
    <CookieConsent
      enableDeclineButton
      acceptOnScroll={false}
      location="bottom"
      expires={7}
      overlay={true}
      buttonWrapperClasses="consentlineclass"
      overlayClasses="nopointer"
      style={{ fontFamily: "Jost", display: "block", background: "#2B373B" }}
      buttonStyle={{ fontFamily: "Jost", color: "#4e503b", fontSize: "18px" }}
      declineButtonStyle={{ fontFamily: "Jost", fontSize: "10px" }}
      buttonText="Accepter"
      declineButtonText="Refuser"
      onAccept={setChoiceAcceptCookies}
      onDecline={setChoiceDeclineCookies}
    >
      Ce Site stockent et/ou accède à des informations sur votre appareil pour
      traiter les données personnelles. Nous procédons ainsi pour fournir un
      contenu personnalisé. Vous pouvez consulter leurs finalités sur la page{" "}
      "Conditions Générales d'Utilisation". Vous pouvez les accepter ou les
      refuser.
      <br />
      <span style={{ fontFamily: "Jost", fontSize: "11px" }}>
        Nous utilisons des cookies et technologies similaires tiers ou non pour
        utiliser des données de géolocalisation, stocker et/ou accéder à des
        informations sur un terminal diffuser des publicités et contenu
        personnalisés, mesurer la performance des publicités et du contenu,
        données d’audience et développement de produit.
      </span>
    </CookieConsent>
    <React.StrictMode>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="/getapp" element={<GetApp />} /> */}
          <Route path="/connect" element={<Connect />} />
          <Route path="/confirm/:activationPass" element={<Confirm />} />
          <Route path="/signup" element={<SignUp />} />
          {/* <Route path="/user/:userid" element={<User />} /> */}
          <Route path="/reading/create" element={<CreateReading />} />
          <Route path="/reading/view/:readingid" element={<ViewReading />} />
          <Route path="/reading/open/:reading" element={<OpenReading />} />
          <Route path="/reading/inprocess" element={<ReadingInProcess />} />
          <Route path="/reading/created" element={<ReadingCreated />} />
          {/* <Route
            path="/reading/createdby/:creator"
            element={<ReadingCreatedBy />}
          />
          <Route
            path="/reading/followedby/:creator"
            element={<ReadingFollowedBy />}
          /> */}
          <Route path="/reading/completed" element={<ReadingCompleted />} />
          <Route path="/reading/all" element={<AllSessions />} />
          <Route path="/reading/lastcreated" element={<LastSessions />} />
          <Route path="/cgu" element={<CGU />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/user/profile" element={<Account />} />
          <Route path="/user/settings" element={<PersonnalSettings />} />
          <Route path="/adminview" element={<AdminView />} />
          <Route path="/adminview/users" element={<AdminViewUsers />} />
          <Route path="/adminview/readings" element={<AdminViewReadings />} />
          <Route path="/adminview/add" element={<AdminAdd />} />
          <Route path="/adminview/addcover" element={<AdminAddCover />} />
          <Route path="/adminview/editsession" element={<AdminEditSession />} />
          <Route
            path="/adminview/edtionesession/:id"
            element={<AdminEditOneSession />}
          />
          <Route path="/adminview/biblio" element={<AdminBiblio />} />
          <Route
            path="/adminview/biblioupdate"
            element={<AdminBiblioUpdate />}
          />
          <Route
            path="/adminview/biblioupdate/:isbn"
            element={<AdminBiblioOneUpdate />}
          />
          <Route path="/reinitpass" element={<ReinitAsk />} />
          <Route path="/reinitpassword/:pass" element={<ReinitPassword />} />
          <Route path="/getapp" element={<GetApp />} />
          {/* <Route path="/test" element={<Testt />} /> */}
          <Route path="*" element={<Page404 />} />
        </Routes>
      </Router>
    </React.StrictMode>
  </>
);

reportWebVitals();
