import "./findbook.css";
import { useState } from "react";
import BookOK from "../bookOK/bookOK";
import CreateDetailSession from "../createDetailSession/createDetailSession";
import ProgressBar from "../progressbar/progressbar";
import LoaderOnly from "../loaderonly/loaderonly";
// const { XMLParser } = require("fast-xml-parser");

function FindBook() {
  const [backSearch, setBackSearch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOk, setIsOk] = useState();
  const [data, setData] = useState();
  const [authorName, setAuthorName] = useState();
  const [isValidatedBook, setIsValidatedBook] = useState(false);
  const [checkedImg, setCheckedImg] = useState();
  const [stage, setStage] = useState(10);
  const [state, setState] = useState("Recherche du livre");
  const [isbnForm, setIsbnForm] = useState();
  if (!localStorage.lecturecommuneToken) {
    document.location.href = "/";
  }
  const token = localStorage.lecturecommuneToken;
  const goBack = () => {
    setBackSearch(true);
    setStage(10);
    setState("Recherche du livre");
  };
  const validateBook = () => {
    setStage(70);
    setState("Détail de la lecture");
    setIsValidatedBook(true);
  };
  const isbnChange = (event) => {
    setCheckedImg(event.target.value);
    console.log(event.target.value);
  }
  const checkBook = async () => {
    if (!checkedImg) {
      alert("ISBN manquant");
    } else {
      setIsLoading(true);
      let searchBook = { isbn: checkedImg };
      let testBook = await fetch(
        "https://www.lecturecommune.fr/api/reading/getbook",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify(searchBook),
        }
      );
      if (testBook.status === 200) {
        let resultBook = await testBook.json();
        setAuthorName(resultBook.book_author);
        setCheckedImg(resultBook.cover);
        const dataOkOur = {
          title: resultBook.name,
          book_pagesnbr: resultBook.nbrpages,
          book_fourth: resultBook.fourth,
        };
        setData(dataOkOur);
        setStage(40);
        setIsOk(true);
        setBackSearch(false);
        setIsbnForm(checkedImg);
        setIsLoading(false);
      } else {
        let bnfFourth = "";
        const isbnImgSearch = checkedImg;
        setIsbnForm(isbnImgSearch);
        const searchBnfData = { isbn: isbnImgSearch };
        setIsLoading(true);
        let testBnf = await fetch(
          "https://www.lecturecommune.fr/api/reading/findBnf",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
            body: JSON.stringify(searchBnfData),
          }
        );
        let testBnfTxt = await testBnf.text();
        const parser = new DOMParser();
        let jObj = await parser.parseFromString(testBnfTxt, "text/xml");
        if (jObj.getElementsByTagName("srw:records")[0].textContent !== "") {
          const authornom = jObj
            .querySelectorAll('[tag="700"]')[0]
            .querySelector('[code="a"]').textContent;
          const authorprenom = jObj
            .querySelectorAll('[tag="700"]')[0]
            .querySelector('[code="b"]')
            ? jObj
                .querySelectorAll('[tag="700"]')[0]
                .querySelector('[code="b"]').textContent
            : "";
          const auth = `${authorprenom} ${authornom}`;
          setAuthorName(auth);
          const arkId = `https://catalogue.bnf.fr/couverture?&appName=NE&idArk=${
            jObj.getElementsByTagName("srw:recordIdentifier")[0].textContent
          }&couverture=1`;
          setCheckedImg(arkId);
          const bnfTitle = jObj
            .querySelectorAll('[tag="200"]')[0]
            .querySelector('[code="a"]').textContent;
          const bnfPages = jObj
            .querySelectorAll('[tag="215"]')[0]
            .querySelector('[code="a"]').textContent;
          const strb = bnfPages.substring(8);
          const bnfPagesNbr = parseFloat(strb.split(" ")[0]);
          if (jObj.querySelectorAll('[tag="330"]')[0]) {
            bnfFourth = jObj
              .querySelectorAll('[tag="330"]')[0]
              .querySelector('[code="a"]').textContent;
          }
          const dataOkBnf = {
            title: bnfTitle,
            book_pagesnbr: bnfPagesNbr,
            book_fourth: bnfFourth,
          };
          setData(dataOkBnf);
          setStage(40);
          setIsOk(true);
          setBackSearch(false);
          setIsLoading(false);
        } else {
          const imgUrl = `https://covers.openlibrary.org/b/isbn/${isbnImgSearch}-L.jpg`;
          let testConnect = await fetch(
            `https://openlibrary.org/isbn/${isbnImgSearch}.json`,
            {
              method: "GET",
            }
          );
          if (testConnect.status === 200) {
            let result = await testConnect.json();
            if (result.covers) {
              setCheckedImg(imgUrl);
              setStage(40);
              setState("Vérification du livre");
            } else {
              setCheckedImg("");
            }
            if (result.authors) {
              let testAuthor = await fetch(
                `https://openlibrary.org${result.authors[0].key}.json`,
                {
                  method: "GET",
                }
              );
              let resAuth = await testAuthor.json();
              if (testAuthor.ok && resAuth.name) {
                let nameauth = resAuth.name;
                setAuthorName(nameauth);
              } else {
                setAuthorName(false);
              }
            }
            setIsLoading(false);
            setIsOk(true);
            setData(result);
            setBackSearch(false);
          } else {
            const googleBooksAPI = await fetch(
              `https://www.googleapis.com/books/v1/volumes?q=isbn:${isbnImgSearch}`,
              { method: "GET" }
            );
            if (googleBooksAPI.status === 200) {
              let googleBooksResult = await googleBooksAPI.json();
              if (googleBooksResult.totalItems !== 0) {
                let googleAuthor = googleBooksResult.items[0].volumeInfo
                  .authors[0]
                  ? googleBooksResult.items[0].volumeInfo.authors[0]
                  : "Auteur non disponible";
                let googleTitle = googleBooksResult.items[0].volumeInfo.title
                  ? googleBooksResult.items[0].volumeInfo.title
                  : "Titre non disponible";
                let googleNbrPages = googleBooksResult.items[0].volumeInfo
                  .pageCount
                  ? googleBooksResult.items[0].volumeInfo.pageCount
                  : "0";
                let googleFourth = googleBooksResult.items[0].volumeInfo
                  .description
                  ? googleBooksResult.items[0].volumeInfo.description
                  : "";
                let googleCover = googleBooksResult.items[0].volumeInfo
                  .imageLinks
                  ? googleBooksResult.items[0].volumeInfo.imageLinks.thumbnail
                    ? googleBooksResult.items[0].volumeInfo.imageLinks.thumbnail
                    : ""
                  : "";
                setAuthorName(googleAuthor);
                setCheckedImg(googleCover);
                const dataOkGoogle = {
                  title: googleTitle,
                  book_pagesnbr: googleNbrPages,
                  book_fourth: googleFourth,
                };
                setData(dataOkGoogle);
                setStage(40);
                setIsOk(true);
                setBackSearch(false);
                setIsLoading(false);
              } else {
                setIsLoading(false);
                setIsOk(false);
                setBackSearch(false);
                alert("Aucun résultat. Veuillez réessayer.");
              }
            } else {
              setIsLoading(false);
              setIsOk(false);
              setBackSearch(false);
              alert("Aucun résultat. Veuillez réessayer.");
            }
          }
        }
      }
    }
  };
  return (
    <>
      <main>
        <h2 className="bookcreated__title">Créer une lecture</h2>
        <ProgressBar stage={stage} txt={state} />
        {isValidatedBook ? (
          <CreateDetailSession
            dataBook={data}
            isbn={isbnForm}
            imgSrc={checkedImg}
            auth={authorName}
            setStage={setStage}
            setState={setState}
          />
        ) : isLoading ? (
          <div className="bookcreated__loader">
            <LoaderOnly />
          </div>
        ) : isOk && !backSearch ? (
          <>
            {/* <BookOK dataBook={data} isbn={isbnImg} author={authorName}/> */}
            <BookOK dataBook={data} imgSrc={checkedImg} author={authorName} />
            <div className="form__book--line">
              <button className="form__book" onClick={goBack}>
                Retour
              </button>
              <button className="form__book" onClick={validateBook}>
                Confirmer
              </button>
            </div>
          </>
        ) : (
          <form>
            <div className="form__div">
              <label>
                <strong>ISBN / EAN :</strong>
              </label>
              <br />
              <input
                id="isbn"
                name="isbn"
                className="form__input--isbn"
                type="text"
                onChange={isbnChange}
              />
            </div>
            <input
              className="form__submit nodecoration"
              type="button"
              value="Rechercher"
              onClick={checkBook}
            />
          </form>
        )}
      </main>
    </>
  );
}

export default FindBook;
