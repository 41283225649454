import Header from '../../components/header/header';
import Signup from '../../components/signup/signup';
import Footer from '../../components/footer/footer';
import TopFooter from '../../components/topFooter/topFooter';

function SignUp() {
  return (
    <>
      <Header />
      <Signup />
      <TopFooter />
      <Footer />
    </>
  );
}

export default SignUp;