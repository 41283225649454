import AdminUsers from '../../components/adminusers/adminusers';
import Footer from '../../components/footer/footer';
import Header from '../../components/header/header';

function AdminViewUsers() {
  return (
    <>
      <Header />
      <AdminUsers />
      <Footer />
    </>
  );
}

export default AdminViewUsers;