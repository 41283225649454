import { Link } from "react-router-dom";
import logo from "../../assets/images/logo-80.png";
import "./nav.css";
import { useState } from "react";
import { IoClose } from "react-icons/io5";
import { BsChevronDown } from "react-icons/bs";
import { HiMiniBars3 } from "react-icons/hi2";
import { MdOutlineMarkEmailUnread } from 'react-icons/md';

const logout = () => {
  localStorage.removeItem("lecturecommuneToken");
  localStorage.removeItem("lecturecommuneUserId");
  document.location.href = "/";
};

function Nav({ connectStatus }) {
  const [books, setBooks] = useState(false);
  const [account, setAccount] = useState(false);
  const [unreadInProcess, setUnreadInProcess] = useState(0);
  const [unreadCompleted, setUnreadCompleted] = useState(0);
  
  const unreadVerif = async () => {
    const token = localStorage.lecturecommuneToken;
    const userId = localStorage.lecturecommuneUserId;
    const unreadCheck = await fetch(
      "https://www.lecturecommune.fr/api/reading/inprocess",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    );
    if (unreadCheck.status === 200) {
      let unreadInProcessNbr = 0;
      const result = await unreadCheck.json();
      result.map((check1) => {
        check1.session_followers.map((detailcheck1) => {
          if(detailcheck1.id === Number(userId) && detailcheck1.unread !== 0) {unreadInProcessNbr += 1;}
        })
      })
      setUnreadInProcess(unreadInProcessNbr);
    }
  };
  const unreadVerif2 = async () => {
    const token = localStorage.lecturecommuneToken;
    const userId = localStorage.lecturecommuneUserId;
    const unreadCheck2 = await fetch(
      "https://www.lecturecommune.fr/api/reading/completed",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    );
    if (unreadCheck2.status === 200) {
      let unreadCompletedNbr = 0;
      const result = await unreadCheck2.json();
      result.map((check2) => {
        check2.session_followers.map((detailcheck2) => {
          if(detailcheck2.id === Number(userId) && detailcheck2.unread !== 0) {unreadCompletedNbr += 1;}
        })
      })
      setUnreadCompleted(unreadCompletedNbr);
    }
  };

  const menuClose = () => {
    document.getElementById("nav").className = "nav__hidden";
  };
  function openBooks() {
    if (!books) {
      setBooks(true);
      setAccount(false);
    } else {
      setBooks(false);
    }
  }
  function openAccount() {
    if (!account) {
      setAccount(true);
      setBooks(false);
    } else {
      setAccount(false);
    }
  }
  if (!connectStatus) {
    return (
      <nav id="nav" className="nav__hidden">
        <div className="nav__top">
        {/* <div className="header__animation2"></div>
        <div className="header__animation3"></div>
        <div className="header__animation4"></div> */}
          <div className="nav__top--global">
            <div onClick={menuClose}>
              <p className="header__bars">
                <HiMiniBars3 />
              </p>
            </div>
            <div className="nav__top--line">
              <h1 className="nav__top--txt">Lecture</h1>
              <div className="nav__top--divlogo">
                <img
                  src={logo}
                  className="nav__top--logo"
                  alt="Logo de Lecture Commune"
                />
              </div>
              <h1 className="nav__top--txt">Commune</h1>
            </div>
            <div onClick={menuClose}>
              <IoClose className="header__bars" />
            </div>
          </div>
        </div>
        <ul className="nav">
          <Link to="/" className="nav__link nodecoration" onClick={menuClose}>
            <li className="nav__link">
              <p className="nav__link--divicon"></p>
              <p className="nav__link--txt">Accueil</p>
            </li>
          </Link>
          <Link
            to="/reading/all/"
            className="nav__link nav__link--border nodecoration"
            onClick={menuClose}
          >
            <li className="nav__link">
              <p className="nav__link--divicon"></p>
              <p className="nav__link--txt">Toutes les lectures</p>
            </li>
          </Link>
          <Link
            to="/connect"
            className="nav__link nav__link--border nodecoration"
            onClick={menuClose}
          >
            <li className="nav__link">
              <p className="nav__link--divicon"></p>
              <p className="nav__link--txt">Se connecter</p>
            </li>
          </Link>
          <Link
            to="/signup"
            className="nav__link nav__link--border nodecoration"
            onClick={menuClose}
          >
            <li className="nav__link">
              <p className="nav__link--divicon"></p>
              <p className="nav__link--txt">S'inscrire</p>
            </li>
          </Link>
          <Link
            to="/faq"
            className="nav__link nav__link--border nodecoration"
            onClick={menuClose}
          >
            <li className="nav__link">
              <p className="nav__link--divicon"></p>
              <p className="nav__link--txt">FAQ</p>
            </li>
          </Link>
          <Link
            to="/contact"
            className="nav__link nav__link--border nodecoration"
            onClick={menuClose}
          >
            <li className="nav__link">
              <p className="nav__link--divicon"></p>
              <p className="nav__link--txt">Nous contacter</p>
            </li>
          </Link>
          <Link
            to="/cgu"
            className="nav__link nav__link--border nodecoration"
            onClick={menuClose}
          >
            <li className="nav__link">
              <p className="nav__link--divicon"></p>
              <p className="nav__link--txt">
                Conditions générales d'utilisation
              </p>
            </li>
          </Link>
        </ul>
      </nav>
    );
  } else {
    unreadVerif();
    unreadVerif2();
    return (
      <nav id="nav" className="nav__hidden">
        <div className="nav__top">
        {/* <div className="header__animation2"></div>
        <div className="header__animation3"></div>
        <div className="header__animation4"></div> */}
          <div className="nav__top--global">
            <div onClick={menuClose}>
              <p className="header__bars">
                <HiMiniBars3 />
              </p>
            </div>
            <div className="nav__top--line">
              <h1 className="nav__top--txt">Lecture</h1>
              <div className="nav__top--divlogo">
                <img
                  src={logo}
                  className="nav__top--logo"
                  alt="Logo de Lecture Commune"
                />
              </div>
              <h1 className="nav__top--txt">Commune</h1>
            </div>
            <div onClick={menuClose}>
              <IoClose className="header__bars" />
            </div>
          </div>
        </div>
        <ul className="nav">
          <Link to="/" className="nav__link nodecoration" onClick={menuClose}>
            <li className="nav__link">
              <p className="nav__link--divicon"></p>
              <p className="nav__link--txt">Accueil</p>
            </li>
          </Link>
          <Link
            className="nav__globallink nav__link--border nodecoration"
            onClick={openAccount}
          >
            <li className="nav__line">
              <div className="nav__link">
                <p className="nav__link--divicon"></p>
                <p className="nav__link--txt">Mon compte</p>
              </div>
              <div className={`nav__link--arrow nav__link--arrow--${account}`}>
                <BsChevronDown />
              </div>
            </li>
          </Link>
          <div className={`nav__account--${account}`}>
            {account ? (
              <div className="nav__detail">
                <Link
                  className="nav__link--link nodecoration"
                  to="/user/profile"
                  onClick={menuClose}
                >
                  <p className="nav__link--detailtxt">{"> Mes informations"}</p>
                </Link>
                <Link
                  className="nav__link--link nodecoration"
                  to="/user/settings"
                  onClick={menuClose}
                >
                  <p className="nav__link--detailtxt">{"> Mes préférences"}</p>
                </Link>
                <Link
                  className="nav__link--link nodecoration"
                  to="/contact"
                  onClick={menuClose}
                >
                  <p className="nav__link--detailtxt">{"> Autre demande"}</p>
                </Link>
              </div>
            ) : (
              ""
            )}
          </div>
          {localStorage.lecturecommuneCheckAdmin &&
          <Link
            to="/adminview/"
            className="nav__link nav__link--border nodecoration"
            onClick={menuClose}
          >
            <li className="nav__link">
              <p className="nav__link--divicon"></p>
              <p className="nav__link--txt">Administration</p>
            </li>
          </Link>}
          <Link
            className="nav__globallink nav__link--border nodecoration"
            onClick={openBooks}
          >
            <li className="nav__line">
              <div className="nav__link">
                <p className="nav__link--divicon"></p>
                <p className="nav__link--txt">Lectures</p>
              </div>
              <div className={`nav__link--arrow nav__link--arrow--${books}`}>
                <BsChevronDown />
              </div>
            </li>
          </Link>
          <div className={`nav__books--${books}`}>
            {books ? (
              <div className="nav__detail">
                <Link
                  className="nav__link--link nodecoration"
                  to="/reading/inprocess/"
                  onClick={menuClose}
                >
                  <div className="nav__link--detailtxtbooks">
                    {unreadInProcess && unreadInProcess !== 0 ? <div className="nav__link--unread"><span>{"> Mes lectures en cours"}</span><MdOutlineMarkEmailUnread className="nav__link--unreadicon"/></div> : "> Mes lectures en cours"}
                  </div>
                </Link>
                <Link
                  className="nav__link--link nodecoration"
                  to="/reading/completed/"
                  onClick={menuClose}
                >
                  <div className="nav__link--detailtxtbooks">
                  {unreadCompleted && unreadCompleted !== 0 ? <div className="nav__link--unread"><span>{"> Mes lectures terminées"}</span><MdOutlineMarkEmailUnread className="nav__link--unreadicon"/></div> : "> Mes lectures terminées"}
                  </div>
                </Link>
                <Link
                  className="nav__link--link nodecoration"
                  to="/reading/created"
                  onClick={menuClose}
                >
                  <p className="nav__link--detailtxtbooks">
                    {"> Mes lectures créées"}
                  </p>
                </Link>
                <Link
                  className="nav__link--link nodecoration"
                  to="/reading/all/"
                  onClick={menuClose}
                >
                  <p className="nav__link--detailtxtbooks">
                    {"> Toutes les lectures"}
                  </p>
                </Link>
                <Link
                  className="nav__link--link nodecoration"
                  to="/reading/lastcreated"
                  onClick={menuClose}
                >
                  <p className="nav__link--detailtxtbooks">
                    {"> Dernières lectures créées"}
                  </p>
                </Link>
                <Link
                  className="nav__link--link nodecoration"
                  to="/reading/create"
                  onClick={menuClose}
                >
                  <p className="nav__link--detailtxtbooks">
                    {"> Créer une lecture"}
                  </p>
                </Link>
              </div>
            ) : (
              ""
            )}
          </div>
          <Link
            to="/user/all"
            className="nav__link nav__link--border nodecoration"
            onClick={menuClose}
          >
            <li className="nav__link">
              <p className="nav__link--divicon"></p>
              <p className="nav__link--txt">Les lecteurs</p>
            </li>
          </Link>
          <Link
            to="/faq"
            className="nav__link nav__link--border nodecoration"
            onClick={menuClose}
          >
            <li className="nav__link">
              <p className="nav__link--divicon"></p>
              <p className="nav__link--txt">FAQ</p>
            </li>
          </Link>
          <Link
            to="/contact"
            className="nav__link nav__link--border nodecoration"
            onClick={menuClose}
          >
            <li className="nav__link">
              <p className="nav__link--divicon"></p>
              <p className="nav__link--txt">Nous contacter</p>
            </li>
          </Link>
          <Link
            to="/cgu"
            className="nav__link nav__link--border nodecoration"
            onClick={menuClose}
          >
            <li className="nav__link">
              <p className="nav__link--divicon"></p>
              <p className="nav__link--txt">
                Conditions générales d'utilisation
              </p>
            </li>
          </Link>
          <Link
            className="nav__link nav__link--border nodecoration"
            onClick={logout}
          >
            <li className="nav__link">
              <p className="nav__link--divicon"></p>
              <p className="nav__link--txt">Se déconnecter</p>
            </li>
          </Link>
        </ul>
      </nav>
    );
  }
}

export default Nav;
