import "./connexion.css";
import { useState } from "react";
import { Link } from "react-router-dom";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import configUrl from "../../assets/config";
const validator = require("email-validator");

const Connexion = () => {
  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState(false);
  const [password, setPassword] = useState("");
  const [showPass, setShowPass] = useState(false);
  const [stayConnected, setStayConnected] = useState(true);
  const handleClick = () => {
    setEmail(document.getElementById("email").value);
  };
  const handleClick2 = () => {
    setPassword(document.getElementById("password").value);
  };
  const handleClick3 = () => {
    setStayConnected(!stayConnected);
  };
  const displayPassword = () => {
    if (showPass) {
      document.getElementById("password").setAttribute("type", "password");
      document
        .getElementById("form__eye--true")
        .classList.add("form__eye--display");
      document
        .getElementById("form__eye--true")
        .classList.remove("form__eye--nodisplay");
      document
        .getElementById("form__eye--false")
        .classList.add("form__eye--nodisplay");
      document
        .getElementById("form__eye--false")
        .classList.remove("form__eye--display");
      setShowPass(false);
    } else {
      document.getElementById("password").setAttribute("type", "text");
      document
        .getElementById("form__eye--false")
        .classList.add("form__eye--display");
      document
        .getElementById("form__eye--false")
        .classList.remove("form__eye--nodisplay");
      document
        .getElementById("form__eye--true")
        .classList.add("form__eye--nodisplay");
      document
        .getElementById("form__eye--true")
        .classList.remove("form__eye--display");
      setShowPass(true);
    }
  };
  const sendData = async (e) => {
    e.preventDefault();
    if (emailValid && password) {
      let dataToSend = {
        email: email,
        password: password,
        stay: stayConnected
      };
      let response = await fetch(`${configUrl.api}user/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataToSend),
      });
      if (response && response.status === 200) {
        let result = await response.json();
        localStorage.setItem("lecturecommuneToken", result.token);
        localStorage.setItem("lecturecommuneUserId", result.userId);
        document.location.href = "/";
      } else if (response && response.status === 401) {
        alert("Veuillez renseigner votre adresse email.");
      } else if (response && response.status === 404) {
        alert(
          "Votre compte n'a pas été activé. Veuillez suivre le lien reçu par mail."
        );
      } else {
        alert("Impossible de vous identifier. Veuillez réessayer.");
      }
    } else {
      alert("Veuillez renseigner votre adresse mail et votre mot de passe");
    }
  };
  const checkEmail = () => {
    const testValid = validator.validate(
      document.getElementById("email").value
    );
    setEmailValid(testValid);
    document
      .querySelector(".form__input--email")
      .classList.add(`form__input--email--${emailValid}`);
    document
      .querySelector(".form__input--email")
      .classList.remove(`form__input--email--${!emailValid}`);
  };
  return (
    <main className="main__connect">
      <h2 className="connexion__title">Connexion</h2>
      <form onSubmit={sendData}>
        <div className="form__div">
          <label>Adresse email :</label>
          <br />
          <input
            id="email"
            name="email"
            className="form__input form__input--mail"
            type="email"
            onChange={handleClick}
            onBlur={checkEmail}
          />
          <div className="form__input--email form__input--email--true">
            {/* Adresse email incorrecte */}
          </div>
        </div>
        <div className="form__div">
          <label>Mot de passe :</label>
          <br />
          <div className="form__eye--line">
            <input
              id="password"
              className="form__input form__input--pass"
              type="password"
              onChange={handleClick2}
            />
            <div className="form__input--password" onClick={displayPassword}>
              <AiOutlineEyeInvisible
                id="form__eye--false"
                className="form__eye form__eye--nodisplay"
              />
              <AiOutlineEye
                id="form__eye--true"
                className="form__eye form__eye--display"
              />
            </div>
          </div>
        </div>
        <div className="form__divline">
          <label>Rester connecté : </label>
          <br />
            <input
              id="stay"
              type="checkbox"
              onChange={handleClick3}
              checked={stayConnected}
            />
        </div>
        
        <input
          className="form__submit nodecoration"
          type="submit"
          value="Se connecter"
          onClick={sendData}
        />
        
      <Link to='/reinitpass' className="nodecoration"><p>Mot de passe oublié</p></Link>
      </form>
    </main>
  );
};

export default Connexion;
