import Footer from '../../components/footer/footer';
import GetLastSessions from '../../components/getlastsessions/getlastsession';
import Header from '../../components/header/header';
import TopFooter from '../../components/topFooter/topFooter';

function LastSessions() {
  return (
    <>
      <Header />
      <GetLastSessions />
      <TopFooter />
      <Footer />
    </>
  );
}

export default LastSessions;