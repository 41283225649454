import { useState, useEffect } from "react";
import "./readingResume.css";
import { useNavigate } from "react-router-dom";
import { BiZoomIn } from "react-icons/bi";

const ReadingResume = ({ dataDisplay }) => {
  const [name, setName] = useState();
  const navigate = useNavigate();
  const creator = async () => {
    if (localStorage.lecturecommuneToken && localStorage.lecturecommuneUserId) {
      const token = localStorage.lecturecommuneToken;
      const getName = await fetch(
        `https://www.lecturecommune.fr/api/user/getname/${dataDisplay.session_author}`,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      if (getName.status === 200) {
        const resName = await getName.json();
        setName(resName[0].username);
      }
    } else {
      setName(false);
    }
  };
  useEffect( () => { creator() }, []);
  return (
    <div className="rdetails">
      <div className="rdetails__line">
        {dataDisplay.book_cover ? (
          <img
            className="rdetails__img"
            src={dataDisplay.book_cover}
            alt="Couverture du livre"
          />
        ) : (
          <div className="rdetails__noimg">
            <p className="rdetails__noimg--title">
              <strong>Couverture non disponible</strong>
            </p>
          </div>
        )}
        <div className="rdetails__column">
          <p className="rdetails__title">
            {dataDisplay.book_name !== "Titre non disponible" ? (
              <strong>{dataDisplay.book_name}</strong>
            ) : (
              <i>
                <strong>Titre non disponible</strong>
              </i>
            )}
          </p>
          <p className="rdetails__author">
            {dataDisplay.book_author !== "Auteur non disponible" ? (
              dataDisplay.book_author
            ) : (
              <i>Auteur non disponible</i>
            )}
          </p>
        </div>
      </div>
      <div className="rdetails__container">
        <div className="rdetails__container--card">
          <div>
            <p className="rdetails__container--title">Lecture créée par</p>
          </div>
          <div>
            <p className="rdetails__container--txt rdetails__container--author" onClick={() => {navigate(`/user/${dataDisplay.session_author}`)}}>
              {name ? <><BiZoomIn />{name}</> : "---"}
            </p>
          </div>
        </div>
        <div className="rdetails__container--card">
          <div>
            <p className="rdetails__container--title">Début de la lecture</p>
          </div>
          <div>
            <p className="rdetails__container--txt">
                {dataDisplay.session_begin_date
                  ? `${
                      new Date(dataDisplay.session_begin_date).getDate() < 10
                        ? `0${new Date(
                            dataDisplay.session_begin_date
                          ).getDate()}`
                        : new Date(dataDisplay.session_begin_date).getDate()
                    } / ${
                      new Date(dataDisplay.session_begin_date).getMonth() + 1 <
                      10
                        ? `0${
                            new Date(
                              dataDisplay.session_begin_date
                            ).getMonth() + 1
                          }`
                        : new Date(dataDisplay.session_begin_date).getMonth() +
                          1
                    } / ${new Date(
                      dataDisplay.session_begin_date
                    ).getFullYear()}`
                  : "---"}
            </p>
          </div>
        </div>
        <div className="rdetails__container--card">
          <div>
            <p className="rdetails__container--title">Fin de la lecture</p>
          </div>
          <div>
            <p className="rdetails__container--txt">
                {dataDisplay.session_end_inscription_date
                  ? `${
                      new Date(
                        dataDisplay.session_end_inscription_date
                      ).getDate() < 10
                        ? `0${new Date(
                            dataDisplay.session_end_inscription_date
                          ).getDate()}`
                        : new Date(
                            dataDisplay.session_end_inscription_date
                          ).getDate()
                    } / ${
                      new Date(
                        dataDisplay.session_end_inscription_date
                      ).getMonth() +
                        1 <
                      10
                        ? `0${
                            new Date(
                              dataDisplay.session_end_inscription_date
                            ).getMonth() + 1
                          }`
                        : new Date(
                            dataDisplay.session_end_inscription_date
                          ).getMonth() + 1
                    } / ${new Date(
                      dataDisplay.session_end_inscription_date
                    ).getFullYear()}`
                  : "---"}
            </p>
          </div>
        </div>
        <div className="rdetails__container--card">
          <div>
            <p className="rdetails__container--title">Lecteurs inscrits</p>
          </div>
          <div>
            <div className="rdetails__container--txt">
                {dataDisplay.session_followers
                  ? <div className="rdetails__container--nbrfollowers">
                    {/* <BiZoomIn /> */}
                    <span>{Object.keys(dataDisplay.session_followers).length}</span></div>
                  : "---"}
            </div>
          </div>
        </div>
        {dataDisplay.book_pagesnbr ? (
          <div className="rdetails__pagesnbr">
            <p className="rdetails__fourth--title">
              Nombre de pages :
            </p>
            <p className="rdetails__pagesnbr--txt">
              {dataDisplay.book_pagesnbr}
            </p>
          </div>
        ) : (
          ""
        )}
        {dataDisplay.book_fourth ? (
          <div className="rdetails__fourth">
            <p className="rdetails__fourth--title">
              4ème de couverture
            </p>
            <p className="rdetails__fourth--txt">{dataDisplay.book_fourth}</p>
          </div>
        ) : (
          ""
        )}
        <div className="rdetails__fourth">
          <p className="rdetails__fourth--title">Résumé de la session :</p>
          <p className="rdetails__fourth--txt">
            {dataDisplay.session_description}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ReadingResume;
