import { useParams } from 'react-router-dom';
import ConfirmPass from '../../components/confirm/confirm';
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import TopFooter from '../../components/topFooter/topFooter';

function Confirm() {
  const passKey = useParams();
  return (
    <>
      <Header />
      <ConfirmPass pass={passKey} />
      <TopFooter />
      <Footer />
    </>
  );
}

export default Confirm;