import AdminHome from '../../components/adminHome/adminHome';
import Footer from '../../components/footer/footer';
import Header from '../../components/header/header';
import TopFooter from '../../components/topFooter/topFooter';

function AdminView() {
  return (
    <>
      <Header />
      <AdminHome />
      <TopFooter />
      <Footer />
    </>
  );
}

export default AdminView;