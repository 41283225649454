import FindBook from '../../components/findbook/findbook';
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import TopFooter from '../../components/topFooter/topFooter';

function CreateReading() {
  return (
    <>
      <Header />
      <FindBook />
      <TopFooter />
      <Footer />
    </>
  );
}

export default CreateReading;