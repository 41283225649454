import "./userpreferences.css";
import { useState, useEffect } from "react";

const UserPreferences = () => {
  const [actualNewReader, setActualNewReader] = useState();
  const [actualNewReading, setActualNewReading] = useState();
  const [actualNewReadingMsg, setActualNewReadingMsg] = useState();
  const [actualCountNewReader, setActualCountNewReader] = useState();
  const [actualCountNewReading, setActualCountNewReading] = useState();
  const [actualCountNewReadingMsg, setActualCountNewReadingMsg] = useState();
  const token = localStorage.lecturecommuneToken;
  const getSettings = async () => {
    let response = await fetch(
      "https://www.lecturecommune.fr/api/user/profile/getsettings",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    );
    if (response && response.status === 200) {
      let result = await response.json();
      setActualNewReader(result.messages_recept[0].newreader);
      setActualNewReading(result.messages_recept[0].newreading);
      setActualNewReadingMsg(result.messages_recept[0].newreadingmsg);
      setActualCountNewReader(result.messages_recept[0].count_newreader);
      setActualCountNewReading(result.messages_recept[0].count_newreading);
      setActualCountNewReadingMsg(
        result.messages_recept[0].count_newreadingmsg
      );
    }
  };
  const changeNewReader = () => {
    actualNewReader === 0 ? setActualNewReader(1) : setActualNewReader(0);
  };
  const changeNewReading = () => {
    actualNewReading === 0 ? setActualNewReading(1) : setActualNewReading(0);
  };
  const changeNewReadingMsg = () => {
    actualNewReadingMsg === 0
      ? setActualNewReadingMsg(1)
      : setActualNewReadingMsg(0);
  };
  const editPreferences = async (e) => {
    e.preventDefault();
    const newDataToSend = {
      update: {
        messages_recept: [
          {
            newreader: actualNewReader,
            count_newreader: actualCountNewReader,
            newreading: actualNewReading,
            count_newreading: actualCountNewReading,
            newreadingmsg: actualNewReadingMsg,
            count_newreadingmsg: actualCountNewReadingMsg,
          },
        ],
      },
    };
    const edit = await fetch(
      "https://www.lecturecommune.fr/api/user/profile/editsettings",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(newDataToSend),
      }
    );
    if (edit.status === 200) {
      getSettings();
      alert('Préférences enregistrées');
    } else {
      alert("Erreur : Veuillez réessayer.");
    }
  };
  useEffect(() => {
    getSettings();
  }, []);
  return (
    <main className="preferences">
      <h2>Préférences</h2>
      <br />
      <p className="preferences__description">
        Vous pouvez modifier vos préférences relatives aux notifications
        envoyées par mail.
        <br />
        <br />
        Vous souhaitez être notifié(e) :
      </p>
      <form onSubmit={editPreferences}>
        <div className="form__div preferences__line">
          <p className="preferences__category">
            Lors de la création d'une nouvelle lecture
          </p>
          <div>
            <div className="toggleWrapper">
              <input
                className="preferences__input"
                type="checkbox"
                id="newReading"
                name="newReading"
                checked={actualNewReading ? true : false}
                onChange={changeNewReading}
              />
              <label className="preferences__label" htmlFor="newReading">
                <i className="preferences__i"></i>
              </label>
            </div>
          </div>
        </div>
        <div className="form__div preferences__line">
          <p className="preferences__category">
            Lorsqu'un lecteur rejoint mes lectures
          </p>
          <div>
            <div className="toggleWrapper">
              <input
                className="preferences__input"
                type="checkbox"
                id="newReader"
                name="newReader"
                checked={actualNewReader ? true : false}
                onChange={changeNewReader}
              />
              <label className="preferences__label" htmlFor="newReader">
                <i className="preferences__i"></i>
              </label>
            </div>
          </div>
        </div>
        <div className="form__div preferences__line">
          <p className="preferences__category">
            Lorsqu'un nouveau message est ajouté
          </p>
          <div>
            <div className="toggleWrapper">
              <input
                className="preferences__input"
                type="checkbox"
                id="newReadingMsg"
                name="newReadingMsg"
                checked={actualNewReadingMsg ? true : false}
                onChange={changeNewReadingMsg}
              />
              <label className="preferences__label" htmlFor="newReadingMsg">
                <i className="preferences__i"></i>
              </label>
            </div>
          </div>
        </div>
        <input
          className="form__submit nodecoration"
          type="submit"
          value="Valider"
          onClick={editPreferences}
        />
      </form>
    </main>
  );
};

export default UserPreferences;
