import "./listusers.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MdOutlineNoPhotography } from "react-icons/md";
import { RiExpandUpDownFill } from "react-icons/ri";
import { BiZoomIn } from "react-icons/bi";
const jsonata = require("jsonata");

function ListUsers({ user }) {
  const [isOpened, setIsOpened] = useState();
  const [lastTxt, setLastTxt] = useState();
  const nbr_followed = user.sessions_followed;
  const nbr_created = user.sessions_created;
  const navigate = useNavigate();
  function Open() {
    !isOpened ? setIsOpened(true) : setIsOpened(false);
  }
  async function lastConnexion() {
    if (user.last_connexion) {
    let dateLast = `Le ${user.last_connexion.substring(
      8,
      10
    )}/${user.last_connexion.substring(5, 7)}/${user.last_connexion.substring(
      0,
      4
    )}`;
    let lastConnect = await jsonata(`$toMillis("${user.last_connexion}")`);
    lastConnect = await lastConnect.evaluate();
    lastConnect = lastConnect / 1000;
    let now = await jsonata("$toMillis($now())");
    now = await now.evaluate();
    now = now / 1000;
    const resultLastConnect = now - lastConnect;
    resultLastConnect <= 60
      ? setLastTxt("Moins d'une minute")
      : resultLastConnect > 60 && resultLastConnect <= 300
      ? setLastTxt("Moins de 5 minutes")
      : resultLastConnect > 300 && resultLastConnect <= 600
      ? setLastTxt("Moins de 10 minutes")
      : resultLastConnect > 600 && resultLastConnect <= 1800
      ? setLastTxt("Moins de 30 minutes")
      : resultLastConnect > 1800 && resultLastConnect <= 3600
      ? setLastTxt("Moins d'une heure")
      : resultLastConnect > 3600 && resultLastConnect <= 7200
      ? setLastTxt("Moins de 2 heures")
      : resultLastConnect > 7200 && resultLastConnect <= 18000
      ? setLastTxt("Moins de 6 heures")
      : resultLastConnect > 18000 && resultLastConnect <= 43200
      ? setLastTxt("Moins de 12 heures")
      : resultLastConnect > 43200 && resultLastConnect <= 86400
      ? setLastTxt("Moins de 24 heures")
      : setLastTxt(dateLast);
  }}
  useEffect( () => { lastConnexion() }, []);
  return (
    <div className="box">
    <div className="userbox__left--pseudo">
      <h3><strong>{user.username}</strong></h3>
    </div>
      <div className="userbox">
        <div className="userbox__left">
          {user.img ? (
            <img
              className="userbox__left--photo"
              src={user.img}
              alt={`Avatar de ${user.username}`}
            />
          ) : (
            <div className="userbox__left--nophoto">
              <p className="userbox__left--txt">
                <MdOutlineNoPhotography />
              </p>
            </div>
          )}
        </div>
        <div className="userbox__right">
          <div className="userbox__right--info">
            {/* Lectures créées : <strong>{Object.keys(nbr_created).length}</strong> */}
            <div className="profile__essential--nbrcreated profile__line">
            {`Lectures créées : `}
            <p
              className="profile__center"
              onClick={() => {
                navigate(`/reading/createdby/${user.id}`);
              }}
            >
              <strong>{Object.keys(nbr_created).length}</strong>
              <span className="profile__center">
                {` `}
                <BiZoomIn />
              </span>
            </p>
          </div>
          </div>
          <div className="userbox__right--info">
            {/* Lectures suivies : <strong>{Object.keys(nbr_followed).length}</strong> */}
            <div className="profile__essential--nbrcreated profile__line">
            {`Lectures suivies : `}
            <p
              className="profile__center"
              onClick={() => {
                navigate(`/reading/followedby/${user.id}`);
              }}
            >
              <strong>{Object.keys(nbr_followed).length}</strong>
              <span className="profile__center">
                {` `}
                <BiZoomIn />
              </span>
            </p>
          </div>
          </div>
        </div>
      </div>
      <div className="userbox__end">
          <p className="userbox__right--info">
            Instagram : <strong>{user.insta ? <a href={`https://www.instagram.com/${user.insta.substring(1)}`} target="_blank" rel="noreferrer" className="nodecoration">{user.insta}</a> : <span>{"- - -"}</span>}</strong>
          </p>
          <p className="userbox__right--info">
            Site Web : {" "}
            {user.website ? (
              <a href={user.website} target="_blank" rel="noopener noreferrer" className="nodecoration">
                <strong>Lien</strong>
              </a>
            ) : (
              <strong><span>{"- - -"}</span></strong>
            )}
          </p></div>
      {user.bio ? <div className="userbox__bio"><p>Bio : {user.bio}</p></div> : ''}
      <div className={`userbox__end userbox__end--${isOpened}`}>
        {isOpened ? (
          <p className="userbox__end--txt">Dernière Connexion : <strong>{lastTxt ? lastTxt : '- - -'}</strong></p>
        ) : (
          ""
        )}
      </div>
      <button onClick={Open} className="userbox__right--button">
            <RiExpandUpDownFill/>
      </button>
    </div>
  );
}

export default ListUsers;