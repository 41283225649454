import "./footer.css";
import { FaSquareFacebook, FaSquareInstagram } from 'react-icons/fa6';
import { FaCopyright } from 'react-icons/fa';

const Footer = () => {
  // const [data, setData] = useState();
  // const dataLast = async () => {
  //   const testConnect = await fetch(
  //     "https://www.lecturecommune.fr/api/reading/count",
  //     {
  //       method: "GET",
  //     }
  //   );
  //   if (testConnect.status === 200) {
  //     const result = await testConnect.json();
  //     setData(result);
  //   }
  // };
  // useEffect(() => dataLast, []);
  return (
    <footer className="footer">
      <div className="footer__social">
        <a href='https://www.instagram.com/lecturecommune/' target="_blank" rel="noreferrer" className="nodecoration footer__social--center"><FaSquareInstagram /></a>
      </div>
      <div className="footer__txt">
        <FaCopyright className="fa-solid fa-copyright" />
        {` 2023 - Lecture Commune - v 1.1.6`}
      </div>
    </footer>
  );
};

export default Footer;
