import Footer from '../../components/footer/footer';
import Header from '../../components/header/header';
import ReadingsInProcess from '../../components/readingsInProcess/readingsInProcess';
import TopFooter from '../../components/topFooter/topFooter';

function ReadingInProcess() {
  return (
    <>
      <Header />
      <ReadingsInProcess />
      <TopFooter />
      <Footer />
    </>
  );
}

export default ReadingInProcess;