import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import TopFooter from '../../components/topFooter/topFooter';
import { useParams } from 'react-router-dom';
import ReinitPassConfirm from '../../components/reinitPassConfirm/reinitPassConfirm';

function ReinitPassword() {
    const pass = useParams();
  return (
    <>
      <Header />
      <TopFooter />
      <ReinitPassConfirm pass={pass} />
      <Footer />
    </>
  );
}

export default ReinitPassword;