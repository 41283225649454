import { useEffect, useState } from "react";
import ProgressBar from "../progressbar/progressbar";
import "./readingSettings.css";
import { BiSave } from "react-icons/bi";

const ReadingSettings = ({ data }) => {
  const [progressSelf, setProgressSelf] = useState(0);
  const [pagesReadedForm, setPagesReadedForm] = useState();
  const [progressCible, setProgressCible] = useState(0);
  const [cuttingChoice, setCuttingChoice] = useState(false);
  const [cuttingDetail, setCuttingDetail] = useState(
    data.session_followers[0].cuttingdetail !== []
      ? data.session_followers[0].cuttingdetail
      : []
  );
  const [cutOption, setCutOption] = useState();
  const [progressChoice, setProgressChoice] = useState();
  const first = async () => {
    const token = localStorage.lecturecommuneToken;
    const checkInitial = await fetch(
      `https://www.lecturecommune.fr/api/reading/checkcut/${data.id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    );
    if (checkInitial.status === 200) {
      let responsee = await checkInitial.json();
      responsee == true ? setCuttingChoice(true) : setCuttingChoice(false);
      if (
        data.session_followers[0].cuttingoption &&
        data.session_followers[0].cuttingoption !== ""
      ) {
        if (
          data.session_followers[0].cuttingoption === "pages" ||
          data.session_followers[0].cuttingoption === "chap"
        ) {
          setCutOption(data.session_followers[0].cuttingoption);
          setProgressChoice(data.session_followers[0].cuttingoption)
        }
      } else {
        setCutOption("pages");
        setProgressChoice("pages");
      }
      // responsee == true && setCuttingDetail(data.session_followers[0].cuttingdetail);
    }
    await CheckPercent();
  };
  const changeCuttingChoice = () => {
    cuttingChoice === false ? setCuttingChoice(true) : setCuttingChoice(false);
  };
  const changeCutOption = () => {
    cutOption
      ? cutOption === "pages"
        ? setCutOption("chap")
        : setCutOption("pages")
      : setCutOption("pages");
  };
  const activeCutting = async () => {
    const token = localStorage.lecturecommuneToken;
    const sessionId = data.id;
    let sendArray = [];
    let counter = 0;
    let counterErrorPage = 0;
    let nbrPagesBook = data.book_pagesnbr;
    for (let index = 0; index < listDaysArray.length; index++) {
      let testDate = new Date(data.session_begin_date);
      let testDay = testDate.getDate() + index;
      testDate.setDate(Number(testDay));
      sendArray.push({
        id: index + 1,
        date: testDate,
        pages: document.getElementById(`cutCible${index + 1}`).value,
      });
      if (document.getElementById(`cutCible${index + 1}`).value !== "") {
        counter += 1;
      }
      if (
        document.getElementById(`cutCible${index + 1}`).value > nbrPagesBook
      ) {
        counterErrorPage += 1;
      }
    }
    if (listDaysArray.length === counter) {
      if (counterErrorPage > 0) {
        alert(
          "Un champ comporte un nombre de pages supérieur aux nombre de pages du livre."
        );
      } else {
        const sendArrayCut = sendArray;
        setCuttingDetail(sendArrayCut);
        const editCut = await fetch(
          `https://www.lecturecommune.fr/api/reading/activecut${
            cutOption === "chap" ? "chap" : ""
          }/${sessionId}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
            body: JSON.stringify(sendArrayCut),
          }
        );
        if (editCut.status === 200) {
          data.session_followers[0].cutting = sendArrayCut;
          setCuttingChoice(true);
          alert("Découpage enregistré !");
          window.location.reload(false);
        } else {
          alert("Erreur - Veuillez réessayer.");
        }
      }
    } else {
      alert("Veuillez renseigner le nombre total de pages pour chaque date");
    }
  };
  let listDays = [];
  const diffDays =
    (new Date(data.session_end_inscription_date) -
      new Date(data.session_begin_date)) /
      (1000 * 3600 * 24) +
    1;
  for (let index = 0; index < diffDays; index++) {
    let testDate = new Date(data.session_begin_date);
    let testDay = testDate.getDate() + index;
    testDate.setDate(Number(testDay));
    let testEndDate = new Date(data.session_end_inscription_date).setHours(23);
    let testEndDate2 = new Date(testEndDate).setMinutes(59);
    if (testDate < new Date(testEndDate2)) {
      listDays.push({ id: index + 1, date: testDate });
    }
  }
  const listDaysArray = listDays;
  const verifId = localStorage.lecturecommuneUserId;
  let pagesReaded = 0;
  let nrbfound = 0;

  const CheckPercent = () => {
    data.session_followers.map((data2) => {
      if (data2.id === Number(localStorage.lecturecommuneUserId)) {
        pagesReaded = data2.stage;
        setPagesReadedForm(pagesReaded);
        nrbfound += 1;
      }
    });
    if (nrbfound === 0) {
      pagesReaded = 0;
    }
    let nbrPagesBook = data.session_followers[0].cuttingoption === "chap" ? cuttingDetail[cuttingDetail.length - 1].pages : data.book_pagesnbr;
    let progressPercent =
      (((pagesReaded / nbrPagesBook) * 100) / 5).toFixed(0) * 5;
      setProgressSelf(progressPercent);
    CheckPercentCible();
  };
  const CheckPercentCible = () => {
    let nbrPagesCible = data.session_stage;
    let nbrPagesBook = data.session_followers[0].cuttingoption === "chap" ? cuttingDetail[cuttingDetail.length - 1].pages : data.book_pagesnbr;
    let progressPercent =
      data.session_followers[0].cutting === "false"
        ? (((nbrPagesCible / nbrPagesBook) * 100) / 5).toFixed(0) * 5
        : (((nbrPagesCible / nbrPagesBook) * 100) / 5).toFixed(0) * 5;
    setProgressCible(progressPercent);
  };
  const changeProgress = () => {
    if (document.getElementById("progressbook").value !== "") {
      let newProgress = Math.floor(
        document.getElementById("progressbook").value
      );
      if (newProgress < 0 || newProgress > (progressChoice && progressChoice === "chap" ? cuttingDetail[cuttingDetail.length - 1].pages : data.book_pagesnbr)) {
        alert(
          "Erreur : veuillez vérifier le nombre de pages / chapites lu(e)s et réessayer."
        );
      } else {
        const token = localStorage.lecturecommuneToken;
        const dataNewReaded = { editstage: newProgress };
        fetch(
          `https://www.lecturecommune.fr/api/reading/editstage/${data.id}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
            body: JSON.stringify(dataNewReaded),
          }
        ).then(() => {
          data.session_followers.map((data3) => {
            if (data3.id === Number(localStorage.lecturecommuneUserId)) {
              data3.stage = newProgress;
            }
          });
          CheckPercent();
        });
      }
    }
  };
  const changeProgressCible = () => {
    if (document.getElementById("progressbookcible").value !== "") {
      let newProgress2 = Math.floor(
        document.getElementById("progressbookcible").value
      );
      if (newProgress2 < 0 || newProgress2 > data.book_pagesnbr) {
        alert(
          "Erreur : veuillez vérifier le nombre de pages lues et réessayer."
        );
      } else {
        const token = localStorage.lecturecommuneToken;
        const dataNewReadedCible = { editstage: newProgress2 };
        fetch(
          `https://www.lecturecommune.fr/api/reading/editstagecible/${data.id}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
            body: JSON.stringify(dataNewReadedCible),
          }
        ).then(() => {
          data.session_stage = newProgress2;
          data.session_followers[0].cutting = "false";
          CheckPercentCible();
          setCuttingChoice(false);
          alert("Objectif de progression enregistré.");
        });
      }
    }
  };
  const disjoinSession = () => {
    let text =
      "Voulez-vous vraiment quitter cette LC ?\nOK pour quitter la LC\nAnnuler pour continuer la lecture.";
    if (window.confirm(text) === true) {
      const token = localStorage.lecturecommuneToken;
      fetch(`https://www.lecturecommune.fr/api/reading/disjoin/${data.id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then(() => {
          document.location.href = "/";
        })
        .catch((error) => {
          document.location.href = "/";
        });
    } else {
      console.log("no");
    }
  };
  useEffect(() => {
    first();
  }, []);
  return (
    <div className="readingsettings">
      {verifId === data.session_author ? (
        <div className="readingsettings__global">
          {/* <div className="readingsettings__global">
            <p>
              <strong>Statut de la lecture :</strong>
            </p>
            <select className="readingsettings__state">
              <option
                className="readingsettings__state--choice"
                value="0"
                label="Non demarrée"
              />
              <option
                className="readingsettings__state--choice"
                value="1"
                label="Démarrée"
              />
              <option
                className="readingsettings__state--choice"
                value="1"
                label="Terminée"
              />
            </select>
          </div> */}
          <div className="readingsettings__global">
            <div className="form__div preferences__line">
              <p className="preferences__category">Découpage :</p>
              <div>
                <div className="toggleWrapper">
                  <input
                    className="preferences__input"
                    type="checkbox"
                    id="newReader"
                    name="newReader"
                    checked={cuttingChoice ? cuttingChoice : cuttingChoice}
                    onChange={() => {
                      changeCuttingChoice();
                    }}
                  />

                  <label className="preferences__label" htmlFor="newReader">
                    <i className="preferences__i"></i>
                  </label>
                </div>
              </div>
            </div>

            {cuttingChoice === false ? (
              <div className="readingsettings__global readingsettings__global--part">
                <div className="form__div preferences__line"></div>
                <p>
                  <strong>Progression attendue :</strong>
                </p>
                <ProgressBar stage={progressCible} txt="" />
                <p className="readingsettings__subtitle">
                  Nombre de pages lues :
                </p>
                <div className="readingsettings__pages">
                  <div className="readingsettings__formline">
                    <input
                      id="progressbookcible"
                      name="progressbookcible"
                      className="form__input form__input--pages"
                      type="number"
                      defaultValue={data.session_stage}
                    />
                    <div
                      className="readingsettings__formline--btn nodecoration"
                      onClick={changeProgressCible}
                    >
                      <BiSave />
                    </div>
                  </div>
                  <div className="readingsettings__pages--descript">
                    A modifier - Entre 0 et {data.book_pagesnbr}
                  </div>
                </div>
              </div>
            ) : (
              <div className="readingsettings__global readingsettings__global--part">
                <div className="form__div preferences__line"></div>
                <p>
                  <strong>Découpage :</strong>
                </p>
                <div className="cutoptions">
                  <div
                    className={`cutoptions__option1 ${
                      cutOption
                        ? cutOption === "pages"
                          ? "cutoptions__active"
                          : ""
                        : ""
                    }`}
                    onClick={() => {
                      cutOption === "chap" && changeCutOption();
                    }}
                  >
                    Par pages
                  </div>
                  <div
                    className={`cutoptions__option2 ${
                      cutOption
                        ? cutOption === "chap"
                          ? "cutoptions__active"
                          : ""
                        : ""
                    }`}
                    onClick={() => {
                      cutOption === "pages" && changeCutOption();
                    }}
                  >
                    Par chapitres
                  </div>
                </div>
                <p className="readingsettings__subtitle">
                  En nombre de{" "}
                  {cutOption
                    ? cutOption === "pages"
                      ? "pages"
                      : "chapitres"
                    : ""}{" "}
                  :
                </p>
                <div className="readingsettings__pages">
                  <div className="readingsettings__cutdetail">
                    {listDaysArray !== []
                      ? listDaysArray.map((oneElement) => (
                          <div
                            key={oneElement.date}
                            className="readingsettings__formline readingsettings__formline--dates"
                          >
                            <p className="readingsettings__subtitle">
                              Le :{" "}
                              {`${
                                new Date(oneElement.date).getDate() < 10
                                  ? "0" +
                                    new Date(oneElement.date).getDate() +
                                    " / "
                                  : new Date(oneElement.date).getDate() + " / "
                              }${
                                new Date(oneElement.date).getMonth() + 1 < 10
                                  ? "0" +
                                    (new Date(oneElement.date).getMonth() + 1) +
                                    " / " +
                                    new Date(oneElement.date).getUTCFullYear()
                                  : new Date(oneElement.date).getMonth() +
                                    1 +
                                    " / " +
                                    new Date(oneElement.date).getUTCFullYear()
                              } : jusqu'${
                                cutOption
                                  ? cutOption === "pages"
                                    ? "à la page"
                                    : "au chapitre"
                                  : ""
                              }`}
                            </p>
                            <input
                              id={`cutCible${oneElement.id}`}
                              name="progressbookcible"
                              className="form__input form__input--pages form__input--cutpages"
                              type="number"
                              defaultValue={
                                cuttingDetail && cuttingDetail.length !== 0
                                  ? cuttingDetail[oneElement.id - 1].pages
                                  : 0
                              }
                            />
                          </div>
                        ))
                      : ""}
                  </div>
                  {cutOption ? (
                    cutOption === "pages" ? (
                      <div className="readingsettings__pages--descript">
                        <br />A modifier - Entre 0 et {data.book_pagesnbr}
                      </div>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}

                  <div
                    className="readingsettings__formline--btn nodecoration readingsettings__formline--cutbtn"
                    onClick={activeCutting}
                  >
                    <BiSave />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="readingsettings__global">
            <div className="readingsettings__global readingsettings__global--part">
              <p>
                <strong>Ma progression :</strong>
              </p>
              <ProgressBar stage={progressSelf} txt="" />
              <p className="readingsettings__subtitle">
              {progressChoice && progressChoice === "chap" ? "Nombre de chapitres lus :" : "Nombre de pages lues :"}
              </p>
              <div className="readingsettings__pages">
                <div className="readingsettings__formline">
                  <input
                    id="progressbook"
                    name="progressbook"
                    className="form__input form__input--pages"
                    type="number"
                    defaultValue={pagesReadedForm}
                  />
                  <div
                    className="readingsettings__formline--btn nodecoration"
                    onClick={changeProgress}
                  >
                    <BiSave />
                  </div>
                </div>
                <div className="readingsettings__pages--descript">
                  {progressChoice && progressChoice === "chap" ? `A modifier - Entre 0 et ${cuttingDetail[cuttingDetail.length - 1].pages}` : `A modifier - Entre 0 et ${data.book_pagesnbr}`}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="readingsettings__global">
          <div className="readingsettings__global readingsettings__global--part">
            <p>
              <strong>
                {cuttingChoice === false
                  ? "Progression attendue :"
                  : "Découpage prévu :"}
              </strong>
            </p>
            {cuttingChoice === false ? (
              <>
                <ProgressBar stage={progressCible} txt="" />
                <p></p>
                <p></p>
              </>
            ) : (
              <div className="readingsettings__cutdetail">
                {listDaysArray !== []
                  ? cuttingDetail.map((oneElement) => (
                      <div
                        key={oneElement.date}
                        className="readingsettings__formline readingsettings__formline--dates"
                      >
                        <p className="readingsettings__subtitle">
                          Le{" "}
                          {`${
                            new Date(oneElement.date).getDate() < 10
                              ? "0" +
                                new Date(oneElement.date).getDate() +
                                " / "
                              : new Date(oneElement.date).getDate() + " / "
                          }${
                            new Date(oneElement.date).getMonth() + 1 < 10
                              ? "0" +
                                (new Date(oneElement.date).getMonth() + 1) +
                                " / " +
                                new Date(oneElement.date).getUTCFullYear()
                              : new Date(oneElement.date).getMonth() +
                                1 +
                                " / " +
                                new Date(oneElement.date).getUTCFullYear()
                          }`}
                          {cutOption
                            ? cutOption === "pages"
                              ? " : jusqu'à la page "
                              : " : jusqu'au chapitre "
                            : " : jusqu'à la page "}
                          <strong>{oneElement.pages}</strong>
                        </p>
                      </div>
                    ))
                  : ""}
                <br />

                {/* <div className="readingsettings__formline readingsettings__formline--dates" key={`idd${oneElement.id}`}>
                  <p>Le</p>
                  <p>Test</p>
                  </div> */}
                {/* listDaysArray !== []
                      ? listDaysArray.map((oneElement) */}
              </div>
            )}
          </div>
          <div className="readingsettings__global readingsettings__global--part">
            <p>
              <strong>Ma progression :</strong>
            </p>
            <ProgressBar stage={progressSelf} txt="" />
            <p>{progressChoice && progressChoice === "chap" ? "Nombre de chapitres lus :" : "Nombre de pages lues :"}</p>
            <div className="readingsettings__pages">
              <div className="readingsettings__formline">
                <input
                  id="progressbook"
                  name="progressbook"
                  className="form__input form__input--pages"
                  type="number"
                  defaultValue={pagesReadedForm}
                />
                <div
                  className="readingsettings__formline--btn nodecoration"
                  onClick={changeProgress}
                >
                  <BiSave />
                </div>
              </div>
              <div className="readingsettings__pages--descript">
                A modifier - Entre 0 et {progressChoice && progressChoice === "chap" ? cuttingDetail[cuttingDetail.length - 1].pages : data.book_pagesnbr}
                <br />
                <br />
              </div>
            </div>
          </div>
          <br />
          <div className="form__book--line">
            <button
              className="form__book rdetails__btn"
              onClick={disjoinSession}
            >
              Quitter cette lecture
            </button>
          </div>
          <br />
        </div>
      )}
    </div>
  );
};

export default ReadingSettings;
