import { useState, useEffect } from "react";
import "./readingdetails.css";
import { Link, useNavigate } from "react-router-dom";
import { BiZoomIn, BiZoomOut } from "react-icons/bi";

const ReadingDetails = ({ dataReading }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState();
  const [isFollowed, setIsFollowed] = useState(false);
  const [followersIsNbr, setFollowersIsNbr] = useState(true);
  const navigate = useNavigate();
  const userToken = localStorage.lecturecommuneUserId;
  const limitDate = new Date(dataReading.session_end_inscription_date);
  const nowDate = new Date();
  let newAvif = "";
  let newWebp = "";
  if (dataReading.book_cover && String(dataReading.book_cover).endsWith(".jpeg")) {
    newAvif = `${dataReading.book_cover.substring(
      0,
      dataReading.book_cover.length - 5
    )}.avif`;
    newWebp = `${dataReading.book_cover.substring(
      0,
      dataReading.book_cover.length - 5
    )}.webp`;
  } else if (String(dataReading.book_cover).endsWith(".jpg")) {
    newAvif = `${dataReading.book_cover.substring(
      0,
      dataReading.book_cover.length - 4
    )}.avif`;
    newWebp = `${dataReading.book_cover.substring(
      0,
      dataReading.book_cover.length - 4
    )}.webp`;
  }
  const changeFollowers = () => {
    setFollowersIsNbr(!followersIsNbr);
  };
  const join = async () => {
    setIsLoading(true);
    if (localStorage.lecturecommuneToken && localStorage.lecturecommuneUserId) {
      const token = localStorage.lecturecommuneToken;
      const joinFetch = await fetch(
        `https://www.lecturecommune.fr/api/reading/join/${dataReading.id}`,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      if (joinFetch.status === 200) {
        setIsLoading(false);
        setIsFollowed(true);
      }
    } else {
      setIsLoading(false);
      setIsFollowed(false);
    }
  };
  const creator = async () => {
    if (localStorage.lecturecommuneToken && localStorage.lecturecommuneUserId) {
      const token = localStorage.lecturecommuneToken;
      dataReading.session_followers.map((detail) => {
        if (Number(detail.id) === Number(userToken)) {
          setIsFollowed(true);
        }
      });
      const getName = await fetch(
        `https://www.lecturecommune.fr/api/user/getname/${dataReading.session_author}`,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      if (getName.status === 200) {
        const resName = await getName.json();
        setName(resName[0].username);
      }
    } else {
      setName(false);
    }
  };
  useEffect(() => {
    creator();
  }, []);
  return (
    <div className="rdetails">
      <div className="rdetails__line">
        {dataReading.book_cover ? (


dataReading.book_cover &&(String(dataReading.book_cover).startsWith(
  "https://www.lecturecommune.fr"
)) ? (
  //
  <picture>
    <source type="image/avif" srcSet={newAvif} />
    <source type="image/webp" srcSet={newWebp} />
    <img
      className="rdetails__img"
      src={dataReading.book_cover}
      alt="Couverture du livre"
    />
  </picture>
) : (
  <img
  className="rdetails__img"
    src={dataReading.book_cover}
    alt="Couverture du livre"
  />
)
          

          // <img
          //   className="rdetails__img"
          //   src={dataReading.book_cover}
          //   alt="Couverture du livre"
          // />
        ) : (
          <div className="rdetails__noimg">
            <p className="rdetails__noimg--title">
              <strong>Couverture non disponible</strong>
            </p>
          </div>
        )}
        <div className="rdetails__column">
          <p className="rdetails__title">
            {dataReading.book_name !== "Titre non disponible" ? (
              <strong>{dataReading.book_name}</strong>
            ) : (
              <i>
                <strong>Titre non disponible</strong>
              </i>
            )}
          </p>
          <p className="rdetails__author">
            {dataReading.book_author !== "Auteur non disponible" ? (
              dataReading.book_author
            ) : (
              <i>Auteur non disponible</i>
            )}
          </p>
        </div>
      </div>
      <div className="separator separator__begin"></div>
      <div className="separator"></div>
      <div className="rdetails__container">
        <div className="rdetails__container--card">
          <div>
            <p className="rdetails__container--title">Lecture créée par</p>
          </div>
          <div>
            {name ? (
              <p
                className="rdetails__container--txt cursorlink"
                onClick={() => {navigate(`/user/${dataReading.session_author}`);}}
              >
                {name}
                <span className="rdetails__container--allwidth">
                  {` `}
                  <BiZoomIn />
                </span>
              </p>
            ) : (
              <p className="rdetails__container--txt">- - -</p>
            )}
          </div>
        </div>
        <div className="rdetails__container--card">
          <div>
            <p className="rdetails__container--title">Début de la lecture</p>
          </div>
          <div>
            <p className="rdetails__container--txt">
              {dataReading.session_begin_date
                ? `${
                    new Date(dataReading.session_begin_date).getDate() < 10
                      ? `0${new Date(dataReading.session_begin_date).getDate()}`
                      : new Date(dataReading.session_begin_date).getDate()
                  } / ${
                    new Date(dataReading.session_begin_date).getMonth() + 1 < 10
                      ? `0${
                          new Date(dataReading.session_begin_date).getMonth() +
                          1
                        }`
                      : new Date(dataReading.session_begin_date).getMonth() + 1
                  } / ${new Date(dataReading.session_begin_date).getFullYear()}`
                : "---"}
            </p>
          </div>
        </div>
        <div className="rdetails__container--card">
          <div>
            <p className="rdetails__container--title">Fin de la lecture</p>
          </div>
          <div>
            <p className="rdetails__container--txt">
              {dataReading.session_end_inscription_date
                ? `${
                    new Date(
                      dataReading.session_end_inscription_date
                    ).getDate() < 10
                      ? `0${new Date(
                          dataReading.session_end_inscription_date
                        ).getDate()}`
                      : new Date(
                          dataReading.session_end_inscription_date
                        ).getDate()
                  } / ${
                    new Date(
                      dataReading.session_end_inscription_date
                    ).getMonth() +
                      1 <
                    10
                      ? `0${
                          new Date(
                            dataReading.session_end_inscription_date
                          ).getMonth() + 1
                        }`
                      : new Date(
                          dataReading.session_end_inscription_date
                        ).getMonth() + 1
                  } / ${new Date(
                    dataReading.session_end_inscription_date
                  ).getFullYear()}`
                : "---"}
            </p>
          </div>
        </div>
        <div className={`rdetails__container--card rdetails__container--${followersIsNbr}`}>
          <div>
            <p className="rdetails__container--title">
              Lecteurs / Lectrices inscrit(e)s
            </p>
          </div>
          <div>
            {!followersIsNbr ? (
              <p className="rdetails__container--txt rdetails__container--followersopened">
                {dataReading.session_followers
                  ? dataReading.session_followers.map((eachFollower) => (
                      <span className="rdetails__container--linkable" onClick={() => {navigate(`/user/${eachFollower.id}`);}}
                        key={eachFollower.id}
                      >{`${eachFollower.name}`}</span>
                    ))
                  : "---"}
                  <span className="rdetails__container--allwidth" onClick={changeFollowers}>
                <BiZoomOut /></span>
              </p>
            ) : (
              <>
                {dataReading.session_followers ? (
                  name ? (
                  <div className="rdetails__container--txt rdetails__container--followersclosed" onClick={changeFollowers}>
                    {`${dataReading.session_followers.length} `}
                      <BiZoomIn />
                  </div>) : (<div className="rdetails__container--txt">
                    {`${dataReading.session_followers.length} `}
                  </div>)
                ) : (
                  "---"
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <div className="rdetails__sub">
        {dataReading.book_pagesnbr ? (
          <div className="rdetails__pagesnbr">
            <p className="rdetails__fourth--title">Nombre de pages :</p>
            <p className="rdetails__pagesnbr--txt">
              {dataReading.book_pagesnbr}
            </p>
          </div>
        ) : (
          ""
        )}
        {dataReading.book_fourth ? (
          <div className="rdetails__fourth">
            <p className="rdetails__fourth--title">4ème de couverture :</p>
            <p className="rdetails__fourth--txt">{dataReading.book_fourth}</p>
          </div>
        ) : (
          ""
        )}
        <div className="rdetails__fourth">
          <p className="rdetails__fourth--title">Résumé de la session :</p>
          <p className="rdetails__fourth--txt">
            {dataReading.session_description}
          </p>
        </div>
      </div>
      {localStorage.lecturecommuneToken &&
      localStorage.lecturecommuneUserId &&
      isFollowed ? (
        <div className="rdetails__command">
          <Link to={`/reading/open/${dataReading.id}`} className="nodecoration">
            <button className="form__book rdetails__btn">Ouvrir</button>
          </Link>
        </div>
      ) : localStorage.lecturecommuneToken &&
        localStorage.lecturecommuneUserId ? (
        (limitDate > nowDate) ? ( isLoading === false ? (
          <div className="rdetails__command">
            <button className="form__book rdetails__btn" onClick={join}>
              Participer
            </button>
          </div>) : ""
        ) : (
          <Link to="/" className="nodecoration">
            <div className="rdetails__end nodecoration">
              <button className="rdetails__btn form__submit nodecoration">
                <span className="nodecoration">Lecture terminée</span>
              </button>
            </div>
          </Link>
        )
      ) : (
        <Link to="/signup" className="nodecoration">
          <div className="rdetails__end nodecoration">
            <button className="rdetails__btn form__submit nodecoration">
              <span className="nodecoration">S'inscrire pour participer</span>
            </button>
          </div>
        </Link>
      )}
    </div>
  );
};

export default ReadingDetails;
