import { useState, useEffect } from "react";
import "./boxreading.css";
import { Link } from "react-router-dom";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { TbAlertTriangle } from "react-icons/tb";
const jsonata = require("jsonata");

const BoxReading = ({ dataReading, limitDate, progress }) => {
  const [progressDurationPercent, setProgressDurationPercent] = useState();
  const [progressDurationPercentBy5, setProgressDurationPercentBy5] =
    useState(-1);
  const [delay, setDelay] = useState();
  let newAvif = "";
  let newWebp = "";
  if (dataReading.book_cover && String(dataReading.book_cover).endsWith(".jpeg")) {
    newAvif = `${dataReading.book_cover.substring(
      0,
      dataReading.book_cover.length - 5
    )}.avif`;
    newWebp = `${dataReading.book_cover.substring(
      0,
      dataReading.book_cover.length - 5
    )}.webp`;
  } else if (String(dataReading.book_cover).endsWith(".jpg")) {
    newAvif = `${dataReading.book_cover.substring(
      0,
      dataReading.book_cover.length - 4
    )}.avif`;
    newWebp = `${dataReading.book_cover.substring(
      0,
      dataReading.book_cover.length - 4
    )}.webp`;
  }
  const checkDelay = async () => {
    if (progress) {
      let beginDateMs = await jsonata(
        `$toMillis("${dataReading.session_begin_date}")`
      );
      let beginDateS = await beginDateMs.evaluate();
      beginDateS = beginDateS / 1000;
      let endDateMs = await jsonata(
        `$toMillis("${dataReading.session_end_inscription_date}")`
      );
      let endDateS = await endDateMs.evaluate();
      endDateS = endDateS / 1000;
      let now = await jsonata("$toMillis($now())");
      now = await now.evaluate();
      now = now / 1000;
      let totalDuration = endDateS - beginDateS;
      let passedDuration = now - beginDateS;
      let passedPercent = Math.round((passedDuration / totalDuration) * 100);
      setProgressDurationPercent(passedPercent);
      let passedPercentBy5 =
        Math.round(((passedDuration / totalDuration) * 100) / 5) * 5;
      setProgressDurationPercentBy5(passedPercentBy5);
    }
    if (limitDate && dataReading.session_begin_date) {
      let beginDateMs = await jsonata(
        `$toMillis("${dataReading.session_begin_date}")`
      );
      let beginDateS = await beginDateMs.evaluate();
      beginDateS = beginDateS / 1000;
      let endDateMs = await jsonata(
        `$toMillis("${dataReading.session_end_inscription_date}")`
      );
      let endDateS = await endDateMs.evaluate();
      endDateS = endDateS / 1000;
      let now = await jsonata("$toMillis($now())");
      now = await now.evaluate();
      now = now / 1000;
      let delayMin = Math.floor((beginDateS - now) / 60);
      let delay2Min = Math.floor((endDateS - now) / 60);
      if (delayMin > 0) {
        delayMin < 60 &&
          setDelay(
            `Début de la lecture dans ${delayMin} minute${
              Math.floor(delayMin > 1) ? "s" : ""
            }`
          );
        delayMin >= 60 &&
          delayMin < 1440 &&
          setDelay(
            `Début de la lecture dans ${Math.floor(delayMin / 60)} heure${
              Math.floor(delayMin / 60) > 1 ? "s" : ""
            }`
          );
        delayMin >= 1440 &&
          delayMin < 4320 &&
          setDelay(
            `Début de la lecture dans ${Math.floor(delayMin / 1440)} jour${
              Math.floor(delayMin / 1440) > 1 ? "s" : ""
            }`
          );
      } else {
        delay2Min < 60 &&
          setDelay(
            `Fin de la lecture dans ${delay2Min} minute${
              Math.floor(delay2Min > 1) ? "s" : ""
            }`
          );
        delay2Min >= 60 &&
          delay2Min < 1440 &&
          setDelay(
            `Fin de la lecture dans ${Math.floor(delay2Min / 60)} heure${
              Math.floor(delay2Min / 60) > 1 ? "s" : ""
            }`
          );
        delay2Min >= 1440 &&
          setDelay(
            `Fin de la lecture dans ${Math.floor(delay2Min / 1440)} jour${
              Math.floor(delay2Min / 1440) > 1 ? "s" : ""
            }`
          );
      }
    }
  };
  useEffect(() => {
    checkDelay();
  }, []);
  return (
    <Link
      className="content__link nodecoration"
      to={`/reading/view/${dataReading.id}`}
    >
      <div className="content__card">
        {dataReading.book_cover ? (
          <div className="content__card--imgcontainer">
            {dataReading.book_cover &&(String(dataReading.book_cover).startsWith(
              "https://www.lecturecommune.fr"
            )) ? (
              //
              <picture>
                <source type="image/avif" srcSet={newAvif} />
                <source type="image/webp" srcSet={newWebp} />
                <img
                  className="content__card--img"
                  src={dataReading.book_cover}
                  alt="Couverture du livre"
                />
              </picture>
            ) : (
              <img
                className="content__card--img"
                src={dataReading.book_cover}
                alt="Couverture du livre"
              />
            )}
            {progressDurationPercentBy5 !== -1 && progressDurationPercent ? (
              <CircularProgressbar
                className="circular"
                value={progressDurationPercent}
                strokeWidth={15}
                text={`${progressDurationPercent}%`}
                background
                // backgroundPadding={-2}
                styles={{
                  path: {
                    stroke: `#ceafaf`,
                    strokeLinecap: "butt",
                  },
                  trail: {
                    stroke: "#ffffff",
                    strokeLinecap: "round",
                  },
                  text: {
                    fill: "black",
                    fontSize: "24px",
                    fontFamily: "Jost",
                    fontWeight: "400",
                  },
                  background: {
                    fill: "#ffffff",
                  },
                }}
              />
            ) : (
              ""
            )}
          </div>
        ) : (
          <div className="content__card--noimg">
            <p className="content__card--noimgtitle">
              <strong>Couverture non disponible</strong>
            </p>
          </div>
        )}
        <p className="content__card--title">
          {dataReading.book_name !== "Titre non disponible" ? (
            <strong>{dataReading.book_name}</strong>
          ) : (
            <i>
              <strong>Titre non disponible</strong>
            </i>
          )}
        </p>
        <p className="content__card--author">
          {dataReading.book_author !== "Auteur non disponible" ? (
            dataReading.book_author
          ) : (
            <i>Auteur non disponible</i>
          )}
        </p>
      </div>
      {limitDate && delay ? (
        <div className="content__card--msg">
          <span className="content__card--span">
            <TbAlertTriangle />
            <strong>{delay}</strong>
          </span>
        </div>
      ) : (
        ""
      )}
    </Link>
  );
};

export default BoxReading;
