import "./contactus.css";
import { useState } from "react";
const validator = require("email-validator");

const ContactUs = () => {
  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState(false);
  const [msg, setMsg] = useState();
  const handleClick = () => {
    setEmail(document.getElementById("email").value);
  };
  const handleClick2 = () => {
    setMsg(document.getElementById("msg").value);
  };
  const sendData = async (e) => {
    e.preventDefault();
    if (emailValid && msg) {
      let dataToSend = {
        email: email,
        msg: msg,
      };
      let response = await fetch('https://www.lecturecommune.fr/api/user/contact', {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataToSend),
      });
      if (response && response.status === 200) {
        alert('Message envoyé !');
        document.location.href = "/";
      } else {
        alert("Impossible d'envoyer le message.");
      }
    } else {
      alert("Données incorrectes. Veuillez réessayer.");
    }
  };
  const checkEmail = () => {
    const testValid = validator.validate(
      document.getElementById("email").value
    );
    setEmailValid(testValid);
  };
  return (
    <main>
      <h2 className="contact__title">Nous contacter</h2><br/><br/>
      <p className="contact__description">Une suggestion ? Une question ? Ce formulaire est à votre disposition.</p>
      <form onSubmit={sendData}>
        <div className="form__div">
          <label>Adresse email :</label>
          <br />
          <input
            id="email"
            name="email"
            className="form__input form__input--mail"
            type="email"
            onChange={handleClick}
            onBlur={checkEmail}
          />
        </div>
        <div className="form__div">
          <label>Votre message :</label>
          <br />
          <div className="form__eye--line">
            <textarea
              id="msg"
              name="msg"
              className="contact__textarea"
              onChange={handleClick2}
            />
          </div>
        </div>
        <input
          className="form__submit nodecoration"
          type="submit"
          value="Envoyer"
          onClick={sendData}
        />
      </form>
    </main>
  );
};

export default ContactUs;
