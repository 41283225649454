import { useEffect } from "react";
import "./adsenseFooter.css";

const AdsenseFooter = ({ slotNbr }) => {
  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {}
  }, []);
  return (
    <div className="footeradsense">
      <div className="footeradsense__container">
        <div className="footeradsense__ad">
          <ins
            className="adsbygoogle footeradsense__responsive"
            style={{ display: "inline-block", height: "90px !important", alignSelf: "center", textAlign: "center" }}
            data-ad-client="ca-pub-6076266123381071"
            data-ad-slot={slotNbr && slotNbr !== "" ? slotNbr : "2797060551"}
            // data-ad-format="horizontal"
            data-full-width-responsive="true"
            // data-full-width-responsive="true"
          ></ins>
        </div>
      </div>
    </div>
  );
};

export default AdsenseFooter;
