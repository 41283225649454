import "./header.css";
import logo from "../../assets/images/logo-80.png";
import Nav from "../nav/Nav";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BiLogIn, BiLogOut } from "react-icons/bi";
import { HiMiniBars3 } from "react-icons/hi2";

function Header() {
  const logout = () => {
    localStorage.removeItem("lecturecommuneToken");
    localStorage.removeItem("lecturecommuneUserId");
    localStorage.lecturecommuneCheckAdmin && localStorage.removeItem("lecturecommuneCheckAdmin");
    document.location.href = "/";
  };
  const [isAuth, setIsAuth] = useState();
  const token = localStorage.lecturecommuneToken;
  async function verifConnexion() {
    if (localStorage.lecturecommuneToken) {
      let testConnect = await fetch(
        "https://www.lecturecommune.fr/api/user/checkauth",
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      if (testConnect.status === 200) {
        let result = await testConnect.json();
        setIsAuth(true);
        localStorage.removeItem("lecturecommuneToken");
        localStorage.removeItem("lecturecommuneUserId");
        if (Number(result.userId) === 1) {
          localStorage.lecturecommuneCheckAdmin && localStorage.removeItem("lecturecommuneCheckAdmin");
          localStorage.setItem("lecturecommuneCheckAdmin", result.status);
        } else {
          localStorage.lecturecommuneCheckAdmin && localStorage.removeItem("lecturecommuneCheckAdmin");
        }
        localStorage.setItem("lecturecommuneToken", result.token);
        localStorage.setItem("lecturecommuneUserId", result.userId);
      } else {
        localStorage.removeItem("lecturecommuneToken");
        localStorage.lecturecommuneCheckAdmin && localStorage.removeItem("lecturecommuneCheckAdmin");
        localStorage.removeItem("lecturecommuneUserId");
        document.location.href = "/";
      }
    } else {
      setIsAuth(false);
    }
  }
  const menuDisplay = () => {
    document.getElementById("nav").className = "nav__display";
  };
  useEffect( () => { verifConnexion() }, []);
  // if (isAuth) {
  return (
    <>
      <header>
      {/* <header className="header__animation1">
        <div className="header__animation2"></div>
        <div className="header__animation3"></div>
        <div className="header__animation4"></div> */}
        <div className={`header`}>
          <div onClick={menuDisplay}>
            <p className="header__bars">
              <HiMiniBars3 />
            </p>
          </div>
          <Link to="/" className="nodecoration">
            <div className="header__line">
              <div className="header__title header__title--minw1">
                <h1 className="header__title--txt">Lecture</h1>
              </div>
              <div className="header__divlogo">
                <img src={logo} className="header__logo" alt="a" />
              </div>
              <div className="header__title header__title--minw2">
                <h1 className="header__title--txt">Commune</h1>
              </div>
            </div>
          </Link>
          {isAuth ? (
            <Link
              className={`nodecoration header__right header__right--${isAuth}`}
              onClick={logout}
            >
              <div className="header__icon--true">
                <div className="header__bars">
                  <BiLogOut />
                  {/* <i className={`fa-solid fa-arrow-right-from-bracket`}></i> */}
                </div>
              </div>
            </Link>
          ) : (
            <Link
              to="/connect"
              className={`nodecoration header__right header__right--${isAuth}`}
            >
              <div className="header__icon--true">
                <div className="header__bars">
                  <BiLogIn />
                  {/* <i className={`fa-solid fa-arrow-right-to-bracket`}></i> */}
                </div>
              </div>
            </Link>
          )}
        </div>
      </header>
      <Nav connectStatus={isAuth} />
    </>
  );
  // } else {
  //   return (
  //     <>
  //       <header>
  //         <div className={`header`}>
  //           <div onClick={menuDisplay}>
  //             <p className="header__bars">
  //               <i className="fa-solid fa-bars"></i>
  //             </p>
  //           </div>
  //           <div className="header__line">
  //             <div className="header__title">
  //               <h1 className="header__title--txt">Lecture</h1>
  //             </div>
  //             <div className="header__divlogo">
  //               <img src={logo} className="header__logo" alt="a" />
  //             </div>
  //             <div className="header__title">
  //               <h1 className="header__title--txt">Commune</h1>
  //             </div>
  //           </div>
  //           <Link
  //             to="/connect"
  //             className={`nodecoration header__right header__right--${isAuth}`}
  //           >
  //             <div className="header__icon--true">
  //               <div className="header__bars">
  //                 <i className="fa-solid fa-arrow-right-to-bracket"></i>
  //               </div>
  //             </div>
  //           </Link>
  //         </div>
  //       </header>
  //       <Nav connectStatus={isAuth} />
  //     </>
  //   );
  // }
}

export default Header;
