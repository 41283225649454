import { useEffect, useState } from "react";
import "./accountDisplay.css";
import Loader from "../loader/loader";
import { BiEdit } from "react-icons/bi";
import { BsFillSendFill } from "react-icons/bs";
import { MdOutlineNoPhotography } from "react-icons/md";

function AccountDisplay() {
  const [imgDisplay, setImgDisplay] = useState();
  const [data, setData] = useState();
  const [editInstaIsOpened, setEditInstaIsOpened] = useState(false);
  const [editValueInsta, setEditValueInsta] = useState("");
  const [editWebSiteIsOpened, setEditWebSiteIsOpened] = useState(false);
  const [editValueWebSite, setEditValueWebSite] = useState("");
  const [editBioIsOpened, setEditBioIsOpened] = useState(false);
  const [editValueBio, setEditValueBio] = useState("");
  const token = localStorage.lecturecommuneToken;
  function instaOpen() {
    if (editInstaIsOpened) {
      setEditInstaIsOpened(false);
    } else {
      setEditInstaIsOpened(true);
      setEditWebSiteIsOpened(false);
      setEditBioIsOpened(false);
    }
  }
  function websiteOpen() {
    if (editWebSiteIsOpened) {
      setEditWebSiteIsOpened(false);
    } else {
      setEditWebSiteIsOpened(true);
      setEditInstaIsOpened(false);
      setEditBioIsOpened(false);
    }
  }
  function bioOpen() {
    if (editBioIsOpened) {
      setEditBioIsOpened(false);
    } else {
      setEditBioIsOpened(true);
      setEditInstaIsOpened(false);
      setEditWebSiteIsOpened(false);
    }
  }
  function changeWebSite() {
    setEditValueWebSite(document.getElementById("website").value);
  }
  function changeBio() {
    setEditValueBio(document.getElementById("bio").value);
  }
  function changeInsta() {
    setEditValueInsta(document.getElementById("insta").value);
  }
  async function editInsta() {
    const newDataToSend = { update: { insta: editValueInsta } };
    const edit = await fetch(
      "https://www.lecturecommune.fr/api/user/profile/edit",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(newDataToSend),
      }
    );
    if (edit.status === 200) {
      dataLast();
      instaOpen();
    } else {
      alert("Erreur : Veuillez réessayer.");
    }
  }
  async function editWebSite() {
    const newDataToSend = { update: { website: editValueWebSite } };
    const edit = await fetch(
      "https://www.lecturecommune.fr/api/user/profile/edit",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(newDataToSend),
      }
    );
    if (edit.status === 200) {
      dataLast();
      websiteOpen();
    } else {
      alert("Erreur : Veuillez réessayer.");
    }
  }
  async function editBio() {
    const newDataToSend = { update: { bio: editValueBio } };
    const edit = await fetch(
      "https://www.lecturecommune.fr/api/user/profile/edit",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(newDataToSend),
      }
    );
    if (edit.status === 200) {
      dataLast();
      bioOpen();
    } else {
      alert("Erreur : Veuillez réessayer.");
    }
  }
  const dataLast = async () => {
    const testConnect = await fetch(
      "https://www.lecturecommune.fr/api/user/profile",
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    if (testConnect.status === 200) {
      const result = await testConnect.json();
      setData(result);
      setImgDisplay(result[0].img);
      setEditValueInsta(result[0].insta);
    }
  };
  const handleSubmit = async () => {
    if (document.getElementById("file").files[0]) {
      const file_box = document.getElementById("file").files[0];
      if (file_box.type === 'image/png' || file_box.type ==='image/jpg' || file_box.type ==='image/jpeg') {
        if (file_box.size < 4000000) {
      let formData = new FormData();
      formData.append("file", document.getElementById("file").files[0]);
      const response = await fetch(
        "https://www.lecturecommune.fr/api/user/profile/editimg",
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + token,
          },
          body: formData,
        }
      );
      const res = await response.json();
      const sendi = `https://www.lecturecommune.fr/assets/profiles/${res}`;
      const newDataToSend2 = { update: { img: sendi } };
      const editimg = await fetch(
        "https://www.lecturecommune.fr/api/user/profile/edit",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify(newDataToSend2),
        }
      );
      if (editimg) {
        // data[0].img = `https://www.lecturecommune.fr/assets/profiles/${res}`;
        // const newd = data;
        // setData(newd);
        dataLast();
      } else {
        alert("Erreur : Veuillez réessayer.");
      }
    } else {
      alert("La taille de votre fichier doit être inférieure à 4Mo")
    }
  } else {
      alert('Votre photo doit être au format jpg ou jpeg / png');
    }
  }
  };
  useEffect(() => {
    dataLast();
  }, []);
  return data ? (
    <main>
      <div className="profile">
        <h2 className="profile__title">Mon Profil</h2>
        <div className="profile__essential">
          <div className="profile__essential--left">
            {imgDisplay ? (
              <img
                className="profile__essential--img"
                src={imgDisplay}
                alt="Avatar"
              />
            ) : (
              <div className="profile__essential--noimg">
                <MdOutlineNoPhotography />
              </div>
            )}
            <form className="profile__essential--form">
              <label htmlFor="file" className="profile__essential--inputlabel">
                <BiEdit />
              </label>
              <input
                className="profile__essential--inputfile"
                id="file"
                type="file"
                name="file"
                onChange={handleSubmit}
              ></input>
            </form>
          </div>
          <div className="profile__essential--infos">
            <div className="profile__essential--username">
              Nom d'utilisateur : <br />
              <strong>{data[0].username}</strong>
            </div>
            <div className="profile__essential--email">
              Adresse email : <br />
              <strong>{data[0].email}</strong>
            </div>
            <div className="profile__essential--nbrcreated">
              {`Lecture créées : `}
              <strong>{data[0].sessions_created.length}</strong>
            </div>
            <div className="profile__essential--nbrcreated">
              {`Lecture suivies : `}
              <strong>{data[0].sessions_followed.length}</strong>
            </div>
          </div>
        </div>
        <div className="profile__essential--infos">
          <div
            className={`profile__openableinsta profile__openableinsta--${editInstaIsOpened}`}
          >
            <div className="profile__detail">
              <BiEdit onClick={instaOpen} className="profile__editbtn" />
              {` Instagram : `}
              <strong>
                {data[0].insta ? (
                  <a
                    href={`https://www.instagram.com/${data[0].insta.substring(
                      1,
                      data[0].insta.length
                    )}`}
                    target="_blank"
                    rel="noreferrer"
                    className="seelink"
                  >
                    {data[0].insta}
                  </a>
                ) : (
                  "- - -"
                )}
              </strong>
            </div>
            <div className="form__edit">
              <input
                id="insta"
                name="insta"
                className="form__input form__input--mail"
                type="text"
                defaultValue={editValueInsta ? editValueInsta : ""}
                placeholder="Votre instagram : @..."
                onChange={changeInsta}
              />
              <button
                className="form__edit--sendbtn profile__editbtn"
                onClick={editInsta}
              >
                <BsFillSendFill />
              </button>
            </div>
          </div>

          <div
            className={`profile__openablewebsite profile__openablewebsite--${editWebSiteIsOpened}`}
          >
            <div className="profile__detail">
              <BiEdit onClick={websiteOpen} className="profile__editbtn" />
              {` Site Web : `}
              <strong>
                {data[0].website ? (
                  <a
                    href={`${data[0].website}`}
                    target="_blank"
                    rel="noreferrer"
                    className="seelink"
                  >
                    {data[0].website}
                  </a>
                ) : (
                  "- - -"
                )}
              </strong>
            </div>

            <div className="form__edit">
              <input
                id="website"
                name="website"
                className="form__input form__input--mail"
                type="text"
                defaultValue={
                  editValueWebSite
                    ? editValueWebSite
                    : data[0].website
                    ? data[0].website
                    : ""
                }
                placeholder="Votre site Web"
                onChange={changeWebSite}
              />
              <button
                className="form__edit--sendbtn profile__editbtn"
                onClick={editWebSite}
              >
                <BsFillSendFill />
              </button>
            </div>
          </div>
          <div className="profile__essential--nbrcreated profile__detail--bio">
            <div className="profile__detail">
              <BiEdit onClick={bioOpen} className="profile__editbtn" />
              Bio :{" "}
            </div>
            <div
              className={`profile__openablebio profile__openablebio--${!editBioIsOpened}`}
            >
              <textarea
                className="profile__detail--areabio"
                readOnly={true}
                value={data[0].bio ? data[0].bio : ""}
              ></textarea>
            </div>
            <div
              className={`profile__openablebio profile__openablebio--edit profile__openablebio--${editBioIsOpened}`}
            >
              <textarea
                id="bio"
                name="bio"
                className="profile__detail--areabio--edit"
                defaultValue={data[0].bio ? data[0].bio : ""}
                onChange={changeBio}
              ></textarea>
              <button
                className="form__edit--biosendbtn profile__editbtn"
                onClick={editBio}
              >
                <BsFillSendFill />
              </button>
            </div>
          </div>
        </div>

        {/* <div className="profile__btn"><input
          className="form__submit nodecoration"
          type="button"
          value="Modifier"
          onClick={console.log('click')}
        /></div> */}
      </div>
    </main>
  ) : (
    <Loader />
  );
}

export default AccountDisplay;
