import { useEffect, useState } from "react";
import Header from "../../components/header/header";
import { useParams, useNavigate } from "react-router-dom";
import ReadingDetails from "../../components/readingdetails/readingdetails";
import LoaderOnly from "../../components/loaderonly/loaderonly";
import Footer from "../../components/footer/footer";
import { BiArrowBack } from 'react-icons/bi';
import TopFooter from "../../components/topFooter/topFooter";

function ViewReading() {
  const readingId = useParams();
  const [data, setData] = useState();
  const navigate = useNavigate();
  const adNbr10 = "2359228238";
  const dataLast = async () => {
    const testConnect = await fetch(
      `https://www.lecturecommune.fr/api/reading/view/${readingId.readingid}`,
      {
        method: "GET",
      }
    );
    if (testConnect.status === 200) {
      const result = await testConnect.json();
      setData(result);
    }
  };
  useEffect( () => { dataLast() }, []);
  return (
    <>
      <Header />
      <main>
        <div className="readingdetails">
          <div className="readingdetails__firstline"><h2 className="readingdetails__title">Lecture</h2><div className="readingdetails__firstline--back" onClick={() => {navigate(-1)}}><BiArrowBack className="cursorlink" /></div></div>
          {data ? <ReadingDetails dataReading={data} /> : <LoaderOnly />}
        </div>
      </main>
      <TopFooter />
      <Footer />
    </>
  );
}

export default ViewReading;
