import { useParams } from 'react-router-dom';
import Footer from '../../components/footer/footer';
import Header from '../../components/header/header';
import ReadingsFollowedBy from '../../components/readingsFollowedBy/readingsFollowedBy';
import TopFooter from '../../components/topFooter/topFooter';

function ReadingFollowedBy() {
    const creatorIdFromUrl = useParams();
  return (
    <>
      <Header />
          <ReadingsFollowedBy creatorId={creatorIdFromUrl}/>
          <TopFooter />
      <Footer />
    </>
  );
}

export default ReadingFollowedBy;