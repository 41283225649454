import "./cgu.css";

const CGUText = () => {
  return (
    <main>
      <div className="legal">
        <div className="legal__div">
          <h2 className="legal__div--title">
            Conditions générales d'utilisation de www.lecturecommune.fr
          </h2>
          <p className="legal__div--txt legal__div--center">
            applicables à compter du 28 juin 2023
          </p>
          <br />
          <p className="legal__div--txt">
            Les présentes conditions générales d'utilisation (dites « CGU ») ont
            pour objet l'encadrement juridique des modalités de mise à
            disposition du site et des services par www.lecturecommune.fr et de
            définir les conditions d’accès et d’utilisation des services par «
            l'Utilisateur ». Les présentes CGU sont accessibles sur le site à la
            rubrique «CGU». Toute inscription ou utilisation du site implique
            l'acceptation sans aucune réserve ni restriction des présentes CGU
            par l’utilisateur. Lors de l'inscription sur le site via le
            Formulaire d’inscription, chaque utilisateur accepte expressément
            les présentes CGU en cochant la case précédant le texte suivant : «
            Je reconnais avoir lu et compris les CGU et je les accepte ». En cas
            de non-acceptation des CGU stipulées dans le présent contrat,
            l'Utilisateur se doit de renoncer à l'accès des services proposés
            par le site. www.lecturecommune.fr se réserve le droit de modifier
            unilatéralement et à tout moment le contenu des présentes CGU.
            L'acceptation des présentes Conditions Générales d'Utilisation
            suppose de la part des Internautes qu'ils jouissent de la capacité
            juridique nécessaire pour cela, ou à défaut qu'ils en aient
            l'autorisation d'un tuteur ou d'un curateur s'ils sont incapables,
            de leur représentant légal s'ils sont mineurs, ou encore qu'ils
            soient titulaires d'un mandat s'ils agissent pour le compte d'une
            personne morale.
          </p>
          <h3>ARTICLE 1 : Les mentions légales</h3>
          <p className="legal__div--txt">
            L'édition du site www.lecturecommune.fr est assurée par
            lecturecommune.fr - Adresse e-mail : admin@lecturecommune.fr.
            L'hébergeur du site www.lecturecommune.fr est la société
            PLANETHOSTER, dont le siège social est situé au 4416 Louis B. Mayer,
            H7P 0G1 LAVAL (GRAND MONTRÉAL), CANADA, avec le numéro de téléphone
            : +1 5148021644.
          </p>
          <h3>ARTICLE 2 : Accès au site</h3>
          <p className="legal__div--txt">
            Le site www.lecturecommune.fr permet à l'Utilisateur un accès
            gratuit aux services suivants : création de lectures communes,
            participation à des lectures communes, et échanges avec les autres
            utilisateurs. Le site est accessible gratuitement en tout lieu à
            tout Utilisateur ayant un accès à Internet. Tous les frais supportés
            par l'Utilisateur pour accéder au service (matériel informatique,
            logiciels, connexion Internet, etc.) sont à sa charge. L’Utilisateur
            non membre n'a pas accès aux services réservés. Pour cela, il doit
            s’inscrire en remplissant le formulaire. En acceptant de s’inscrire
            aux services réservés, l’Utilisateur membre s’engage à fournir des
            informations sincères et exactes concernant son état civil et ses
            coordonnées, notamment son adresse email. Pour accéder aux services,
            l’Utilisateur doit ensuite s'identifier à l'aide de son identifiant
            et de son mot de passe qu'il aura fourni lors de son inscription.
            Tout Utilisateur membre régulièrement inscrit pourra également
            solliciter sa désinscription en écraivant à admin@lecturecommune.fr.
            Celle-ci sera effective dans un délai raisonnable. Tout événement dû
            à un cas de force majeure ayant pour conséquence un
            dysfonctionnement du site ou serveur et sous réserve de toute
            interruption ou modification en cas de maintenance, n'engage pas la
            responsabilité de www.lecturecommune.fr. Dans ces cas, l’Utilisateur
            accepte ainsi ne pas tenir rigueur à l’éditeur de toute interruption
            ou suspension de service, même sans préavis. L'Utilisateur a la
            possibilité de contacter le site par messagerie électronique à
            l’adresse email de l’éditeur communiqué à l’ARTICLE 1.
          </p>
          <h3>ARTICLE 3 : Collecte des données</h3>
          <p className="legal__div--txt">
            Le site assure à l'Utilisateur une collecte et un traitement
            d'informations personnelles dans le respect de la vie privée
            conformément à la loi n°78-17 du 6 janvier 1978 relative à
            l'informatique, aux fichiers et aux libertés. En vertu de la loi
            Informatique et Libertés, en date du 6 janvier 1978, l'Utilisateur
            dispose d'un droit d'accès, de rectification, de suppression et
            d'opposition de ses données personnelles. L'Utilisateur exerce ce
            droit : par mail à l'adresse email admin@lecturecommune.fr
          </p>
          <h3>ARTICLE 4 : Propriété intellectuelle</h3>
          <p className="legal__div--txt">
            Les marques, logos, signes ainsi que tous les contenus du site
            (textes, images, son...) font l'objet d'une protection par le Code
            de la propriété intellectuelle et plus particulièrement par le droit
            d'auteur. L'Utilisateur doit solliciter l'autorisation préalable du
            site pour toute reproduction, publication, copie des différents
            contenus. Il s'engage à une utilisation des contenus du site dans un
            cadre strictement privé, toute utilisation à des fins commerciales
            et publicitaires est strictement interdite. Toute représentation
            totale ou partielle de ce site par quelque procédé que ce soit, sans
            l’autorisation expresse de l’exploitant du site Internet
            constituerait une contrefaçon sanctionnée par l’article L 335-2 et
            suivants du Code de la propriété intellectuelle. Il est rappelé
            conformément à l’article L122-5 du Code de propriété intellectuelle
            que l’Utilisateur qui reproduit, copie ou publie le contenu protégé
            doit citer l’auteur et sa source. Le contenu importé (textes,
            images, données...) reste la propriété de leurs auteurs et n'est
            utilisé que pour l'identification des ouvrages. Ces données sont
            principalement récupérées via l'API BNF (Bibliothèque Nationale de
            France : https://api.bnf.fr/fr/) et, dans une moindre mesure, via l'API OpenLibrary (https://openlibrary.org/developers/api).
          </p>
          <h3>ARTICLE 5 : Responsabilité</h3>
          <p className="legal__div--txt">
            Les sources des informations diffusées sur le site
            www.lecturecommune.fr sont réputées fiables mais le site ne garantit
            pas qu’il soit exempt de défauts, d’erreurs ou d’omissions. Les
            informations communiquées sont présentées à titre indicatif et
            général sans valeur contractuelle. Malgré des mises à jour
            régulières, le site www.lecturecommune.fr ne peut être tenu
            responsable de la modification des dispositions administratives et
            juridiques survenant après la publication. De même, le site ne peut
            être tenue responsable de l’utilisation et de l’interprétation de
            l’information contenue dans ce site. L'Utilisateur s'assure de
            garder son mot de passe secret. Toute divulgation du mot de passe,
            quelle que soit sa forme, est interdite. Il assume les risques liés
            à l'utilisation de son identifiant et mot de passe. Le site décline
            toute responsabilité. Le site www.lecturecommune.fr ne peut être
            tenu pour responsable d’éventuels virus qui pourraient infecter
            l’ordinateur ou tout matériel informatique de l’Internaute, suite à
            une utilisation, à l’accès, ou au téléchargement provenant de ce
            site. La responsabilité du site ne peut être engagée en cas de force
            majeure ou du fait imprévisible et insurmontable d'un tiers.
          </p>
          <h3>ARTICLE 6 : Liens hypertextes</h3>
          <p className="legal__div--txt">
            Des liens hypertextes peuvent être présents sur le site.
            L’Utilisateur est informé qu’en cliquant sur ces liens, il sortira
            du site www.lecturecommune.fr. Ce dernier n’a pas de contrôle sur
            les pages web sur lesquelles aboutissent ces liens et ne saurait, en
            aucun cas, être responsable de leur contenu.
          </p>
          <h3>ARTICLE 7 : Cookies</h3>
          <p className="legal__div--txt">
            L’Utilisateur est informé que lors de ses visites sur le site, un
            cookie peut s’installer automatiquement sur son logiciel de
            navigation. Les cookies sont de petits fichiers stockés
            temporairement sur le disque dur de l’ordinateur de l’Utilisateur
            par votre navigateur et qui sont nécessaires à l’utilisation du site
            www.lecturecommune.fr. Les cookies ne contiennent pas d’information
            personnelle et ne peuvent pas être utilisés pour identifier
            quelqu’un. Un cookie contient un identifiant unique, généré
            aléatoirement et donc anonyme. Certains cookies expirent à la fin de
            la visite de l’Utilisateur, d’autres restent. L’information contenue
            dans les cookies est utilisée pour améliorer le site
            www.lecturecommune.fr. En naviguant sur le site, L’Utilisateur les
            accepte. L’Utilisateur doit toutefois donner son consentement quant
            à l’utilisation de certains cookies. A défaut d’acceptation,
            l’Utilisateur est informé que certaines fonctionnalités ou pages
            risquent de lui être refusées. L’Utilisateur pourra désactiver ces
            cookies par l’intermédiaire des paramètres figurant au sein de son
            logiciel de navigation.
          </p>
          <h3>ARTICLE 8 : Publication par l’Utilisateur</h3>
          <p className="legal__div--txt">
            Le site permet aux membres de publier les contenus suivants :
            lectures, messages privés, messages à destination des autres
            lecteurs, état d'avancement de sa lecture. Dans ses publications, le
            membre s’engage à respecter les règles de la Netiquette (règles de
            bonne conduite de l’internet) et les règles de droit en vigueur. Le
            site peut exercer une modération sur les publications et se réserve
            le droit de refuser leur mise en ligne, sans avoir à s’en justifier
            auprès du membre. Le membre reste titulaire de l’intégralité de ses
            droits de propriété intellectuelle. Mais en publiant une publication
            sur le site, il cède à l'éditeur le droit non exclusif et gratuit de
            représenter, reproduire, adapter, modifier, diffuser et distribuer
            sa publication, directement ou par un tiers autorisé, dans le monde
            entier, sur tout support (numérique ou physique), pour la durée de
            la propriété intellectuelle. Le Membre cède notamment le droit
            d'utiliser sa publication sur internet et sur les réseaux de
            téléphonie mobile. L'éditeur s'engage à faire figurer le nom du
            membre à proximité de chaque utilisation de sa publication. Tout
            contenu mis en ligne par l'Utilisateur est de sa seule
            responsabilité. L'Utilisateur s'engage à ne pas mettre en ligne de
            contenus pouvant porter atteinte aux intérêts de tierces personnes.
            Tout recours en justice engagé par un tiers lésé contre le site sera
            pris en charge par l'Utilisateur. Le contenu de l'Utilisateur peut
            être à tout moment et pour n'importe quelle raison supprimé ou
            modifié par le site, sans préavis.
          </p>
          <h3>ARTICLE 9 : Droit applicable et juridiction compétente</h3>
          <p className="legal__div--txt">
            La législation française s'applique au présent contrat. En cas
            d'absence de résolution amiable d'un litige né entre les parties,
            les tribunaux français seront seuls compétents pour en connaître.
            Pour toute question relative à l’application des présentes CGU, vous
            pouvez joindre l’éditeur aux coordonnées inscrites à l’ARTICLE 1.
          </p>
          <br />
          <p className="legal__div--txt legal__div--end">CGU réalisées sur http://legalplace.fr/</p>
        </div>
      </div>
    </main>
  );
};

export default CGUText;
