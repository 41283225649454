import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import UserPreferences from '../../components/userpreferences/userpreferences';
import TopFooter from '../../components/topFooter/topFooter';

function PersonnalSettings() {
  return (
    <>
      <Header />
      <UserPreferences />
      <TopFooter />
      <Footer />
    </>
  );
}

export default PersonnalSettings;