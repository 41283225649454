import AdsenseFooter from "../adsenseFooter/adsenseFooter";
import "./topFooter.css";

const TopFooter = () => {
  const adNbr10 = "2359228238";
  // const [data, setData] = useState();
  // const dataLast = async () => {
  //   const testConnect = await fetch(
  //     "https://www.lecturecommune.fr/api/reading/count",
  //     {
  //       method: "GET",
  //     }
  //   );
  //   if (testConnect.status === 200) {
  //     const result = await testConnect.json();
  //     setData(result);
  //   }
  // };
  // useEffect(() => dataLast, []);
  return (
    <div className="topfooter">
      <div className="topfooter__ad">
        <div className="topfooter__ad--container">
          <AdsenseFooter slotNbr={adNbr10} />
        </div>
      </div>
    </div>
  );
};

export default TopFooter;
