import { useState } from "react";
import './reinitDemande.css';
import configUrl from "../../assets/config";

const ReinitDemand = () => {
  const [email, setEmail] = useState("");
  const [isSent, setIsSent] = useState(false);
  const [isSentOk, setIsSentOk] = useState(false);
  const handleClick = () => {
    setEmail(document.getElementById("email").value);
  };
  const sendData = async (e) => {
    e.preventDefault();
    if (email) {
      let dataToSend = {
        email: email,
      };
      let response = await fetch(`${configUrl.api}user/reinitpass`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataToSend),
      });
      if (response && response.status === 200) {
        setIsSent(true);
        setIsSentOk(true);
      } else if (response && response.status !== 200) {
        alert("Erreur : veuillez réessayer.")
        setIsSent(true);
        setIsSentOk(false);
      }
    } else {
      alert("Veuillez renseigner votre adresse mail");
    }
  };
  return (
    <main className="main__connect">
        <h2 className="connexion__title">Mot de pass oublié</h2><br />
      <p className="reinit__p">Pour réinitialiser votre mot de passe, veuillez rensigner votre adresse email et valider.</p>
      <form onSubmit={sendData}>
        <div className="form__div">
          <label>Adresse email :</label>
          <br />
          <input
            id="email"
            name="email"
            className="form__input form__input--mail"
            type="email"
            onChange={handleClick}
          />
          <div className="form__input--email form__input--email--true">
            {/* Adresse email incorrecte */}
          </div>
        </div>
        
        <input
          className="form__submit nodecoration"
          type="submit"
          value="Réinitialiser"
          onClick={sendData}
        />
      </form><br />
      {(isSent && isSentOk) && <p className="reinit__p">Un mail vous a été envoyé. Veuillez suivre le lien pour réinitialiser votre mot de passe.</p>}
      
      
    </main>
  );
};

export default ReinitDemand;
