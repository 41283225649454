import Error404 from '../../components/error404/error404';
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import TopFooter from '../../components/topFooter/topFooter';

function Page404() {
  return (
    <>
      <Header />
      <Error404 />
      <TopFooter />
      <Footer />
    </>
  );
}

export default Page404;