import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import Reading from "../../components/reading/reading";
import Error404 from "../../components/error404/error404";
import Loader from "../../components/loader/loader";
import TopFooter from "../../components/topFooter/topFooter";

function OpenReading() {
  const readingId = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState();
  const [isUserAuthorized, setIsUserAuthorized] = useState(false);
  const userToken = localStorage.lecturecommuneUserId;
  const token = localStorage.lecturecommuneToken;
  const dataFetch = async () => {
    setIsLoading(true);
    const testConnect = await fetch(
      `https://www.lecturecommune.fr/api/reading/open/${readingId.reading}`,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    if (testConnect.status === 200) {
      const result = await testConnect.json();
      // await result.session_followers.map((detailUsers) => {
      //   if (detailUsers.id == userToken) {
          setIsUserAuthorized(true);
          setData(result);
        // }
      // });
      // if(!isUserAuthorized) {
      //       const testConnect2 = await fetch(
      //         `https://www.lecturecommune.fr/api/user/checkadmin`,
      //         {
      //           method: "post",
      //           headers: {
      //             Authorization: "Bearer " + token,
      //           },
      //         }
      //       );
      //       if (testConnect2.status === 200) {
      //         setIsUserAuthorized(true);
      //         setData(result);
      //       }}
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    dataFetch();
  }, []);
  return (
    <>
      <Header />
      {isLoading ? (
        <Loader />
      ) : data && isUserAuthorized ? (
        <Reading dataOpen={data} />
      ) : !data && !isUserAuthorized ? (
        <Error404 />
      ) : (
        <Loader />
      )}
      <TopFooter />
      <Footer />
    </>
  );
}

export default OpenReading;
