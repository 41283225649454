import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { BiArrowBack } from "react-icons/bi";
import "./adminbiblioupdate.css";
import Header from "../header/header";
import Footer from "../footer/footer";

function AdminBiblioUpdate() {
  const [dataUsers, setDataUsers] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [catalog, setCatalog] = useState([]);
  const [seeAll, setSeeAll] = useState(true);
  const navigate = useNavigate();
  const getMeta = (url, cb) => {
    const img = new Image();
    img.onload = () => cb(null, img);
    img.onerror = (err) => cb(err);
    img.src = url;
  };
  const first = async () => {
    setIsLoading(true);
    if (!localStorage.lecturecommuneCheckAdmin) {
      navigate("/");
    }
    const token = localStorage.lecturecommuneToken;
    const testConnect = await fetch(
      `https://www.lecturecommune.fr/api/user/checkadmin`,
      {
        method: "post",
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    if (testConnect.status === 200) {
      const fetchUsers = await fetch(
        `https://www.lecturecommune.fr/api/mybooks/getalladminmustupdate`,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      if (fetchUsers.status === 200) {
        if (!seeAll) {
          let result2 = await fetchUsers.json();
          // setDataUsers(result2);
          let newArrayData = [];
          result2.map((oneResult) => {
            getMeta(oneResult.cover, (err, img) => {
              // console.log(img.naturalWidth, img.naturalHeight);
              if (img.naturalHeight === 138 && img.naturalWidth === 92) {
                newArrayData.push(oneResult);
              }
            });
          });
          setDataUsers(newArrayData);
        } else {
          let result2 = await fetchUsers.json();
          setDataUsers(result2);
        }
        //
        const getCatalog = await fetch(
          `https://www.lecturecommune.fr/api/reading/getallbooks`,
          {
            method: "GET",
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        if (getCatalog.status === 200) {
          const resultCatalog = await getCatalog.json();
          let newArray = [];
          resultCatalog.map((book) => {
            newArray.push(book.isbn);
          });
          setCatalog(newArray);
        }
        //
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } else {
      navigate("/error");
    }
  };
  useEffect(() => {
    first();
  }, []);
  return (
    <>
      <Header />
      <main>
        {!isLoading && (
          <>
            <div className="readingdetails__firstline">
              <h2 className="bookcreated__title">Livres à modifier</h2>
              <div
                className="readingdetails__firstline--back"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <BiArrowBack className="cursorlink" />
              </div>
            </div>
            <h3 className="bookcreated__title adminusers__title">
              {dataUsers && `${dataUsers.length} livres`}
            </h3>
            <div>
              <p
                onClick={() => {
                  setSeeAll(!seeAll);
                  first();
                }}
              >
                {seeAll ? "Tous" : "BNF only"}
              </p>
            </div>
            <div className="adminusers">
              {dataUsers && (
                <div className="adminusers__line">
                  <p className="adminusers__line--1">
                    <strong>Id</strong>
                  </p>
                  <p className="adminusers__line--2">
                    <strong>User Id</strong>
                  </p>
                  <p className="adminusers__line--2">
                    <strong>ISBN</strong>
                  </p>
                  <p className="adminusers__line--2">
                    <strong>Titre</strong>
                  </p>
                  <p className="adminusers__line--5">
                    <strong>Cover</strong>
                  </p>
                  <p className="adminusers__line--5">
                    <strong>Catalog</strong>
                  </p>
                  <p className="adminusers__line--5">
                    <strong>Fourth</strong>
                  </p>
                  <p className="adminusers__line--5">
                    <strong>Price</strong>
                  </p>
                  <p className="adminusers__line--5">
                    <strong>Pages nbr</strong>
                  </p>
                  <p className="adminusers__line--5">
                    <strong>Update</strong>
                  </p>
                </div>
              )}
              {dataUsers &&
                dataUsers.map((user) => (
                  <div className="adminusers__line" key={user.id}>
                    <p
                      className="adminusers__line--1"
                      style={{
                        height: 110,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >{`${user.id}`}</p>
                    <p
                      className="adminusers__line--2"
                      style={{
                        height: 110,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >{`${user.user}`}</p>
                    <p
                      className="adminusers__line--2"
                      style={{
                        height: 110,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >{`${user.isbn}`}</p>
                    <p
                      className="adminusers__line--2"
                      style={{
                        height: 110,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >{`${user.title}`}</p>
                    {user.cover && user.cover !== "" ? (
                      <div
                        className="adminusers__line--2"
                        style={{
                          height: 110,
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          style={{ width: 65, height: 100 }}
                          src={user.cover}
                        />
                      </div>
                    ) : (
                      <p
                        className="adminusers__line--5"
                        style={{ height: 110 }}
                      >
                        X
                      </p>
                    )}
                    <p
                      className="adminusers__line--5"
                      style={{
                        height: 110,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {catalog.includes(user.isbn) ? "Présent" : ""}
                    </p>
                    <p
                      className="adminusers__line--5"
                      style={{
                        height: 110,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      {user.fourth ? user.fourth : "X"}
                    </p>
                    <p
                      className="adminusers__line--5"
                      style={{
                        height: 110,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {user.price ? `${user.price} €` : "X"}
                    </p>
                    <p
                      className="adminusers__line--5"
                      style={{
                        height: 110,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {user.nbrpages ? user.nbrpages : "X"}
                    </p>
                    <p
                      className="adminusers__line--5"
                      style={{
                        height: 110,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Link to={`/adminview/biblioupdate/${user.isbn}`}>
                        Update
                      </Link>
                    </p>
                  </div>
                ))}
            </div>
          </>
        )}
      </main>
      <Footer />
    </>
  );
}

export default AdminBiblioUpdate;
