import { useEffect } from "react";
import { isMobile, osName } from "react-device-detect";
import logo from "../../assets/images/logo-80.png";
import insta from "../../assets/images/instaLogo.png";
import web from "../../assets/images/webLogo.png";

function GetApp() {
  const initialFunction = async () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const dataToSend = {
      date: new Date(Date.now()),
      mobile: isMobile,
      platform: osName,
      useragent: navigator.userAgent,
    };
    await fetch(`https://www.lecturecommune.fr/api/user/addgetapp`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dataToSend),
    });
    // const result = await fetchGetApp.json();
    // console.log(result);
    if (/android/i.test(userAgent)) {
      window.location.replace(
        "https://play.google.com/store/apps/details?id=com.lecturecommune.app"
      );
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.replace("https://apps.apple.com/app/id6463268154");
    }
  };

  useEffect(() => {
    initialFunction();
  }, []);
  return (
    <div
      style={{
        height: window.innerHeight,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: 30,
        width: "100vw",
      }}
    >
      <img src={logo} />
      <span style={{ fontFamily: "Jost", fontSize: 20 }}>Lecture Commune</span>
      <a
        style={{
          width: "80vw",
          maxWidth: 400,
          textDecoration: "none",
          color: "black",
          fontFamily: "Jost",
          display: "flex",
          flexDirection: "column",
          gap: 15,
        }}
        href="https://www.instagram.com/lecturecommune"
      >
        <div
          style={{
            // width: "80vw",
            // maxWidth: 300,
            border: "1px solid lightgrey",
            padding: 5,
            borderRadius: 5,
            display: "flex",
            flexDirection: "row",
            gap: 20,
            alignItems: "center",
            minHeight: 45,
            justifyContent: "center",
          }}
        >
          <img src={insta} />
          <span>Suivez-vous sur Instagram</span>
        </div>
      </a>
      <a
        style={{
          width: "80vw",
          maxWidth: 400,
          textDecoration: "none",
          color: "black",
          fontFamily: "Jost",
          display: "flex",
          flexDirection: "column",
          gap: 15,
        }}
        href="https://www.lecturecommune.fr"
      >
        <div
          style={{
            border: "1px solid lightgrey",
            padding: 5,
            borderRadius: 5,
            display: "flex",
            flexDirection: "row",
            gap: 20,
            alignItems: "center",
            minHeight: 45,
            justifyContent: "center",
          }}
        >
          <img src={web} style={{ width: 40 }} />
          <span>Visitez notre site Web</span>
        </div>
      </a>
      {/* <Header />
      <MainPage />
      <TopFooter />
      <Footer /> */}
    </div>
  );
}

export default GetApp;
