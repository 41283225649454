import Footer from '../../components/footer/footer';
import Header from '../../components/header/header';
import MainPage from '../../components/mainpage/mainpage';
import TopFooter from '../../components/topFooter/topFooter';

function Home() {
  return (
    <>
      <Header />
      <MainPage />
      <TopFooter />
      <Footer />
    </>
  );
}

export default Home;