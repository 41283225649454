import { useEffect, useState } from "react";
import { TiDelete } from "react-icons/ti";
import "../readingsCreated/readingsCreated.css";
import Loader from "../loader/loader";
import ReadingShow from "../readingShow/readingShow";
import Scheduler from "../scheduler/scheduler";

function GetAllSessions() {
  const [data, setData] = useState();
  const [initialData, setInitialData] = useState();
  const [nbrFiltered, setNbrFiltered] = useState(-1);
  const [view, setView] = useState("list");
  const goList = () => {
    view !== "list" && setView("list");
  };
  const goScheduler = () => {
    view !== "scheduler" && setView("scheduler");
  };
  const dataLast = async () => {
    const testConnect = await fetch(
      "https://www.lecturecommune.fr/api/reading/all",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (testConnect.status === 200) {
      const result = await testConnect.json();
      setData(result);
      setInitialData(result);
    }
  };
  async function filterSearch() {
    let filterFound = [];
    if (document.getElementById("search").value !== "") {
      initialData.map((oneReading) => {
        const removeAccents = (str) =>
          str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
          let authorWhitoutAccent = removeAccents(String(oneReading.book_author).toLowerCase());
          let bookNameWhithoutAccent = removeAccents(String(oneReading.book_name).toLowerCase());
          let searchWithoutAccent = removeAccents(document.getElementById("search").value.toLowerCase());
        if (
          authorWhitoutAccent.includes(searchWithoutAccent) || bookNameWhithoutAccent.includes(searchWithoutAccent)
        ) {
          filterFound.push(oneReading);
        }
        if (filterFound !== []) {
          setData(filterFound);
          setNbrFiltered(filterFound.length);
        } else {
          setNbrFiltered(0);
        }
      });
    } else {
      setNbrFiltered(-1);
      setData(initialData);
    }
  }
  useEffect(() => {
    dataLast();
  }, []);
  return data ? (
    <main>
      <div className="readinginprocess">
        <h2 className="readinginprocess__title">Toutes les lectures</h2>
        <div className="readinginprocess__line">
          <div
            className={`scheduler__link readinginprocess__line--element readinginprocess__line--left ${
              view === "list" ? "readinginprocess__line--selected" : ""
            }`}
            onClick={goList}
          >
            Liste
          </div>

          <div
            className={`scheduler__link readinginprocess__line--element readinginprocess__line--right ${
              view === "scheduler" ? "readinginprocess__line--selected" : ""
            }`}
            onClick={goScheduler}
          >
            Calendrier
          </div>
        </div>
        {view === "list" && (
          <div className="user__searchline">
            <div className="user__search">
              <input
                id="search"
                name="search"
                className="user__search--input"
                type="text"
                onChange={filterSearch}
                placeholder="Recherche..."
              />
              <TiDelete
                className="user__search--delete"
                onClick={() => {
                  document.getElementById("search").value = "";
                  filterSearch();
                }}
              />
            </div>
          </div>
        )}
        <div className="readinginprocess__list scheduler__table">
          {data && view === "list" ? (
            data.map((data2) => (
              <ReadingShow key={data2.id} reading={data2} limitDate={true} />
            ))
          ) : (
            <Scheduler readings={data} />
          )}
        </div>
      </div>
    </main>
  ) : (
    <Loader />
  );
}

export default GetAllSessions;
