import { useEffect, useState } from "react";
import Footer from "../footer/footer";
import Header from "../header/header";
import TopFooter from "../topFooter/topFooter";
import { useNavigate, useParams } from "react-router-dom";

const AdminEditOneSession = () => {
  const [sessions, setSessions] = useState([]);
  const [thisSession, setThisSession] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [allbooks, setAllbooks] = useState([]);
  const [bookFound, setBookFound] = useState();
  const [isbn, setIsbn] = useState();
  const navigate = useNavigate();
  const id = useParams();

  const first = async () => {
    setIsLoading(true);
    if (!localStorage.lecturecommuneCheckAdmin) {
      navigate("/");
    }
    const token = localStorage.lecturecommuneToken;
    const testConnect = await fetch(
      `https://www.lecturecommune.fr/api/user/checkadmin`,
      {
        method: "post",
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    if (testConnect.status === 200) {
      const get2 = await fetch(
        `https://www.lecturecommune.fr/api/reading/getallbooks`,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      if (get2.status === 200) {
        const result2 = await get2.json();
        setAllbooks(result2);
      } else {
        navigate("/error");
      }
      const getAllBooks = await fetch(
        `https://www.lecturecommune.fr/api/reading/alladmin`,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      if (getAllBooks.status === 200) {
        const result = await getAllBooks.json();
        setSessions(result);
        result.map((onesession) => {
          if (onesession.id === Number(id.id)) {
            setThisSession(onesession);
            setIsbn({
              target: { value: `${onesession.session_followers[0].isbn}` },
            });
            setIsLoading(false);
          }
        });
      } else {
        navigate("/error");
      }
    } else {
      navigate("/error");
    }
  };

  useEffect(() => {
    first();
  }, []);

  useEffect(() => {
    if (allbooks.length > 0) {
      let resultNbr = 0;
      allbooks.map((onebook) => {
        if (Number(onebook.isbn) === Number(isbn.target.value)) {
          resultNbr += 1;
          setBookFound(onebook);
        }
      });
      if (resultNbr === 0) {
        setBookFound();
      }
    }
  }, [isbn]);

  return (
    <>
      <Header />
      <div
        style={{
          marginTop: 100,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <p>Session actuelle</p>
        <br />
        {!isLoading && thisSession && (
          <div>
            <div>
              <p>
                <strong>Titre</strong> : {thisSession.book_name}
              </p>
            </div>
            <div>
              <p>
                <strong>Auteur</strong> : {thisSession.book_author}
              </p>
            </div>
            <div>
              <p>
                <strong>Résumé</strong> : {thisSession.book_fourth}
              </p>
            </div>
            <div>
              <p>
                <strong>Couv</strong> :
              </p>
              {thisSession.book_cover && (
                <img height={200} src={thisSession.book_cover} />
              )}
            </div>
            <div>
              <p>Pages : {thisSession.book_pagesnbr}</p>
            </div>
          </div>
        )}
        <br />
        <div>
          <input
            id="nbr"
            name="nbr"
            onChange={setIsbn}
            value={isbn ? isbn.target.value : ""}
          />
        </div>
        <br />
        {!isLoading && bookFound && (
          <div>
            <br />
            <p>Nouvelles données</p>
            <br />
            <div>
              <p>
                <strong>Titre</strong> : {bookFound.name}
              </p>
            </div>
            <div>
              <p>
                <strong>Auteur</strong> : {bookFound.book_author}
              </p>
            </div>
            <div>
              <p>
                <strong>Résumé</strong> : {bookFound.fourth}
              </p>
            </div>
            <div>
              <p>
                <strong>Couv</strong> :
              </p>
              {thisSession.book_cover && (
                <img height={200} src={bookFound.cover} />
              )}
            </div>
            <div>
              <p>Pages : {bookFound.nbrpages}</p>
            </div>
            <button
              style={{ width: 100, height: 30 }}
              type="button"
              onClick={async () => {
                let toSend = {
                  title: bookFound.name,
                  author: bookFound.book_author,
                  cover: bookFound.cover,
                  fourth: bookFound.fourth,
                  pages: bookFound.nbrpages,
                };

                const token = localStorage.lecturecommuneToken;
                const updateData = await fetch(
                  `https://www.lecturecommune.fr/api/reading/editsessiondata/${id.id}`,
                  {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: "Bearer " + token,
                    },
                    body: JSON.stringify(toSend),
                  }
                );
                if (updateData.status === 200) {
                  alert("Maj OK");
                } else {
                  alert("Erreur");
                }
              }}
            >
              Valider
            </button>
          </div>
        )}
      </div>
      <TopFooter />
      <Footer />
    </>
  );
};

export default AdminEditOneSession;
