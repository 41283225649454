import { useState } from "react";
import { Link } from "react-router-dom";
import "./createDetailSession.css";
import LoaderOnly from "../loaderonly/loaderonly";

function CreateDetailSession({ dataBook, isbn, imgSrc, auth, setStage, setState }) {
  const [isCreated, setIsCreated] = useState();
  const [isLoading, setIsLoading] = useState();
  const createReading = async () => {
    if (
      !document.getElementById("desc").value ||
      !document.getElementById("begin").value ||
      !document.getElementById("limit").value
    ) {
      alert("Donnée(s) manquante(s)");
    } else {
      let datebegin = new Date(document.getElementById("begin").value);
      let dateend = new Date(document.getElementById("limit").value);
      let datenow = new Date();
      let beginVerif = datebegin > datenow;
      let endVerif = dateend > datebegin;
      if (beginVerif && endVerif) {
        document.getElementById("formcreatebtn").classList.add("formcreate__btnvalidatenone");
      let token = localStorage.lecturecommuneToken;
      setIsLoading(true);
      let dataCreate = {
        session_begin_date: document.getElementById("begin").value,
        session_end_inscription_date: document.getElementById("limit").value,
        session_description: document.getElementById("desc").value,
        book_name: dataBook.title ? dataBook.title : "Titre non disponible",
        book_author: auth ? auth : "Auteur non disponible",
        book_cover: imgSrc ? imgSrc : "",
        book_fourth: dataBook.book_fourth ? dataBook.book_fourth : '',
        book_pagesnbr: dataBook.book_pagesnbr ? dataBook.book_pagesnbr : '',
        book_isbn: isbn,
      };
      let create = await fetch(
        `https://www.lecturecommune.fr/api/reading/add`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify(dataCreate),
        }
      );
      if (create.status === 200) {
        setIsCreated(true);
        setIsLoading(false);
        setStage(100);
        setState('Lecture créée');
      } else {
        setIsLoading(false);
        setIsCreated(false);
        setStage(404);
        setState('Erreur');
        alert("Impossible de créer la lecture");
      }
    } else {
      alert('Dates incorrectes. Veuillez réessayer.')
    }
  }};
  return (
    <>
      {isLoading ? (
        <LoaderOnly />
      ) : isCreated ? (
        <div className="bookcreated">
          <h3>Félicitations !</h3><br />
          <p>Votre lecture a été crée.</p>
          <br />
          <Link to="/" className="bookcreated__button nodecoration">
            Revenir à l'accueil
          </Link>
        </div>
      ) : (
        <div className="create">
        <form className="formcreate">
          <div className="formcreate__div">
            <label>Résumé de la session :</label>
            <br />
            <textarea
              id="desc"
              name="desc"
              className="formcreate__input formcreate__input--area"
              placeholder="Vous pouvez préciser le choix du livre, le niveau requis etc..."
              wrap="soft"
            />
          </div>
          <div className="form__div">
            <label>Début de la lecture :</label>
            <br />
            <input
              id="begin"
              name="begin"
              className="form__input formcreate__input--date"
              type="datetime-local"
            />
          </div>
          <div className="form__div">
            <label>Fin de la lecture :</label>
            <br />
            <input
              id="limit"
              name="limit"
              className="form__input formcreate__input--date"
              type="datetime-local"
            />
          </div>
          <input
          id="formcreatebtn"
            className="form__submit nodecoration"
            type="button"
            value="Créer"
            onClick={createReading}
          />
        </form>
        </div>
      )}
    </>
  );
}

export default CreateDetailSession;
