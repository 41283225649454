import { useEffect, useState } from "react";
import Footer from "../footer/footer";
import Header from "../header/header";
import TopFooter from "../topFooter/topFooter";
import { useNavigate } from "react-router-dom";

const AdminEditSession = () => {
  const [sessions, setSessions] = useState([]);
  const navigate = useNavigate();

  const first = async () => {
    if (!localStorage.lecturecommuneCheckAdmin) {
      navigate("/");
    }
    const token = localStorage.lecturecommuneToken;
    const testConnect = await fetch(
      `https://www.lecturecommune.fr/api/user/checkadmin`,
      {
        method: "post",
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    if (testConnect.status === 200) {
      const getAllBooks = await fetch(
        `https://www.lecturecommune.fr/api/reading/alladmin`,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      if (getAllBooks.status === 200) {
        const result = await getAllBooks.json();
        setSessions(result);
      } else {
        navigate("/error");
      }
    } else {
      navigate("/error");
    }
  };

  useEffect(() => {
    first();
  }, []);
  return (
    <>
      <Header />
      <div
        style={{
          marginTop: 100,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <p>Edit session</p>
        <br />
        <div>
          <table border={1}>
            <tr>
              <th>id</th>
              <th>ISBN</th>
              <th>BNF</th>
              <th>Titre</th>
              <th>Auteur</th>
              <th>Couv</th>
              <th>Nbr</th>
              <th>Lien</th>
            </tr>
            {sessions.length > 0 &&
              sessions.slice(0, 100).map((oneLine) => {
                return (
                  <tr key={oneLine.id}>
                    <td>{oneLine.id}</td>
                    <td>{oneLine.session_followers[0].isbn}</td>
                    <td>
                      {oneLine.book_cover.substring(0, 20) ===
                      "https://www.lecturec"
                        ? ""
                        : "BNF"}
                    </td>
                    <td>{oneLine.book_name}</td>
                    <td>{oneLine.book_author}</td>
                    <td>
                      <img
                        src={oneLine.book_cover}
                        style={{ height: "100px" }}
                      />
                    </td>
                    <td>{oneLine.book_pagesnbr}</td>
                    <td>
                      <a href={`/adminview/edtionesession/${oneLine.id}`}>
                        Modifier
                      </a>
                    </td>
                  </tr>
                );
              })}
          </table>
        </div>
      </div>
      <TopFooter />
      <Footer />
    </>
  );
};

export default AdminEditSession;
