import { useState } from "react";
import ReadingResume from "../readingResume/readingResume";
import "./reading.css";
import ReadingFollowers from "../readingFollowers/readingFollowers";
import ReadingChat from "../readingChat/readingChat";
import ReadingSettings from "../readingSettings/readingSettings";

const Reading = ({ dataOpen }) => {
  const [isFiltered, setIsFiltered] = useState(1);
  const changeChoice1 = () => {
    if (isFiltered !== 1) {
      let deleteClass = document.getElementById(`choice${isFiltered}`);
      deleteClass.classList.remove("upbar__choice--active");
      deleteClass.classList.add("upbar__choice--notactive");
      let addClass = document.getElementById(`choice1`);
      addClass.classList.remove("upbar__choice--notactive");
      addClass.classList.add("upbar__choice--active");
      setIsFiltered(1);
    }
  };
  const changeChoice2 = () => {
    if (isFiltered !== 2) {
      let deleteClass = document.getElementById(`choice${isFiltered}`);
      deleteClass.classList.remove("upbar__choice--active");
      deleteClass.classList.add("upbar__choice--notactive");
      let addClass = document.getElementById(`choice2`);
      addClass.classList.remove("upbar__choice--notactive");
      addClass.classList.add("upbar__choice--active");
      setIsFiltered(2);
    }
  };
  const changeChoice3 = () => {
    if (isFiltered !== 3) {
      let deleteClass = document.getElementById(`choice${isFiltered}`);
      deleteClass.classList.remove("upbar__choice--active");
      deleteClass.classList.add("upbar__choice--notactive");
      let addClass = document.getElementById(`choice3`);
      addClass.classList.remove("upbar__choice--notactive");
      addClass.classList.add("upbar__choice--active");
      setIsFiltered(3);
    }
  };
  const changeChoice4 = () => {
    if (isFiltered !== 4) {
      let deleteClass = document.getElementById(`choice${isFiltered}`);
      deleteClass.classList.remove("upbar__choice--active");
      deleteClass.classList.add("upbar__choice--notactive");
      let addClass = document.getElementById(`choice4`);
      addClass.classList.remove("upbar__choice--notactive");
      addClass.classList.add("upbar__choice--active");
      setIsFiltered(4);
    }
  };
  return (
    <main>
      <div className="openreading">
        <h2 className="openreading__nodisplay">Lecture</h2>
      <div className="upbar">
        <div
          id="choice1"
          className="upbar__choice upbar__choice1 upbar__choice--active"
          onClick={changeChoice1}
        >
          Résumé
        </div>
        <div id="choice2" className="upbar__choice upbar__choice2 upbar__choice--notactive" onClick={changeChoice2}>
          Lecteurs
        </div>
        <div id="choice3" className="upbar__choice upbar__choice3 upbar__choice--notactive" onClick={changeChoice3}>
          Discussion
        </div>
        <div id="choice4" className="upbar__choice upbar__choice4 upbar__choice--notactive" onClick={changeChoice4}>Progression</div>
      </div>
{isFiltered === 1 ? <ReadingResume dataDisplay={dataOpen} /> : isFiltered === 2 ? <div className="rdetails__listfollowers">{dataOpen.session_followers.map((dataFollower) => <ReadingFollowers key={dataFollower.id} followers={dataFollower} cible={dataOpen.session_followers[0].cuttingoption === "chap" ? dataOpen.session_followers[0].cuttingdetail[dataOpen.session_followers[0].cuttingdetail.length - 1].pages : dataOpen.book_pagesnbr} />)}</div> : isFiltered === 3 ? <ReadingChat messages={dataOpen.session_messages} sessionId={dataOpen.id} followers={dataOpen.session_followers} /> : <ReadingSettings data={dataOpen} />}
</div>
    </main>
  );
};

export default Reading;