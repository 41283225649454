import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import FAQDetail from '../../components/faqDetail/faqDetail';
import TopFooter from '../../components/topFooter/topFooter';

function FAQ() {
  return (
    <>
      <Header />
      <FAQDetail />
      <TopFooter />
      <Footer />
    </>
  );
}

export default FAQ;